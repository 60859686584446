import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export default function MaterialUIPickers(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDateTimePicker
        label={props.label}
        inputFormat={props.inputFormat}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        renderInput={props.renderInput}
      />
    </LocalizationProvider>
  );
}