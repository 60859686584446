import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

import { setUser } from './user';

export const createMerchantShoppers = (merchantId, value) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`merchants/${merchantId}/merchant_shoppers/bulk`, value);

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchantShoppers.CREATE,
        payload: {
          merchantShoppers: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      })
    )

    return data;
  }
};


export const fetchMerchantShoppers = (merchantId, query) => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`merchants/${merchantId}/merchant_shoppers`, {
      params: {
        limit: 50,
        offset: 0,
        ...query
      }
    })

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchantShoppers.INDEX,
        payload: {
          merchantShoppers: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      })
    )

    return data;
  }
};

export const deleteMerchantShopper = (merchantId, merchantShopperId) => {
  return async (dispatch, getState) => {
    const { data } = await request.delete(`merchants/${merchantId}/merchant_shoppers/${merchantShopperId}`)

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchantShoppers.DELETE,
        payload: {
          merchantShopper: {
            id: merchantShopperId
          }
        }
      })
    )

    return data;
  }
};


export const addMerchantShoppers = merchantShoppers => {
  return {
    type: actionTypes.merchantShoppers.INDEX,
    payload: {
      merchantShoppers
    },
  };
};
