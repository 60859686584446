import _ from 'lodash';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {Title, Section, GreySection} from './Components';

export default function MarketingPricing(props) {
  return (
    <Box mt={6} mb={6}>
      <Box>
        <Container maxWidth="sm">
          <Stack alignItems="center">
            <Title textAlign="center">
              Our pricing is transparent
            </Title>

            <Box mt={1}>
              <Typography variant="body2" textAlign="center">
                For shoppers, we're entirely free. For businesses, you tell us how much money you will have to refund
                your shoppers if a sports event occurs, we'll compute the probability, and charge you a small premium payment.
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Box mt={3}>
        <Stack alignItems="center">
          <ArrowDownwardIcon />
        </Stack>
      </Box>

      <Box mt={4}>
        <GreySection>
          <Container maxWidth="md">
            <Grid spacing={2} container>
              <Grid item xs={12} md={6}>
                <Paper sx={{p: 3}} variant="outlined">
                  <Typography variant="h5" gutterBottom>
                    Shopper Pricing
                  </Typography>

                 <Typography variant="body2">
                    When you receive a promotion from a retailer through us, you will never have
                    to pay us. You always receive the entire value of the promotion.
                  </Typography>

                  <Box mt={4} mb={3}>
                    <Stack spacing={4} direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2" fontWeight={600}>
                        Service fees
                      </Typography>

                      <Chip
                        color="primary"
                        label={
                          <Typography variant="body2" fontWeight={600}>
                            0%
                          </Typography>
                        }  />
                    </Stack>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{p: 3}} variant="outlined">
                  <Typography variant="h5" gutterBottom>
                    Retailer Pricing
                  </Typography>

                 <Typography variant="body2">
                    Our pricing model is just like insurance. You request a certain amount of coverage
                    to refund your shoppers if a sports event occurs and we'll charge a premium based on underwriting
                    the probability of the event.
                  </Typography>

                  <Box mt={3}>
                    <Button variant="contained" fullWidth>
                      Contact us for a quote
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </GreySection>
      </Box>
    </Box>
  )
}
