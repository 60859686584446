import _ from 'lodash';
import { connect } from 'react-redux';
import * as React from 'react';
import Chip from '@mui/material/Chip';

import AutocompleteAsync from "./AutocompleteAsync";
import TextField from "./TextField";

import request from '../utils/request';

function AutocompleteMerchants(props) {
  const { defaultValue, onChange, multiple } = props;

  return (
    <AutocompleteAsync
      multiple={multiple}
      label="Search merchants"
      fetchResponse={async inputValue => {
        const { data } = await request.get("merchants", {
          params: {
            search: inputValue,
            ..._.get(props, "params", {})
          }
        });

        return _.map(data, d=> {
          return {
            id: d.id,
            ...d.attributes
          }
        })
      }}
      getOptionLabel={(option) => _.get(option, "name", "")}
      defaultValue={defaultValue}
      onChange={onChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          name="merchant"
          label="Search merchants"
        />
      )} />
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
})(AutocompleteMerchants);
