import _ from "lodash";
import * as React from "react";
import Script from 'react-load-script';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 300,
  width: "100%",
  borderRadius: "20px",
  border: "1px solid #dbdbdb",
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.4,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
  borderRadius: "20px"
});

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  opacity: 0.05,
  borderRadius: "20px",
  transition: theme.transitions.create('opacity'),
}));

const CloseIcon = styled(HighlightOffIcon)(({ theme }) => ({
  position: 'absolute',
  right: "-7px",
  top: "-7px",
  zIndex: 20,
  color: theme.palette.common.dark,
}));

const AddLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  textAlign: "center",
  top: "40%",
  color: theme.palette.common.dark,
}));


const ImageUpload = props => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const { cropping, multiple, height, photo, addPhoto, removePhoto } = props;

  const openUpload = () => {
    if (!scriptLoaded) return;

    window.cloudinary.openUploadWidget({
      cloudName: "dirtrv8w6",
      uploadPreset: "giyjsoyh",
      sources: [ 'local', 'instagram', 'facebook', 'dropbox', 'google_drive'],
      croppingAspectRatio: _.get(props, "croppingAspectRatio", 1.5),
      cropping: cropping === false ? false : true,
      showSkipCropButton: false,
      allowed_formats: ['png', 'jpg', 'jpeg'],
      multiple: multiple || false
     }, (error, result) => {
      if (!error && result && result.event === "success") {
        if (result.format == "heic") {
          addPhoto({
            ...result.info,
            secure_url: result.info.secure_url.replace(".heic", ".jpg"),
            url: result.info.url.replace(".heic", ".jpg"),
          })
        } else {
          addPhoto(result.info)
        }
      }
    });
  }

  const src = photo == null ? null :
    photo.url || photo.secure_url;

  return (
    <Box>
      <Script
        url="https://upload-widget.cloudinary.com/global/all.js"
        onError={() => setScriptLoaded(false)}
        onLoad={() => setScriptLoaded(true)}
      />

      <ImageButton
        style={
          props.error ?
            {
              height,
              border: "1px solid red"
            } :
            {
              height,
            }
        }
        focusRipple onClick={openUpload}>
        {
          src != null &&
            <ImageSrc style={{
              backgroundImage: `url(${src})`
            }} />
        }

        <ImageBackdrop
          className="MuiImageBackdrop-root" />

        {
          src == null &&
            <AddLabel>
              <Typography variant="body3" fontWeight={600}>
                {props.label}
              </Typography>
            </AddLabel>
        }

      </ImageButton>
    </Box>
  );
};

export default ImageUpload;
