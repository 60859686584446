import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import FullScreenDrawer from '../../../components/FullScreenDrawer';
import ClickablePaper from '../../../components/ClickablePaper';
import withRouter from '../../../utils/withRouter';
import request from '../../../utils/request';
import currencyFormatter from '../../../utils/currencyFormatter';
import * as promotionsActions from '../../../actions/promotions';
import * as promotionEntryActions from '../../../actions/promotionEntries';

function PromotionEntryDetails(props) {
  const { promotions, location, params, history} = props;

  const promotionEntryId = params.promotionEntryId;

  const { promotion } = useOutletContext();
  const [loading, setLoading] = React.useState(true);

  const [promotionEntry, setPromotionEntry] = React.useState({});

  const proposition = _.get(promotionEntry, "proposition", {});

  React.useEffect(() => {
    Promise.all([
      fetchPromotionEntryDetails(promotionEntryId),
    ]).finally(() => setLoading(false))
  }, [promotionEntryId])

  async function fetchPromotionEntryDetails(id) {
    const { data } = await request.get(`promotion_entries/${id}`);

    if (data.errors) {
      alert(data.errors.message)
    } else {
      setPromotionEntry({
        id: data.id,
        ...data.attributes
      })
    }
  }


  const updatePromotionEntry = data => {
    setPromotionEntry({
      ...promotionEntry,
      ...data
    })
  }

  const orderProps = [{
    name: "Merchant ID",
    attribute: "uuid",
    formatter: _.identity
  }, {
    name: "Amount",
    attribute: "amount",
    formatter: val => currencyFormatter(val, 2)
  }, {
    name: "Purchase Date",
    attribute: "purchased_at",
    formatter: val => val
  }]

  const payoutProps = [{
    name: "Method",
    attribute: "uuid",
    formatter: _.identity
  }, {
    name: "Amount",
    attribute: "amount",
    formatter: val => currencyFormatter(val, 2)
  }, {
    name: "Completed",
    attribute: "completed_at",
    formatter: val => val
  }]

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => history.replace("../")}>
      <Box minWidth={350}>
        {
          _.isEmpty(promotionEntry) ?
            <Loader /> :
            <Box>
              <Stack direction="row" justifyContent="space-between" sx={{pt: 2, px: 2 }}>
                <Link variant="body3" color="secondary" fontWeight={500} component={RouterLink}
                to={`/app/shoppers/${promotionEntry.merchant_shopper.id}`}>
                {promotionEntry.merchant_shopper.name}
                </Link>


                <Chip size="small" label={
                  <Typography variant="body4" fontWeight={500}>
                    {_.upperCase(promotionEntry.status)}
                  </Typography>
                } />

              </Stack>

              <Box px={2} pb={2}>
                <Typography variant="body3" color="text.secondary">
                  {promotionEntry.merchant_shopper.email}
                </Typography>

                <Typography variant="body3" color="text.secondary">
                  {promotionEntry.merchant_shopper.phone}
                </Typography>
              </Box>

              <Divider />

              <Box p={2}>
                <Typography variant="body4" fontWeight={600} textTransform="uppercase" color="text.secondary" gutterBottom>
                  Order
                </Typography>

                <Stack direction="column" spacing={1}>
                  {
                    _.map(orderProps, (prop, i) => {
                      return (
                        <Box key={prop.name}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body3" color="text.secondary">
                              {
                                prop.name
                              }
                            </Typography>

                            <Typography variant="body3" color="text.secondary">
                              {
                               prop.formatter(promotionEntry.entryable[prop.attribute])
                              }
                            </Typography>
                          </Stack>

                          { i < orderProps.length - 1 && <Divider /> }
                        </Box>
                      )
                    })
                  }
                </Stack>
              </Box>


              <Divider />

              <Box p={2}>
                <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center">
                  <Typography variant="body4" fontWeight={600} gutterBottom textTransform="uppercase" color="text.secondary">
                    Proposition
                  </Typography>

                  <Chip size="small" label={
                      <Typography variant="body4" fontWeight={600} textTransform={"uppercase"}>
                        {proposition.status}
                      </Typography>
                    } />
                </Stack>

                <Link variant="body3" color="secondary" fontWeight={500} component={RouterLink} to={
                  `/app/promotions/${promotion.id}/propositions/${proposition.id}`
                }>
                  {
                    _.truncate(
                      proposition.title, {
                        'length': 25,
                        'omission': '...'
                      }
                    )

                  }
                </Link>

              </Box>

              <Divider />

              <Box p={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body4" fontWeight={600} textTransform="uppercase" color="text.secondary" gutterBottom>
                    Risk Coverage
                  </Typography>

                  <Chip size="small" label={
                    <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                      {promotionEntry.promotion_entry_insurance.status}
                    </Typography>
                  } />
                </Stack>

                <Stack spacing={0} sx={{mt: 1}}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="body3" color="text.secondary">
                        Total value
                      </Typography>

                      <Typography variant="body3" color="text.secondary">
                        {
                         currencyFormatter(promotionEntry.promotion_entry_insurance.amount, 2)
                        }
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body3" color="text.secondary">
                      Cost
                    </Typography>

                    <Typography variant="body3" color="text.secondary">
                      {
                       currencyFormatter(promotionEntry.promotion_entry_insurance.cost, 2)
                      }
                    </Typography>
                  </Stack>

                </Stack>
              </Box>

              <Divider />

              <Box p={2}>
                  {
                    _.isEmpty(promotionEntry.promotion_entry_prize) ||
                      <Box>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="body4" fontWeight={600} textTransform="uppercase" color="text.secondary" gutterBottom>
                            Prize
                          </Typography>

                          <Chip size="small" label={
                            <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                              {promotionEntry.promotion_entry_prize.status}
                            </Typography>
                          } />
                        </Stack>

                        <Stack sx={{mt: 1}} direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="body3" color="text.secondary">
                            Value
                          </Typography>
                          <Typography variant="body3" color="text.secondary">
                            {
                             currencyFormatter(promotionEntry.promotion_entry_prize.amount, 2)
                            }
                          </Typography>
                        </Stack>
                      </Box>
                  }
              </Box>
            </Box>
          }
        </Box>
      </FullScreenDrawer>
  );
}

const select = $$state => _.pick($$state, "user", "promotions", "promotionPropositions", "promotionEntries");
export default connect(select, {
  ...promotionEntryActions,
})(withRouter(PromotionEntryDetails));


