import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink, Outlet, useParams, useNavigate, useOutletContext } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import FullScreenDrawer from '../../components/FullScreenDrawer';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

export default function MerchantShopperDetails(props) {
  const [merchantShopper, setMerchantShopper] = React.useState({});
  const [loading, setLoading] = React.useState({});
  const [saving, setSaving] = React.useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const { merchantId, deleteMerchantShopper, regions } = useOutletContext();
  const { merchantShopperId } = params;

  const attrs = [
    {
      key: "uuid"
    },
    {
      key: "email"
    },
    {
      key: "phone"
    },
    {
      key: "gender"
    },
    {
      label: "Region",
      key: "region_id",
      valueGetter: v => _.chain(regions).find(r => r.id == v).get("name").value()
    },
    {
      label: "Account Created",
      key: "account_created_at",
      valueGetter: v => !!v ? moment(v).format("MM/DD/YY") : 0
    },
    {
      label: "Last Ordered",
      key: "last_ordered_at",
      valueGetter: v => !!v ? moment(v).format("MM/DD/YY") : 0
    },
    {
      key: "gmv",
      valueGetter: v => !!v ? currencyFormatter(v, 2) : 0
    },
    {
      label: "Orders Count",
      key: "orders_count",
    },
  ];

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [merchantId, merchantShopperId])

  const fetchData = async () => {
    if (!merchantId) return;

    const { data } = await request.get(`merchants/${merchantId}/merchant_shoppers/${merchantShopperId}`)
    if (data.errors) {

    } else {
      setMerchantShopper({
        id: data.id,
        ...data.attributes
      })
    }
  }

  const destroy = async () => {
    try {
      setSaving(true);

      const data = await deleteMerchantShopper(merchantId, merchantShopperId)
      if (data.errors) {

      } else {
        navigate("../")
      }
    } catch(err) {

    } finally {
      setSaving(true)
    }
  }

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box minWidth={450} py={3} px={2}>

        {
          loading ?
            <Loader /> :
            <Box>
              <Box mb={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" fontWeight={500}>
                    {merchantShopper.name}
                  </Typography>

                  <LoadingButton
                    variant="contained"
                    color="error"
                    size="small"
                    loading={saving}
                    onClick={destroy}>
                    Delete
                  </LoadingButton>
                </Stack>
              </Box>


              {
                _.map(attrs, attr => {
                  return (
                    <Box key={attr} sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      py: 2
                    }}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body4" fontWeight={500}>
                          { _.toUpper(attr.label || attr.key) }
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                          {
                            !!attr.valueGetter ?
                              attr.valueGetter(merchantShopper[attr.key]) :
                              merchantShopper[attr.key]
                          }
                        </Typography>

                      </Stack>
                    </Box>
                  )
                })
              }
            </Box>

        }
      </Box>
    </FullScreenDrawer>
  );
}
