import _ from 'lodash';
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Link from '../../components/Link';

import UserActivationModal from './Activation';

export default function ActivationBanner(props) {
  const { user } = props;
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  if (_.isEmpty(user) || user.status != "registered") return <Box />;

  return (
    <Box sx={{
      background: "rgb(240, 237, 248)",
      p: 1
    }}>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems={"center"}
          spacing={2}>
          <Typography variant="body3" fontWeight={500}>
            Please verify your account with the emailed code
          </Typography>

          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={toggleOpen}>
            Verify
          </Button>
        </Stack>
      </Container>

        <UserActivationModal
          open={open}
          onClose={toggleOpen} />

    </Box>
  )
}
