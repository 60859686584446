import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

import * as notificationActions from '../../actions/notifications';
import withRouter from '../../utils/withRouter';
import Popover from '../../components/Popover';

function NotificationsPopover(props) {
  const { user, notifications, fetchNotifications, history } = props;

  React.useEffect(() => {
    // if (!!user.id) fetchNotifications()
  }, [user.id])

  return (
    <Badge badgeContent={notifications.length}>
      <IconButton component={RouterLink} to="#" sx={{p: 0}}>
        <NotificationsIcon sx={{
          color: "#fff"
        }} />
      </IconButton>
    </Badge>
  );
}


const select = $$state => _.pick($$state, "user", "notifications");
export default connect(select, notificationActions)(withRouter(NotificationsPopover));
