import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoadingButton from '@mui/lab/LoadingButton';

import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';
import TextField from '../../components/TextField';

const validationSchema = yup.object({
  current_password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Current Password is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const AccountSecurity = props => {
  const { user } = props;

  const formik = useFormik({
    initialValues: {
      current_password: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async data => {
      const results = await props.updateUserPassword(data)
      if (results.errors) {
        formik.setErrors(results.errors)
      } else {
        formik.resetForm()
      }
    }
  });

  const breadcrumbs = [
    <Link
      variant="body3"
      fontWeight={600}
      key="1"
      component={RouterLink}
      to="../">
      Account
    </Link>,
    <Typography variant="body3" key="3" color="text.secondary" fontWeight={600}>
      Security
    </Typography>,
  ];

  return (
    <Container maxWidth="sm">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Stack spacing={3} sx={{mt: 3}}>
        <Paper sx={{p: 2}} variant="outlined">
          <Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2" fontWeight={600}>
                Two Factor Authentication
              </Typography>

              <Switch label="On"  size="small" onClick={() => {
                alert("Sorry, something went wrong!")
              }} />
            </Stack>
          </Box>

          <Typography variant="body3" color="text.secondary">
            Enable SMS authentication to add an extra layer of security to your account. We’ll send you a code to verify your identity.
          </Typography>
        </Paper>

        <Paper sx={{p: 3}} variant="outlined">
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <Typography variant="body2" fontWeight={600}>
                Password
              </Typography>

              <TextField
                name="current_password"
                label="Current Password"
                type="password"
                autoComplete="off"
                value={formik.values.current_password}
                onChange={formik.handleChange}
                error={formik.touched.current_password && Boolean(formik.errors.current_password)}
                helperText={formik.touched.current_password && formik.errors.current_password}
              />

              <TextField
                name="password"
                label="New Password"
                type="password"
                autoComplete="off"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />

              <LoadingButton
                sx={{ mt: 2 }}
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formik.isSubmitting}>
                Update
              </LoadingButton>
            </Stack>
          </form>
        </Paper>

        <Paper sx={{p: 3}} variant="outlined">
          <Typography variant="body2" fontWeight={600}>
            Chrome extension access key
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Sign in to our Chrome extension using this key.
          </Typography>

          <Box mt={2}>
            <TextField
              fullWidth
              disabled={true}
              value={user.token} />
          </Box>
        </Paper>
      </Stack>
    </Container>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {
  ...alertActions,
  ...userActions,
})(withRouter(AccountSecurity));
