import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import TextField from '../../components/TextField';
import DialogCloseButton from '../../components/DialogCloseButton';
import DialogResponsive from '../../components/DialogResponsive';

const validationSchema = yup.object({
  code: yup
    .string()
    .required(),
});

function UserActivationModal({user, activateUser, open, onClose}) {
  const [resending, setResending] = React.useState(false);

  const resend = async () => {
    setResending(true)

    const { data } = await request.post(`users/send_activation`)
    if (data.errors) {
      alert(data.errors.message)
    } else {
      alert("Email resent!")
    }

    setResending(false)
  }

  const formik = useFormik({
    initialValues: {
      activation_token: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await activateUser(user, values.code);
        if (results.errors) {
          alert(results.errors.message)
        } else {
          onClose();
        }
      } catch(err) {
      }
    }
  });

  return (
    <DialogResponsive
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      sx={{
        zIndex: 100000000,
      }}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Container maxWidth="xs">
          <Typography variant="title" fontWeight={600}>
            Confirm your account
          </Typography>

          <Box>
            <Typography variant="body3" color="text.secondary">
              Please confirm your account with a code emailed to
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="body3" fontWeight={500} gutterBottom>
              {user.email}
            </Typography>
          </Box>

          <Box mt={2} mb={4}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  name="code"
                  label="Enter the code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}/>

                <LoadingButton
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={!formik.values.code || formik.isSubmitting}>
                  Activate
                </LoadingButton>
              </Stack>
            </form>
          </Box>

          <Divider />

          <Box mt={4}>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Haven't received an email?
            </Typography>

            <Typography variant="body3">
              Please search for <b>help@loyaltyworks.syz</b> in your spam or alternate folders if you cannot locate it in your primary inbox.
            </Typography>
          </Box>

          <Box mt={3}>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={resending}
                onClick={resend}>
                Resend Email
              </LoadingButton>

              <Button onClick={onClose}>
                Skip for now
              </Button>
            </Stack>
          </Box>
        </Container>
      </DialogContent>
    </DialogResponsive>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(withRouter(UserActivationModal));
