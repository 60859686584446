import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

import { setUser } from './user';

export const createMerchant = value => {
  return async (dispatch, getState) => {
    const { data } = await request.post("merchants", value);

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchants.CREATE,
        payload: {
          merchant:{
            id: data.id,
            ...data.attributes
          }
        }
      })
    )

    return data;
  }
};


export const fetchMerchants = userId => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`merchants`, {
      user_id: userId
    });

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchants.INDEX,
        payload: {
          merchants: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      })
    )

    return data;
  }
};
