import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext, useParams, Outlet } from "react-router-dom";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import * as request from '../../../utils/adminRequest';

import Loader from '../../../components/Loader';

export default function AdminAccountBalancesDetails(props) {
  const [loading, setLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const [accountBalance, setAccountBalance] = React.useState({});

  const { accountBalanceId } = useParams();

  const fetchData = async () => {
    const { data } = await request.get(`account_balances/${accountBalanceId}`);
    setAccountBalance({
      id: data.id,
      ...data.attributes
    })
  }

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  return (
    <Container maxWidth="xl">
      <Box mt={2} mb={4}>
        {
          loading ?
            <Loader /> :
            <Stack spacing={2}>
              <Box>
                <Box mb={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={(e, tab) => {

                  }}>
                    <Tab label="Transactions" sx={{
                      textTransform: "inherit",
                      fontSize: 14
                    }}  />
                  </Tabs>
                </Box>

                <Paper variant="paper">
                  <Outlet />
                </Paper>

              </Box>
            </Stack>
          }
        </Box>
    </Container>
  )
}
