import _ from "lodash";
import Promise from "bluebird";
import * as React from "react";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import WebhookIcon from '@mui/icons-material/Webhook';

import withRouter from '../../utils/withRouter';
import Loader from '../../components/Loader';

import ShopifyLogo from './logos/Shopify';

function IntegrationsList(props) {
  const { user, integrations, history } = props;

  const platforms = [{
    name: "API",
    path: "api",
    description: "Integrate directly into your app with our SDKs.",
    logo: <WebhookIcon sx={{fontSize: 100, margin: "0 auto"}} />
  }, {
    name: "Shopify",
    description: "Add the TailRisk Shopify plugin.",
    path:"shopify",
    logo: <ShopifyLogo />
  }, {
    name: "Square",
    status: "pending",
    description: "Enter customers into TailRisk promotions at POS.",
    logo: <img
        style={{
          height: 100,
          margin: "0 auto"
        }}
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Square%2C_Inc_-_Square_Logo.jpg/1200px-Square%2C_Inc_-_Square_Logo.jpg" />
  }, {
    name: "Toast",
    status: "pending",
    description: "Enter customers into TailRisk promotions at POS.",
    logo: <img
        style={{
          height: 100,
          margin: "0 auto"
        }}
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzSZHWR6x4kwkRZBiTxAvA4T32e5kEwUEe8ZWX5fmGDQ&s" />
  }, {
    name: "HubSpot",
    status: "pending",
    description: "Message customers via your HubSpot account.",
    logo: <img
        style={{
          height: 100,
          margin: "0 auto"
        }}
        src="https://cdn.worldvectorlogo.com/logos/hubspot-1.svg" />
  }]

  return (
    <Box>
      <Grid container spacing={{
        xs: 3,
        md: 3
      }}>
        {
          _.map(platforms, platform => {
            return (
              <Grid item xs={12} md={6} lg={4} key={platform.name}>
                <Paper sx={{p: 3, height: 340}} variant="outlined">
                  <Stack direction="column" spacing={3}>
                    {
                      platform.logo
                    }

                    <Box>
                      <Typography variant="h5">
                        {platform.name}
                      </Typography>

                      <Typography variant="body3">
                        {platform.description}
                      </Typography>
                    </Box>


                    <Stack direction="column" alignItems="center" spacing={2}>
                      <Button fullWidth variant="outlined" disabled={platform.status == "pending"} component={RouterLink} to={`./${platform.path}`}>
                        Integrate
                      </Button>

                      {
                        platform.status == "pending" ?
                          <Chip size="small" label={
                            <Typography variant="body4" fontWeight={500}>
                              Coming Soon
                            </Typography>
                          } /> : null

                      }
                    </Stack>

                  </Stack>
                </Paper>
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "integrations");
export default connect(select, {

})(withRouter(IntegrationsList));
