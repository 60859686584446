import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink, useOutletContext, Outlet } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import Link from '../../components/Link';
import TextField from '../../components/TextField';
import DatePicker from '../../components/DatePicker';
import ClickablePaper from '../../components/ClickablePaper';

import request from '../../utils/request';

function Title({promotion, updatePromotion}) {
  const [title, setTitle] = React.useState(promotion.title);
  const [newTitle, setNewTitle] = React.useState(promotion.title);
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    setTitle(promotion.title)
  }, [promotion.title]);

  const handleReturn = async (event) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press event here
      toggleEditing()
      updatePromotion({
        ...promotion,
        title: newTitle
      })

      persistChange({title: newTitle})
    }
  };

  const persistChange = async update => {
    try {
      const { data } = await request.put(`promotions/${promotion.id}`, update)
      if (data.errors) {
        alert(data.errors.message)
      } else {

      }
    } catch(err) {
      alert("Unknown error")
    }
  }

  const toggleEditing = async () => {
    setEditing(!editing);
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Typography variant="body4" fontWeight={600} textTransform="uppercase">
          Title
        </Typography>

        <IconButton onClick={toggleEditing}>
          <EditIcon sx={{fontSize: 13}} />
        </IconButton>
      </Stack>


      {
        editing ?
          <TextField
            sx={{mt: 1}}
            fullWidth
            onKeyDown={handleReturn}
            onChange={e => {
              setNewTitle(e.target.value)
            }}
            value={newTitle}
            label="Title" /> :
          <Typography variant="body3" fontWeight={500} color="text.secondary">
            {title}
          </Typography>
      }
    </Box>
  )
}

function Description({promotion, updatePromotion}) {
  const [description, setDescription] = React.useState(promotion.description);
  const [newDescription, setNewDescription] = React.useState(promotion.description);
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    setDescription(promotion.description)
  }, [promotion.description]);

  const handleReturn = async (event) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press event here
      toggleEditing()
      updatePromotion({
        ...promotion,
        description: newDescription
      })

      persistChange({description: newDescription})
    }
  };

  const persistChange = async update => {
    try {
      const { data } = await request.put(`promotions/${promotion.id}`, update)
      if (data.errors) {
        alert(data.errors.message)
      } else {

      }
    } catch(err) {
      alert("Unknown error")
    }
  }

  const toggleEditing = async () => {
    setEditing(!editing);
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Typography variant="body4" fontWeight={600} textTransform="uppercase">
          Description
        </Typography>

        <IconButton onClick={toggleEditing}>
          <EditIcon sx={{fontSize: 13}} />
        </IconButton>
      </Stack>


      {
        editing ?
          <TextField
            sx={{mt: 1}}
            fullWidth
            multiline
            rows={4}
            onKeyDown={handleReturn}
            onChange={e => {
              setNewDescription(e.target.value)
            }}
            value={newDescription}
            label="Description" /> :
          <Typography variant="body3" fontWeight={500} color="text.secondary">
            {description}
          </Typography>
      }
    </Box>
  )
}

function Schedule({promotion, updatePromotion, attr}) {
  const [date, setDate] = React.useState("");
  const [newDate, setNewDate] = React.useState(date);
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    setDate(_.get(promotion, attr, ""))
  }, [promotion[attr]]);

  const onChange = async date => {
    toggleEditing();

    const update = {
      [attr]: date
    }

    updatePromotion({
      ...promotion,
      ...update
    })

    persistChange(update)
  };

  const persistChange = async update => {
    try {
      const { data } = await request.put(`promotions/${promotion.id}`, update)
      if (data.errors) {
        alert(data.errors.message)
      } else {

      }
    } catch(err) {
      alert("Unknown error")
    }
  }

  const toggleEditing = async () => {
    setEditing(!editing);
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Typography variant="body4" fontWeight={600} textTransform="uppercase">
          {
            _.chain(attr).split("_").map(_.toUpper).join(" ").value()
          }
        </Typography>

        <IconButton onClick={toggleEditing}>
          <EditIcon sx={{fontSize: 13}} />
        </IconButton>
      </Stack>

      {
        editing ?
          <DatePicker
            sx={{mb: 2}}
            inputFormat="MM/DD/yyyy"
            value={date}
            minDate={moment().add(2,'days')}
            maxDate={moment().add(60,'days')}
            onChange={onChange}
            renderInput={(params) =>
              <TextField
                name={attr}
                fullWidth
                {...params} />
            }
            /> :
          <Typography variant="body3" fontWeight={500} color="text.secondary">
            {
              !!date &&
                moment(date).format("MM/DD/YYYY")
            }
          </Typography>
      }
    </Box>
  )
}


export default function PromotionsConfig(props) {
  const { promotion, updatePromotion } = useOutletContext();

  return (
    <Box>
      <Box mb={3}>
        <Title promotion={promotion} updatePromotion={updatePromotion} />
      </Box>

      <Divider />

      <Box mt={3} mb={3}>
        <Description promotion={promotion} updatePromotion={updatePromotion} />
      </Box>

      <Divider />

      <Box mt={3} mb={3}>
        <Stack direction="row" alignItems="center" spacing={10}>
          <Schedule attr={"start_at"} promotion={promotion} updatePromotion={updatePromotion} />
          <Schedule attr={"end_at"} promotion={promotion} updatePromotion={updatePromotion} />
        </Stack>
      </Box>

      <Divider />

      <Box mt={3} mb={3}>
        <Typography variant="body4" fontWeight={600} textTransform="uppercase">
          Regions
        </Typography>

        <Stack sx={{mt: 1}} direction="row" spacing={1}>
          {
            _.map(promotion.regions, r => {
              return <Chip key={r.id} size="small"
                icon={
                  r.direction == "include" ?
                    <CheckIcon /> :
                    <ClearIcon />
                }
                label={
                  <Typography variant="body4" fontWeight={500}>
                    {r.region.name}
                  </Typography>
                } />
            })
          }
        </Stack>
      </Box>

      <Divider />

      <Box mt={3} mb={3}>
        <Typography variant="body4" fontWeight={600} textTransform="uppercase">
          Leagues
        </Typography>

        <Stack sx={{mt: 1}} direction="row" spacing={1}>
          {
            _.map(promotion.leagues, l => {
              return <Chip key={l.id} size="small"
                icon={
                  l.direction == "include" ?
                    <CheckIcon /> :
                    <ClearIcon />
                }
                label={
                  <Typography variant="body4" fontWeight={500}>
                    {l.league.name}
                  </Typography>
                } />
            })
          }
        </Stack>
      </Box>

      <Divider />

      {
        promotion.sports_events.length > 0 &&
          <Box>
            <Box mt={3} mb={3}>
              <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                Events
              </Typography>

              <Stack sx={{mt: 1}} direction="row" spacing={1}>
                {
                  _.map(promotion.sports_events, e => {
                    return <Chip key={e.id} size="small"
                      icon={
                        e.direction == "include" ?
                          <CheckIcon /> :
                          <ClearIcon />
                      }
                      label={
                        <Typography variant="body4" fontWeight={500}>
                          {e.sports_event.name}
                        </Typography>
                      } />
                  })
                }
              </Stack>
            </Box>

            <Divider />

          </Box>
      }

      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
          <Box flexGrow={{
            xs: 0,
            sm: 0
          }}>

            {
              promotion.status == "draft" &&
                <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    component={RouterLink}
                    to="./start">
                    Publish
                  </Button>
                  <Button
                    color="error"
                    component={RouterLink}
                    to={`./delete`}
                    sx={{textDecoration: "initial"}}>
                    Delete
                  </Button>
                </Stack>
            }

            {
              promotion.status == "started" &&
                <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    component={RouterLink}
                    to={`./end`}
                    sx={{textDecoration: "initial"}}>
                    End Promotion
                  </Button>
                </Stack>
            }
          </Box>
        </Toolbar>
      </AppBar>

      <Outlet context={{
        promotion,
        updatePromotion
      }} />
    </Box>
  )
}
