import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';

import DialogCloseButton from '../../../components/DialogCloseButton';
import TextField from '../../../components/TextField';
import ImageUpload from '../../../components/ImageUpload';
import AutocompletePlaces from '../../../components/AutocompletePlaces';
import * as request from '../../../utils/adminRequest';

const validationSchema = yup.object({
  form: yup
    .string()
    .required(),
  email: yup
    .string()
    .required(),
  name: yup
    .string()
    .required(),
  phone: yup
    .string()
    .required(),
  amount: yup
    .number()
    .required()
});

export default function AdminCardsNew(props) {
  const { cards, setCards } = useOutletContext();

  const navigate = useNavigate();
  const location = useLocation();

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const onClose = () => navigate("../", {replace: true});

  const formik = useFormik({
    initialValues: _.merge({
      name: "",
      amount: "",
      email: "",
      phone: "",
      form: "virtual",
    }, search),
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.post(`cards`, values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          setCards([
            {
              id: data.id,
              ...data.attributes
            }, ...cards
          ])
          onClose()
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          New Card
        </Typography>

      </DialogTitle>

      <DialogContent>
        <Box mt={1}>
          <form onSubmit={formik.handleSubmit} id="merchant-new-form">
            <Stack spacing={2}>
              <TextField
                name="form"
                value={formik.values.form}
                onChange={formik.handleChange}
                label="Form"
                fullWidth
                select
                error={formik.touched.form && Boolean(formik.errors.form)}
                helperText={formik.touched.form && formik.errors.form}>
                {
                  _.map(["virtual", "physical"], option => (
                    <MenuItem key={option.value} value={option}>
                      { _.capitalize(option) }
                    </MenuItem>
                  ))
                }

              </TextField>

              <TextField
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                label="Name"
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name} />

              <TextField
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                label="Email"
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email} />

              <TextField
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                label="Phone"
                fullWidth
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone} />


              <TextField
                type="number"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                label="Amount"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount} />


            </Stack>
          </form>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={formik.isSubmitting}
          type="submit"
          form="merchant-new-form"
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
