import * as React from 'react';
import _ from "lodash";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import PeopleIcon from '@mui/icons-material/People';
import FlagIcon from '@mui/icons-material/Flag';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ScienceIcon from '@mui/icons-material/Science';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsIcon from '@mui/icons-material/Settings';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';

import AccountPopover from '../user/AccountPopover';
import NotificationsPopover from './NotificationsPopover';

import Logo from '../../components/Logo';

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const IconMapper = (page, active) => {
  switch(page) {
    case "promotions":
      return (
        <Tooltip title="Promotions">
          <BookmarksIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "billing":
      return (
        <Tooltip title="Favorites">
          <AccountBalanceIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "shoppers":
      return (
        <Tooltip title="Shoppers">
          <PeopleIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "settings":
      return (
        <Tooltip title="Users">
          <SettingsIcon size="xs" color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "integrations":
      return (
        <Tooltip title="Integrations">
          <ElectricBoltIcon size="xs" color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    default:
      return <HomeIcon color={active ? "secondary" : "inherit"} />
  }
}

function AdminMain(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const activePage = _.split(location.pathname, "/")[2] || "home";

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar color="secondary" position="fixed" open={open}>
        <Toolbar sx={{justifyContent: "space-between"}}>
          <IconButton
            color="inherit"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
            }}>
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: "center", spacing: 2 }}>
            <NotificationsPopover />
            <AccountPopover />
          </Box>

        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Box pl={1}>
          <IconButton>
            <Logo path="/app" />
          </IconButton>

        </Box>

        <Divider />

        <List dense>
          {["home", "shoppers", "promotions", 'billing', "integrations", 'settings'].map((page, index) => (
            <ListItem dense key={page} disablePadding sx={{ display: 'block' }} divider>
              <ListItemButton
                onClick={() => {
                  props.history.push(page == "home" ? "./" : `./${page}`)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  {
                    IconMapper(page, page == activePage)
                  }
                </ListItemIcon>
                <ListItemText primary={
                  <Typography color={page == activePage ? "secondary" : "primary"} variant="body3" fontWeight={500}>{
                    _.map(
                      _.split(page, "-"),
                      _.capitalize
                    ).join(" ")
                  }</Typography>
                } sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List dense>
          {[].map((page, index) => (
            <ListItem dense key={page} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  props.history.push(`./${page}`)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {IconMapper(page, page == activePage)}
                </ListItemIcon>
                <ListItemText primary={_.capitalize(page)} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, background: "#f7f7f7", minHeight: "calc(100vh - 0px)", p: 3 }}>
        <DrawerHeader />

        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(withRouter(AdminMain));
