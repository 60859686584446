import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, useLocation, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as paymentsActions from '../../actions/payments';
import * as transactionActions from '../../actions/transactions';

import Loader from '../../components/Loader';

import Balance from './Balance';

function BillingMain(props) {
  const { payments, fetchPayments, transactions, fetchTransactions } = props;

  const [loading, setLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);

  const {
    paymentMethods,
    fetchPaymentMethods,
    history
  } = props;

  const fetchData = async () => {
    if (transactions.length == 0) fetchTransactions()

    if (payments.length < 20) fetchPayments()
  }

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  return (
    <Container maxWidth="lg">
      <Balance />

      <Box mt={2} mb={4}>
        {
          loading ?
            <Loader /> :
            <Stack spacing={2}>
              <Box>
                <Box mb={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={(e, tab) => {

                  }}>
                    <Tab label="Transactions" sx={{
                      textTransform: "inherit",
                      fontSize: 14
                    }}  />
                  </Tabs>
                </Box>

                <Box>
                  <Outlet />
                </Box>
              </Box>
            </Stack>
          }
        </Box>
    </Container>
  )
}

const select = $$state => _.pick($$state, "payments", "transactions");
export default connect(select, {
  ...paymentsActions,
  ...transactionActions
})(withRouter(BillingMain));
