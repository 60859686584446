import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import CsvDownloader from 'react-csv-downloader';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Link from '../../components/Link';

export default function MerchantShoppersSample({user}) {
  const [downloading, setDownloading] = React.useState(false);

  const columns = [{
    id: "uuid",
    displayName: "UUID"
  }, {
    id: "name",
    displayName: "Name"
  }, {
    id: 'email',
    displayName: 'Email'
  }, {
    id: 'phone',
    displayName: 'Phone'
  }, {
    id: 'gender',
    displayName: 'Gender'
  }, {
    id: 'city',
    displayName: 'City'
  }, {
    id: 'zip',
    displayName: 'Zip'
  }, {
    id: 'last_ordered_at',
    displayName: 'Last_Ordered_at'
  }, {
    id: 'account_created_at',
    displayName: 'Account_Created_At'
  }, {
    id: 'gmv',
    displayName: 'GMV'
  }];

  const datas = [{
    uuid: "ID FROM YOUR SYSTEM OR EMAIL / PHONE",
    name: user.name,
    email: user.email,
    phone: "9178231890",
    gender: "Male",
    city: 'Austin',
    zip: 78701,
    account_created_at: moment().add(-7, "days").format("MM/DD/YYYY"),
    last_ordered_at: moment().add(-5, "days").format("MM/DD/YYYY"),
    gmv: 500.42
  }];

  return (
    <Box>
      <CsvDownloader
        filename="tailrisk-shoppers-sample"
        extension=".csv"
        separator=","
        wrapColumnChar=""
        columns={columns}
        datas={datas}>
        <Button color="secondary" variant="outlined">
          Download Sample
        </Button>
      </CsvDownloader>
    </Box>
  );
}
