import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import Link from '../../components/Link';
import Popover from '../../components/Popover';
import withRouter from '../../utils/withRouter';
import * as promotionsActions from '../../actions/promotions';
import * as newPromotionActions from '../../actions/newPromotion';

function LoggedOut() {
  return (
    <Box pt={2}>
      <Container maxWidth="md">
        <Alert icon={false} color="error" variant="filled">
          <Typography variant="body3" fontWeight={600}>
            You must be logged in to view this page.
          </Typography>
        </Alert>
      </Container>
    </Box>
  )
}

function ProfilesDetails(props) {
  const {
    promotions,
    deletePromotion,
    createPromotion,
    updatePromotion,
    user,
    params,
    location,
    history
  } = props;

  const promotion = _.find(promotions, d => d.id == params.promotionId)

  const tabs = [
    "photos",
    "prompts",
    "bio",
    "reviews"
  ];

  const pageIndex = _.indexOf(
    tabs,
    _.chain(location.pathname)
      .split("/")
      .last()
      .value()
  )

  const breadcrumbs = [
    <Link
      variant="body3"
      fontWeight={600}
      key="1"
      color="secondary"
      component={RouterLink}
      to="../../">
      Profiles
    </Link>,
    <Typography variant="body3" key="2" color="text.secondary" fontWeight={600}>
      {promotion?.title}
    </Typography>,
  ];

  const initiateReview = () => {
    const reviewable = promotion.prompts.length == 3 &&
      promotion.photos.length == 6;

    if (reviewable) {
      createPromotion({});
      history.push(`/app/profiles/${promotion.id}/edit/reviews/new`)
    } else {
      alert("You need to complete your profile first before it can be reviewed. Please add 3 prompts and 6 photos.")
    }
  }

  const destroy = async () => {
    try {
      const results = await deletePromotion(promotion.id)
      if (results.errors) {
        alert(results.errors.message)
      } else {
        history.push("../../")
      }
    } catch(err) {
      alert("Unknown server error")
    } finally {

    }
  }

  const makeSample = async () => {
    try {
      const results = await updatePromotion(promotion.id, { sample: true })
      if (results.errors) {
        alert(results.errors.message)
      } else {

      }
    } catch(err) {
      alert("Unknown server error")
    } finally {

    }
  }

  if (user.id == null) return <LoggedOut />
  if (promotion == null) return <Box />

  return (
    <Box pt={2} pb={10}>
      <Container maxWidth="md">

        <Box mt={1} mb={1}>
          <Stack direction={{
            xs: "column",
            md: "row"
          }} spacing={2} justifyContent={{
            xs: "initial",
            md: "space-between"
          }} alignItems={{
            xs: "initial",
            md: "center"
          }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}>
              {breadcrumbs}
            </Breadcrumbs>

            <Stack direction="row" spacing={1}>
              <Button
                component={RouterLink}
                to={`/p/${promotion.token}`}
                variant="contained"
                target="_blank"
                color="secondary"
                size="small"
                fullWidth
                sx={{minWidth: 160}}>
                View Profile
              </Button>

              <Button
                fullWidth
                onClick={initiateReview}
                color="primary"
                size="small"
                variant="contained"
                sx={{minWidth: 160}}>
                Review
              </Button>

              <Popover action={
                <IconButton color="primary">
                  <MoreVertIcon />
                </IconButton>
              } content={popupState => {
                return (
                  <List disablePadding component="nav" sx={{minWidth: 200}}>
                    <ListItemButton
                      divider
                      onClick={() => {
                        destroy()
                        popupState.close()
                      }}>
                      <ListItemText primary={
                        <Typography variant="body3" fontWeight={500}>Delete profile</Typography>
                      } />
                    </ListItemButton>
                    {
                      user.role == "admin" &&
                        !promotion?.sample &&
                        <ListItemButton
                          onClick={() => {
                            makeSample()
                            popupState.close()
                          }}>
                          <ListItemText primary={
                            <Typography variant="body3" fontWeight={500}>Make it a sample</Typography>
                          } />
                        </ListItemButton>
                    }
                  </List>
                )
              }}/>

            </Stack>
          </Stack>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            value={pageIndex}
            onChange={(e, newValue) => {
              const tab = tabs[newValue];
              history.push(`/app/profiles/${params.promotionId}/edit/${tab}`)
            }}
            variant="scrollable"
            scrollButtons="auto">

            {
              _.map(tabs, (page, i)=> (
                <Tab
                  key={i}
                  label={<Typography variant="body3" fontWeight={600}
                  sx={{textTransform: "capitalize"}}>{page}</Typography>}  />
              ))
            }
          </Tabs>
        </Box>

        <Box mt={{
          xs: 1,
          sm: 2
        }}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions", "newPromotion");
export default connect(select, {
  ...promotionsActions,
  ...newPromotionActions
})(withRouter(ProfilesDetails));
