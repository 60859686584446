import _ from "lodash";
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate } from "react-router-dom";
import { useIntercom } from 'react-use-intercom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';

import Alerts from './Alerts';
import TopNavBar from './navigation/TopNavBar';
import BottomNavBar from './navigation/BottomNavBar';
import Footer from './navigation/Footer';

import withRouter from '../utils/withRouter';
import * as analytics from '../utils/analytics';
import * as userActions from '../actions/user';

function Consumer(props) {
  const {
    user,
    location,
    history
  } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [intercomInitiated, setIntercomInitiated] = React.useState(false);

  const { boot, shutdown, hide, show, update } = useIntercom();

  const initiateIntercom = () => {
    if (user.id) {
      boot({
        email: user.email,
        user_id: user.id,
        created_at: user.created_at,
      })
    } else {
      boot()
    }

    setIntercomInitiated(true)
  }

  React.useEffect(() => {
    // Scroll to top when page is changed
    // by react router
    window.scrollTo(0, 0);

    // analytics.logEvent("Page View", {pathname: location.pathname});

    // if (intercomInitiated && location.pathname !== "/app" && mobile) {
    //   shutdown()
    //   setIntercomInitiated(false)
    // } else if (!intercomInitiated && (!mobile || location.pathname == "/app"))  {
    //   initiateIntercom()
    // }
  }, [location.pathname]);

  // const userExists = Boolean(window.localStorage.getItem("user"));
  // const shouldRedirect = !userExists && !location.pathname.includes("/app/onboarding");
  // if (shouldRedirect) return <Navigate to="/app/onboarding" replace />;

  return (
    <Box>
      <Box sx={{
        minHeight: "calc(100vh)",
        background: "#fff"
      }}>
        <Outlet />
      </Box>

      <Alerts />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {})(withRouter(Consumer));
