import _ from 'lodash';
import moment from "moment";
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Loader from '../../../components/Loader';
import Link from '../../../components/Link';
import ImageUpload from '../../../components/ImageUpload';
import * as request from '../../../utils/adminRequest';

export default function AdminPlayerDetails(props) {
  const [loading, setLoading] = React.useState(true);
  const [player, setPlayer] = React.useState({})
  const [activeTab, setActiveTab] = React.useState(0);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchData()
  }, []);


  const fetchData = async id => {
    const { data } = await request.get(`players/${params.playerId}`)

    setPlayer({
      id: data.id,
      ...data.attributes
    })

    setLoading(false)
  }

  const updatePlayer = async values => {
    const { data } = await request.put(`players/${params.playerId}`, values)

    setPlayer({
      ...player,
      ...data.attributes
    })
  }

  if (loading) return <Loader />;

 const breadcrumbs = [
    <Link
      variant="body3"
      color="secondary"
      fontWeight={600}
      component={RouterLink}
      to="../">
      Players
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      {player.name}
    </Typography>,
  ];

  return (
    <Box p={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs}
      </Breadcrumbs>

      <Box mt={3} mb={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={3} alignItems="center">
            <Box width={150}>
              <ImageUpload
                photo={
                  player.avatar
                }
                cropping={false}
                height={150}
                addPhoto={avatar => updatePlayer({avatar})}
                label={"Profile Photo"} />
            </Box>

            <Box>

              <Typography variant="body3">
                Created {
                  moment(player.created_at).format("MMMM Do")
                }
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box mt={2}>
        <Outlet context={{
          player,
          updatePlayer
        }} />
      </Box>

    </Box>
  )
}
