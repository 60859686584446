import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function AdminAccountBalancesTransactions(props) {
  const [cards, setCards] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    logicOperator: 'and',
    quickFilterValues: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetchData(
      _.reduce(filterModel.items, (acc, i) => {
        acc[i.field] = i.value;
        return acc;
      }, {})
    )
  }, [filterModel]);

  const fetchData = async query => {
    const { data } = await request.get(`cards`, {
      params: {
        ...query
      }
    })

    setCards(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }

  const columns = [
    {
      field: 'issuer',
      headerName: 'Issuer',
      width: 100,
      valueGetter: (value) => {
        return _.toUpper(value.name);
      }
    },
    {
      field: 'form',
      headerName: 'Form',
      width: 120,
      valueGetter: _.toUpper
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueGetter: _.toUpper
    },
    {
      field: 'last_4',
      headerName: 'Last 4',
      width: 100,
      valueGetter: _.toUpper
    },
    {
      field: 'funding',
      headerName: 'Funding',
      width: 120,
      valueGetter: _.toUpper
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      valueGetter: (value) => {
        return currencyFormatter(value, 2)
      }
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 120,
      valueGetter: _.toUpper
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 150,
      valueGetter: (value) => {
        return moment(value).format("MM/DD/YY")
      }
    },
  ]

  const rows = cards;

  if (loading) return <Loader />;

  return (
    <Box style={{ height: 550 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        rowCount={hasMore ? rows.length + 1 : rows.length}
        paginationMode="server"
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        onRowSelectionModelChange={row => {
          navigate(`./${row}`)
        }}
        components={{
          Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
          HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
        }}
      />

      {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </Box>
      )}

      <Outlet context={{
        cards,
        setCards
      }} />


      <AppBar position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "rgba(255, 255, 255, 0.7)",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1}>
            <Button variant="contained" component={RouterLink} to="./new">
              New
            </Button>
            <Button variant="contained" color="secondary" component={RouterLink} to="./promise">
              Promise
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}