import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

import ImageUpload from '../../../components/ImageUpload';
import ColorPicker from '../../../components/ColorPicker';
import * as request from '../../../utils/adminRequest';

export default function AdminMerchantDesignDetails(props) {
  const { merchant, setMerchant } = useOutletContext();
  const { colors, photos, logo } = merchant;

  const updateDesign = async payload => {
    try {
      const { data } = await request.put(
        `merchants/${merchant.id}`,
        payload
      )

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setMerchant({
          ...merchant,
          ...data.attributes
        })
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {

    }
  }

  const updateColors = colors => {
    updateDesign({
      ...merchant,
      colors
    })
  }

  return (
    <Box pb={10}>
      <Box mt={2} mb={4}>
        <Grid container spacing={10}>
          <Grid item md={4} xs={6}>
            <Box>
              <Typography variant="body4" fontWeight={600} gutterBottom>
                Brand primary color
              </Typography>
              <ColorPicker color={
                _.get(colors, ["primary"], "")
              } onChange={color => {
                updateColors({
                  ...colors,
                  primary: color.hex
                })
              }} />
            </Box>

          </Grid>
          <Grid item md={4} xs={6}>
            <Box>
              <Typography variant="body4" fontWeight={600} gutterBottom>
                Brand secondary color
              </Typography>
              <ColorPicker color={
                _.get(colors, ["secondary"], "")
              } onChange={color => {
                updateColors({
                  ...colors,
                  secondary: color.hex
                })
              }} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box mt={4} mb={4}>
        <Typography variant="body3" fontWeight={600}>
          Photos
        </Typography>
        <Typography variant="body3" color="text-secondary">
          Will be the default collateral for promotions
        </Typography>

        <Box mt={2}>
          <Grid container spacing={2}>
            {
              _.times(3, i => {
                const photo = photos[i] || {};

                return (
                  <Grid key={i} lg={4} item sm={6} xs={12}>
                    <ImageUpload
                      key={i}
                      photo={photo}
                      cropping={true}
                      height={250}
                      addPhoto={newPhoto => {
                        const updated = _.times(3, n => i == n ? newPhoto : photos[n]);

                        updateDesign({
                          ...merchant,
                          photos: updated
                        })
                      }}
                      label={"Upload Image"} />
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
