import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import TextField from '../../../components/TextField';
import Link from '../../../components/Link';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

const validationSchema = yup.object({
  card_transaction_id: yup
    .string()
});

export default function AdminOffersApprove(props) {
  const { offer, setOffer } = useOutletContext();

  const navigate = useNavigate();

  const onClose = () => navigate("../", {replace: true});

  const formik = useFormik({
    initialValues: {
      card_transaction_id: ""
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.post(`offers/${offer.id}/verify`, values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          window.location.href = "/admin/offers/"
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  if (_.isEmpty(offer)) return <Box />;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Approve this PMS
        </Typography>

        <Link color="secondary" variant="body3" fontWeight={500} target="_blank" href={
          `/admin/users/${offer.requirement_title}`
        }>
          Search for transaction at { offer.requirement_title } before { offer.proposition.eligibility_ends_at }
        </Link>
      </DialogTitle>

      <DialogContent>
        <Box mt={1}>
          <form onSubmit={formik.handleSubmit} id="merchant-new-form">
            <Stack spacing={2}>
              <TextField
                name="card_transaction_id"
                value={formik.values.card_transaction_id}
                onChange={formik.handleChange}
                label="Plaid Transaction ID"
                fullWidth
                error={formik.touched.card_transaction_id && Boolean(formik.errors.card_transaction_id)}
                helperText={formik.touched.card_transaction_id && formik.errors.card_transaction_id} />

            </Stack>
          </form>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          type="submit"
          form="merchant-new-form"
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
