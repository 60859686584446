import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '../../components/TextField';

import withRouter from '../../utils/withRouter';
import * as paymentActions from '../../actions/payments';

const validationSchema = yup.object({
  amount: yup
    .number()
    .required(),
});

function WiredForm({onSubmit}) {
  const formik = useFormik({
    initialValues: {
      amount: "",
      memo: ""
    },
    validationSchema,
    onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={1}>
        <TextField
          fullWidth
          type="number"
          value={formik.values.amount}
          onChange={formik.handleChange}
          label="What was the amount transferred?"
          name="amount"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          error={formik.touched.amount && Boolean(formik.errors.amount)}/>

        <TextField
          fullWidth
          value={formik.values.memo}
          onChange={formik.handleChange}
          label="Did you include a memo?"
          name="memo"
          error={formik.touched.memo && Boolean(formik.errors.memo)}/>

        <LoadingButton
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}>
          Save
        </LoadingButton>
      </Stack>
    </form>
  )
}

function BillingWired(props) {
  const { createPayment, history } = props;

  const onSubmit = async values => {
    try {
      const data = await createPayment(values);
      if (data.errors) {
        alert(data.errors.message)
      } else {
        history.replace("/app/billing")
      }
    } catch (err) {
      alert("Unknown server error")
    }
  }

  return (
    <Box>
      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Last step
        </Typography>
        <Typography variant="body3">
          Help us more quickly assign these funds to your account.
        </Typography>
      </Box>

      <Box mt={4}>
        <WiredForm onSubmit={onSubmit} />
      </Box>
    </Box>
  )
}

const select = $$state => _.pick($$state, "payments");
export default connect(select, {
  ...paymentActions,
})(withRouter(BillingWired));
