import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Faq from './Faq';

export default function ConsumersPromotionsTermsOfService({promotion}) {
  const faqs = _.get(promotion, ["design", "faqs"], []);

  return (
    <Box>
      {
        false &&
          <Faq question={"What are the complete Terms of Service?"}
            answer="Coming soon" />
      }
    </Box>
  )
}
