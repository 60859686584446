import _ from "lodash";
import * as React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../../utils/request';

import TextField from '../../components/TextField';
import Link from '../../components/Link';

import GoogleSignup from './GoogleSignup';

const validationSchema = yup.object({
  name: yup
    .string()
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .required(),
});

function SignupForm({createUser, onClose, onAuthentication, setScreen}) {
  const [errors, setErrors] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      password: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await createUser({
          ...values,
        })

        if (results.errors) {
          formik.setErrors(results.errors)
        } else if (onAuthentication != null) {
          setErrors(null)
          onAuthentication();
          onClose();
        } else {
          setErrors(null)
          onClose();
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        {
          errors != null &&
            <Alert icon={false} color="error" variant="filled">
              <Typography variant="body3" fontWeight={500}>
                {errors}
              </Typography>
            </Alert>
        }

        <TextField
          fullWidth
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          label="Your name"
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name} />

        <TextField
          fullWidth
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          label="Your email"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email} />

        <TextField
          fullWidth
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          label="Enter password"
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Sign up
        </LoadingButton>

      </Stack>
    </form>
  )
}

export default function Signup(props) {
  const {
    createUser,
    referrable_id,
    referrable_type,
    setScreen,
    onClose,
    onAuthentication
  } = props;

  const [errors, setErrors] = React.useState(null);

  const onGoogleSignup = async values => {
    try {
      const { data } = await request.post("user_sessions/google_credentials", {
        ...values,
        referrable_id,
        referrable_type
      });

      const results = await createUser(data)
      if (results.errors) {
        setErrors(results.errors.message)
      } else if (onAuthentication != null) {
        setErrors(null)
        onAuthentication();
        onClose();
      } else {
        setErrors(null)
        onClose();
      }
    } catch (err) {
      alert("Unknown server error")
    }

  };

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Signup
      </Typography>

      <Typography variant="body3" color="text.secondary">
        Already have an existing account? <Link
        underline={"always"} href="#" onClick={e => {
          e.preventDefault();
          props.setScreen("login")
        }}>Log in</Link>.
      </Typography>

      <Box mt={2}>
        <GoogleSignup
          title="Sign up with Google"
          onSuccess={credentials => {
            onGoogleSignup({google_oauth_token: credentials.credential})
          }}
          onError={() => {
            alert("Failed to sign up, please try again")
        }} />

        {
          errors != null &&
            <Alert sx={{mt: 2}} icon={false} color="error" variant="filled">
              <Typography variant="body3" fontWeight={500}>
                {errors}
              </Typography>
            </Alert>
        }

        <Divider sx={{mt: 2, mb: 2}}>
          <Typography variant="body3">Or</Typography>
        </Divider>

        <SignupForm {...props} />

      </Box>
    </Box>
  );
}
