import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.promotions.CREATE:
      return [
        payload.promotion,
        ...$$state
      ];
    case actionTypes.promotions.INDEX:
      return payload.promotions;
    case actionTypes.promotions.UPDATE:
      return _.map($$state, d => d.id == payload.promotion.id ?
          {
            ...d,
            ...payload.promotion
          } : d
      )
    case actionTypes.promotions.DELETE:
      return _.reject($$state, d => d.id == payload.promotion.id)
    default:
      return $$state;
  }
};
