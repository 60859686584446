import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams, Outlet, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import FullScreenDrawer from '../../../components/FullScreenDrawer';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';
import * as dates from '../../../utils/dates';
import currencyFormatter from '../../../utils/currencyFormatter';

import Actions from './Actions';

export default function AdminOffersDetails(props) {
  const navigate = useNavigate();
  const params = useParams();

  const [offer, setOffer] = React.useState({})

  React.useEffect(() => {
    fetchData(params.offerId)
  }, [params.offerId]);

  const fetchData = async id => {
    const { data } = await request.get(`offers/${id}`)

    setOffer(
      {
        id: data.id,
        ...data.attributes
      }
    )
  }

  const {
    proposition,
    merchant_shopper,
    requirement,
    transactions,
    prize,
  } = offer;

  const merchant = _.get(requirement, ["merchant"], {});

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box width={550}>
        {
          offer == null ?
            <Loader /> :
            <Box p={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Chip size="small" label={
                  <Typography variant="body4" fontWeight={600}>
                    {_.upperCase(offer.status)}
                  </Typography>
                } />

                <Actions offer={offer} />
              </Stack>

              <Box mt={1} mb={2}>
                <Box mt={1}>
                  <Link variant="body3" component={RouterLink} to={
                    `/admin/users/${merchant_shopper.user_id}`
                  } target="_blank" color="secondary">
                    View User
                  </Link>

                  <Link variant="body4" component={RouterLink} to={`/admin/propositions/${proposition.id}`} target="_blank" color="secondary">
                    {
                      proposition.title
                    } ({_.upperCase(proposition.status)})
                  </Link>
                </Box>
              </Box>

              <Divider />

              {
                !_.isEmpty(prize) &&
                  <Box mt={3} mb={3}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2" fontWeight={600}>
                        Prize
                      </Typography>
                    </Stack>

                    <Typography variant="body3">
                      {
                        currencyFormatter(prize.amount, 2)
                      }
                    </Typography>
                    <Typography variant="body3">
                      {
                        prize.execute_at
                      }
                    </Typography>

                  </Box>
              }

              <Divider />

              <Box my={2}>
                <Link color="secondary" variant="body2" fontWeight={600} component={RouterLink} to={`/admin/merchants/`} target="_blank">
                  Order at {requirement.title}
                </Link>

                {
                  _.map(transactions, transaction => {
                    return (
                      <Link key={transaction.id} color="secondary" variant="body4" taget="_blank" fontWeight={600} component={RouterLink} to={
                        `/admin/users/${merchant_shopper.user_id}/transactions/${transaction.id}`
                      }>
                        Pending Card Transaction
                      </Link>

                    )
                  })
                }

              </Box>

          </Box>
        }
      </Box>

      <Outlet context={{
        offer,
        setOffer
      }} />
    </FullScreenDrawer>

  )
}
