import _ from "lodash";
import * as React from "react";
import moment from "moment";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';

import EventForm from './Form';

export default function AdminPropositionsNew(props) {
  const navigate = useNavigate();

  const { event } = useOutletContext();

  const onClose = () => navigate("../", {replace: true});

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="sm">

      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={600}>
          New Event
        </Typography>
      </DialogTitle>

      <DialogContent>
        <EventForm event={{}} />
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          type="submit"
          form="event-form"
          size="large"
          variant="contained">
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
