const initialState = {
  alerts: [],
  integrations: [],
  merchants: [],
  merchantUsers: [],
  merchantShoppers: [],
  newPromotion: {},
  notifications: [],
  payments: [],
  promotions: [],
  promotionEntries: [],
  promotionPropositions: [],
  regions: [],
  sports: [],
  sportsEvents: [],
  leagues: [],
  players: [],
  teams: [],
  transactions: [],
  user: {},
};

export default initialState;
