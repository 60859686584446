import _ from "lodash";
import Promise from "bluebird";
import * as React from "react";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import * as promotionActions from '../../actions/promotions';
import withRouter from '../../utils/withRouter';
import Loader from '../../components/Loader';

function PromotionsMain(props) {
  const { user, promotions, fetchPromotions, history } = props;

  return (
    <Box>
      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions");
export default connect(select, {
  ...promotionActions,
})(withRouter(PromotionsMain));
