import _ from "lodash";
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

import DataGrid from '../../../components/DataGrid';
import currencyFormatter from '../../../utils/currencyFormatter';

const PromotionEntriesTable = props => {
  const { promotionEntries } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([])

  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: 'Shopper', hideable: true },
    { field: 'shopper_uuid', headerName: 'Shopper', width: 170 },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueFormatter: _.toUpper
    },
    {
      field: 'proposition_title',
      headerName: 'Proposition',
      width: 200,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
    },
    {
      field: 'created_at',
      headerName: 'Added',
      width: 130,
      type: "dateTime",
      valueGetter: (value) => {
        return new Date(value)
      },
      valueFormatter: (value) => {
        return !!value ? moment(value).format("MM/DD/YY") : ""
      }
    },
  ]

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const rows = _.map(promotionEntries, pe => {
    return {
      ...pe,
      shopper_uuid: pe.merchant_shopper.uuid,
      proposition_title: pe.proposition.title,
      amount: currencyFormatter(
        _.get(pe, ["entryable", "amount"], 0),
        2
      )
    }
  });

  return (
    <Box style={{ height: 350 }}>
      <DataGrid
        checkboxSelection
        onRowClick={({id, row}) => {
          navigate(`./${row.id}`)
        }}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id: false
        }}
        pageSize={10}
        loading={isLoading}
        rowCount={hasMore ? rows.length + 1 : rows.length}
        onPageChange={handlePageChange}
        paginationMode="server"
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedRows(newRowSelectionModel)
        }}
        components={{
          Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
          HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
        }}
      />
      {isLoading && (
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PromotionEntriesTable;