import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import GoogleSignup from './GoogleSignup';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import TextField from '../../components/TextField';
import Link from '../../components/Link';

const validationSchema = yup.object({
  username: yup
    .string()
    .required(),
});

function UsernameForm({user, updateUsername, onClose, onAuthentication}) {
  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,
    onSubmit: async ({username}) => {
      try {
        const results = await updateUsername(user, username);
        if (_.get(results.errors, ["message"])) {
          alert(results.errors.message)
        } else if (results.errors) {
          formik.setErrors(results.errors)
        } else {
          if (onAuthentication) {
            onAuthentication()
          } else {
            onClose()
          }
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={1}>
        <TextField
          fullWidth
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          label="Your username"
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Save
        </LoadingButton>

      </Stack>
    </form>
  )
}

export default function Username(props) {
  const { user } = props;

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Create your username
      </Typography>

      <Typography variant="body3" color="text.secondary">
        Your default username is {user.username}
      </Typography>

      <Box mt={2}>
        <UsernameForm {...props} />
      </Box>
    </Box>
  );
}
