import * as React from 'react';
import _ from "lodash";
import { Outlet, Link as RouterLink, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import TextField from '../../../../components/TextField';

export default function ConsumersDemoProxrCheckout(props) {
  const navigate = useNavigate();
  const [code, setCode] = React.useState("");

  const submit = () => {
    navigate(`./${code}`)
  }

  return (
    <Box>
      <AppBar color="transparent" position="fixed">
        <Toolbar sx={{justifyContent: "space-between"}}>

          <img style={{height: 40}} src="https://cdn.shopify.com/s/files/1/0590/9082/9490/files/ProXR_Pickleball_Black_Blue_Logo_1000px_x320.png?v=1682694464" />

          <IconButton>
            <ShoppingCartIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container sx={{mt: "65px", height: "100vh"}}>
        <Grid item xs={12} md={6}>
        </Grid>
        <Grid item xs={12} md={6} sx={{
          background: "rgb(241,241,241)",
          borderLeft: "1px solid rgb(209,209,209)",
          flexGrow: 1
        }}>
          <Box p={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                onChange={e => {
                  setCode(e.target.value)
                }}
                />

              <Button variant="contained" onClick={submit}>
                Apply
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Outlet context={{
        onClose: () => navigate("./", {replace: true})
      }} />
    </Box>
  )
}