import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';

function BillingWire(props) {
  const { history } = props;

  const properties = [
    {
      name: "Account name",
      value: "TailRisk Funding"
    },
    {
      name: "Account Number",
      value: "11234"
    },
    {
      name: "Routing Number",
      value: "52525",
    },
    {
      name: "Account Type",
      value: "Checking",
    },
    {
      name: "Address",
      value: "52525 West",
    },
  ];

  return (
    <Box>
      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Wire your funds
        </Typography>
        <Typography variant="body3">
          Make sure to use the following instructions.
        </Typography>
      </Box>

      <Box mt={2}>
        <Stack direction="column" spacing={1}>
          {
            _.map(properties, prop => (
              <Box key={prop.name} py={1} sx={{
                borderBottom: "1px solid #dcd9d9"
              }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body3" fontWeight={500} color="text.secondary">
                    {prop.name}
                  </Typography>
                  <Typography variant="body3" color="text.secondary">
                    {prop.value}
                  </Typography>
                </Stack>
              </Box>
            ))
          }
        </Stack>
      </Box>

      <Box mt={3}>
        <Button variant="contained" fullWidth onClick={() => {
          history.push("../wired")
        }}>
          Wire Initiated
        </Button>
      </Box>
    </Box>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
})(withRouter(BillingWire));
