import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.merchantShoppers.CREATE:
      return _.uniqBy([
        ...$$state,
        ...payload.merchantShoppers,
      ], m => m.id);
    case actionTypes.merchantShoppers.INDEX:
      return _.uniqBy([
        ...$$state,
        ...payload.merchantShoppers,
      ], m => m.id);
    case actionTypes.merchantShoppers.DELETE:
      return _.reject($$state, s => s.id == payload.merchantShopper.id);
    default:
      return $$state;
  }
};
