import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import withRouter from '../../../utils/withRouter';
import request from '../../../utils/request';
import FullScreenDialog from '../../../components/FullScreenDialog';

import * as regionsActions from '../../../actions/regions';
import * as alertActions from '../../../actions/alerts';

import Faqs from './Faqs';

function ConsumerPromotionsDetails(props) {
  const { user, history, location, params } = props;

  const context = useOutletContext();
  const handleClose = _.get(context, "onClose", _.identity);

  const { promotionId } = params;

  const [loading, setLoading] = React.useState(true);
  const [promotion, setPromotion] = React.useState({});

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const { data } = await request.get(`promotions/token?token=${promotionId}`)

    if (data.errors == null) {
      setLoading(false)
      setPromotion({
        ...data,
        ...data.attributes
      })
    }
  }

  const accept = () => {
    history.push(`./accept`)
  }

  const decline = () => {
    history.push(`./decline`)
  }

  const proposition = promotion.proposition;

  const timeLeft = getTimeTill(proposition.eligibility_ends_at);

  const primaryColor = _.get(promotion, ["design", "colors", "primary"], "rgb(0,169,224)" );
  const secondaryColor = _.get(promotion, ["design", "colors", "secondary"], "rgb(0,169,224)");

  const logoUrl = _.get(promotion, ["design", "logo", "secure_url"], []);

  const photos = [];
  // const photos = _.get(promotion, ["design", "photos"], []);
  function getTimeTill(dateTime) {
    const now = moment(); // Current time
    const targetDateTime = moment(dateTime); // Target date/time

    const diff = moment.duration(targetDateTime.diff(now)); // Calculate difference

    // Check the difference in various units
    if (diff.asSeconds() < 0) {
      return "Expired"
    } else if (diff.asSeconds() < 60) {
      return `${Math.round(diff.asSeconds())} seconds left`;
    } else if (diff.asMinutes() < 60) {
      return `${Math.round(diff.asMinutes())} minutes left`;
    } else if (diff.asHours() < 24) {
      return `${Math.round(diff.asHours())} hours left`;
    } else {
      return `${Math.round(diff.asDays())} days left`;
    }
  }

  if (loading) return <Loader />;

  return (
    <FullScreenDialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth="sm">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          {
            logoUrl ?
              <Box mb={2}>
                <img src={logoUrl} height={40} />
              </Box> :
              <Box />
          }

          <Stack direction="row" spacing={1} sx={{mb: 0.5}}>
            <Typography variant="body3" fontWeight={600}>
              SPECIAL OFFER
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography variant="body3" fontWeight={600} textTransform="upperCase" color={
              timeLeft == "Expired" ? "error" : "primary"
            }>
              { timeLeft }
            </Typography>
          </Stack>

          <Typography variant="h3" color={primaryColor}>
            {
              promotion.title
            }
          </Typography>

          <Box mt={2}>
            <Typography variant="body2" color="text.secondary">
              {
                promotion.description
              } if {
                proposition.description
              }
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          {
            photos.length > 0 ?
              <Box mt={2}>
                <img src={photos[0].url} style={{width: "100%"}} />
              </Box> :
              <Box />
          }
        </Box>

        <Divider />

        <Box mt={5}>
          <Faqs promotion={promotion} />
        </Box>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button variant="contained" onClick={accept} disabled={
          promotion.status !== "pending" || timeLeft == "Expired"
        }>
          I'm Interested
        </Button>

        <Button onClick={decline} disabled={
          promotion.status !== "pending" || timeLeft == "Expired"
        }>
          No Thanks
        </Button>
      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, ["user", "regions"]);
export default connect(select, {
  ...alertActions,
  ...regionsActions
})(withRouter(ConsumerPromotionsDetails));
