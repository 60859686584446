import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Faq from './Faq';
import TermsOfService from './TermsOfService';

export default function ConsumersPromotionsFaqs({promotion}) {
  const faqs = _.get(promotion, ["design", "faqs"], []);

  const defaults = [{
    question: "If I win, how will I receive my refund?",
    answer: "The rebate will be paid to you bank account or as stored value at the merchant."
  }, {
    question: "When will I receive my refund?",
    answer: "Entrants should allow approximately 6-8 weeks for delivery of their rebate payment. Customers do not need to contact the merchant to initiate the rebate check process."
  }, {
    question: "Do I have to purchase a certain dollar amount to qualify?",
    answer: "There was no minimum or maximum purchase amount to qualify for this promotion."
  }]

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        FAQs
      </Typography>

      <Stack spacing={1} sx={{mt: 1}}>
        {
          _.map([...faqs, ...defaults], (faq, i) => {
            return <Faq
              key={i}
              {...faq} />
          })
        }

          <TermsOfService />

      </Stack>
    </Box>
  )
}
