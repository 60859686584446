import _ from 'lodash';
import moment from "moment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useParams, Outlet, useOutletContext } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Loader from '../../../components/Loader';
import FullScreenDrawer from '../../../components/FullScreenDrawer';
import * as request from '../../../utils/adminRequest';
import * as dates from '../../../utils/dates';
import currencyFormatter from '../../../utils/currencyFormatter';

import Actions from './TransactionActions';

export default function AdminPlaidTransactions(props) {
  const { transactions, user } = useOutletContext();

  const params = useParams();
  const navigate = useNavigate();

  const transaction = _.find(transactions, t => t.id == params.transactionId)

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box minWidth={600} py={3} px={2}>
        {
          transaction == null ?
            <Loader /> :
            <Box>
              <Stack direction="row" spacing={2} alignItems="center">
                <CopyToClipboard
                  text={transaction.id}
                  onCopy={() => {
                  }}>
                    <Button size="small" startIcon={<ContentCopyIcon />} variant="outlined">
                      Transaction ID
                    </Button>
                </CopyToClipboard>
                <CopyToClipboard
                  text={transaction.card_id}
                  onCopy={() => {
                  }}>
                    <Button size="small" startIcon={<ContentCopyIcon />} variant="outlined">
                      Card ID
                    </Button>
                </CopyToClipboard>
                  <Actions transaction={transaction} />

              </Stack>
              <Box mt={2} mb={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="body1" fontWeight={600}>
                          {
                            transaction.merchant_name
                          }
                        </Typography>
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                              {
                                transaction.status
                              }
                          </Typography>
                        } />
                      </Stack>
                      <Typography variant="body3">
                        {
                          dates.tzAwareDateTime(transaction.authorized_at)
                        }
                      </Typography>
                      <Typography variant="body3">
                        {
                          currencyFormatter(transaction.amount, 2)
                        }
                      </Typography>

                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Divider />

              <Box mt={2} mb={3}>
                <Stack spacing={1}>
                  <Typography variant="body3">
                    {
                      transaction.category
                    }
                  </Typography>

                  <Stack direction="row" spacing={2}>
                    <Typography variant="body3">
                      {
                        _.get(transaction, ["personal_finance_category", "primary"])
                      }
                    </Typography>
                    <Typography variant="body3">
                      {
                        _.get(transaction, ["personal_finance_category", "detailed"])
                      }
                    </Typography>

                  </Stack>
                </Stack>
              </Box>
            </Box>
        }
      </Box>
    </FullScreenDrawer>
  )
}
