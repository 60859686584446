import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Auth from './Auth';
import * as userActions from '../../actions/user';

function UserAuthButton(props) {
  const [open, setOpen] = React.useState(false);
  const { user, screen, onAuthentication } = props;

  let children = React.cloneElement(props.children, {
    onClick: () => {
      if (_.isEmpty(user) || user.status == "unregistered" || (user.status == "registered" && screen == "activate")) {
        setOpen(true)
      } else {
        onAuthentication();
      }
    }
  })

  return (
    <Box>
      <Auth
        {...props}
        open={open}
        user={user}
        screen={screen}
        onAuthentication={() => {
          setOpen(false);
          if (onAuthentication != null) onAuthentication()
        }}
        onClose={() => setOpen(false)} />

      {children}
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, userActions)(UserAuthButton);
