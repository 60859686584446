import axios from "axios";

import * as environment from '../environment';

axios.defaults.baseURL = `${environment.APIHost}/`;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

function request(options={}) {
	const user = window.localStorage.getItem("user");
	const jwt = (user ? JSON.parse(user) : {}).jwt;

	const opts = {
	  headers: {
	  	'Authorization': `Bearer ${jwt}`,
      // 'Content-Type': 'application/json',
      // 'Accept': 'application/json',
	  },
	  ...options
	};

	return axios(opts);
}

request.post = function post(url, data={}, options={}, prefix="api") {
	return request({
		url: `${prefix}/${url}`,
		method: "post",
		data,
		...options
	})
}

request.get = function get(url, options={}, prefix="api") {
	return request({
		url: `${prefix}/${url}`,
		method: "get",
		...options
	})
}

request.put = function put(url, data={}, options={}, prefix="api") {
	return request({
		url: `${prefix}/${url}`,
		method: "put",
		data,
		...options
	})
}

request.patch = function patch(url, data={}, options={}, prefix="api") {
	return request({
		url: `${prefix}/${url}`,
		method: "patch",
		data,
		...options
	})
}

request.delete = function del(url, options={}, prefix="api") {
	return request({
		url: `${prefix}/${url}`,
		method: "delete",
	})
}

export default request;