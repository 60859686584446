import _ from 'lodash';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';

export default function AutocompleteAsync(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { label, fetchResponse } = props;

  const fetchOptions = debounce(async (inputValue) => {
    setLoading(true);

    try {
      const results = await fetchResponse(inputValue)
      setOptions(results);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
    setLoading(false);
  }, 500); // Adjust the wait time as needed


  return (
    <Autocomplete
      multiple={false}
      noOptionsText="No Results"
      {..._.omit(props, "fetchResponse", "label")}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        fetchOptions(newInputValue);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
