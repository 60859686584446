import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function AdminAccountBalancesTransactions(props) {
  const { accountBalanceId } = useParams()
  const [transactions, setTransactions] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    logicOperator: 'and',
    quickFilterValues: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetchData(
      _.reduce(filterModel.items, (acc, i) => {
        acc[i.field] = i.value;
        return acc;
      }, {})
    )
  }, [filterModel]);

  const fetchData = async query => {
    const { data } = await request.get(`account_balances/${accountBalanceId}/transactions`, {
      params: {
        ...query
      }
    })

    setTransactions(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }

  const columns = [
    {
      field: 'transactable_type',
      headerName: 'Transactable',
      width: 200,
      valueGetter: _.toUpper
    },
    {
      field: 'direction',
      headerName: 'Direction',
      width: 120,
      valueGetter: _.toUpper
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      valueGetter: (value) => {
        return currencyFormatter(value, 2)
      }
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 150,
      valueGetter: (value) => {
        return currencyFormatter(value, 2)
      }
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 150,
      valueGetter: (value) => {
        return moment(value).format("MM/DD/YY")
      }
    },
    {
      field: 'reconcilable',
      headerName: 'Reconcilable',
      width: 150,
    },
    {
      field: 'reconciliation_id',
      headerName: 'Reconciliation',
      width: 150,
    },
  ]

  const rows = transactions;

  if (loading) return <Loader />;

  return (
    <Box style={{ height: 550 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        rowCount={hasMore ? rows.length + 1 : rows.length}
        paginationMode="server"
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        onRowSelectionModelChange={row => {
          navigate(`./${row}`)
        }}
        components={{
          Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
          HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
        }}
      />

      {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </Box>
      )}

      <Outlet context={{
        transactions,
      }} />
    </Box>
  )
}