import * as React from 'react';
import Box from '@mui/material/Box';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && (
        children
      )}
    </Box>
  );
}