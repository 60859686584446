import * as React from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  letterSpacing: "-0.02em",
  lineHeight: 1.2,
  fontWeight: 600,
  [theme.breakpoints.up('lg')]: {
    fontSize: '50px',
    letterSpacing: "-0.04em",
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '25px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "22px",
    fontWeight: 500,
    lineHeight: 1.4
  },
}));

export const Section = styled(Box)(({ theme }) => ({
  borderRadius: "28px",
  background: "#f4f5f6",
  padding: '56px',
  [theme.breakpoints.up('lg')]: {
    padding: '56px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: "0px",
    backgroundColor: "#fff"
  },
}));

export const GreySection = styled(Box)(({ theme }) => ({
  borderRadius: "28px",
  background: "#f4f5f6",
  padding: '56px',
  [theme.breakpoints.up('lg')]: {
    padding: '56px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: "20px",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
}));


export const HeroImage = styled(Box)(({ theme }) => ({
  maxHeight: "608px",
  minHeight: "608px",

  [theme.breakpoints.up('lg')]: {
    maxHeight: "608px",
    minHeight: "608px",
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: "500px",
    minHeight: "500px",
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: "300px",
    maxHeight: "300px",
  },
}));
