import _ from 'lodash';
import * as React from 'react';
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function AdminPaymentsMain(props) {
  const tabs = ["payments", "invoices"];
  const [activeTab, setActiveTab] = React.useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (_.includes(location.pathname, "invoice")) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }, [location.pathname]);

  return (
    <Container maxWidth="xl">
      <Box mt={2} mb={4}>
        <Box mb={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs indicatorColor="secondary" textColor="secondary" value={activeTab} onChange={(e, tab) => {
            if (tab == 0) {
              navigate(`/admin/payments`)
            } else {
              navigate(`/admin/payments/invoices`)
            }
          }}>
            {
              _.map(tabs, t => {
                return (
                  <Tab key={t} label={t} sx={{
                    fontSize: 14
                  }}  />
                )
              })
            }
          </Tabs>
        </Box>

        <Box mt={2}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  )

}