import _ from "lodash";

import actionTypes from "../constants/index";
import generateUUID from '../utils/uuid';

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.alerts.CREATE:
      // Material UI does not support multiple
      // snacks concurrently. We can use a library
      // that does and then uncomment state below.
      return [
        payload.alert,
        // ...$$state
      ];
    case actionTypes.alerts.DELETE:
      return _.reject($$state, a => a.id == payload.alert.id);
    case actionTypes.merchantShoppers.CREATE:
      return [
        {
          id: generateUUID(),
          title: "Shoppers added"
        },
        ...$$state
      ];
    case actionTypes.payments.CREATE:
      return [
        {
          id: generateUUID(),
          title: "Payment created"
        },
        ...$$state
      ];
    case actionTypes.user.PASSWORD_UPDATE:
      return [
        {
          id: generateUUID(),
          title: "Password Updated"
        },
        ...$$state
      ];
    default:
      return $$state;
  }
};
