import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FilterListIcon from '@mui/icons-material/FilterList';

import Link from '../../components/Link';
import ClickablePaper from '../../components/ClickablePaper';
import withRouter from '../../utils/withRouter';
import Loader from '../../components/Loader';

import * as promotionActions from '../../actions/promotions';

import Search from './Search';

function PromotionsList(props) {
  const { user, promotions, fetchPromotions, history} = props;

  const [loading, setLoading] = React.useState(true);

  const merchantId = _.get(user, ["merchant_user", "merchant_id"]);

  React.useEffect(() => {
    if (!user.id) return setLoading(false)

    Promise.all([
      fetchPromotions({
        merchant_id: merchantId
      })
    ]).finally(() => setLoading(false))
  }, [user.id]);

  return (
    <Box>
        <Box mb={2}>
          <Stack direction={{
            xs: "column",
            md: "row"
          }} spacing={{xs: 2, md: 2}} justifyContent={{
            xs: "initial",
            md: "space-between"
          }} alignItems={{
            xs: "initial",
            md: "center"
          }}>
            <Stack direction="row" spacing={2}>
              <Search merchantId={merchantId} onSelect={p => {
                history.push(`./${p.id}`)
              }} />

              <Stack direction="row" spacing={0}>
              </Stack>
            </Stack>

            <Button component={RouterLink} to="./new" variant="contained" color="secondary" startIcon={<AddIcon />}>
              New Promotion
            </Button>
          </Stack>
        </Box>

        <Grid container spacing={1}>
          {
            loading ?
            <Loader /> :
            _.map(promotions, promotion => {
              return (
                <Grid key={promotion.id} item xs={12} sm={12}>
                  <ClickablePaper onClick={() => history.push(`./${promotion.id}`)}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box flexGrow={1}>
                        <Typography variant="body3" fontWeight={500}>
                          {promotion.title}
                        </Typography>

                        <Typography variant="body3" color="text.secondary" sx={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                        }}>
                          {
                            _.truncate(
                              promotion.description, {
                                'length': 200,
                                'omission': '...'
                              }
                            )
                          }
                        </Typography>

                        <Box mt={2}>
                          <Stack direction="row" spacing={1} justifyContent="end">

                            <Chip size="small" label={
                              <Typography variant="body4" fontWeight={500}>
                                {_.upperCase(promotion.status)}
                              </Typography>
                            } />
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </ClickablePaper>
                </Grid>
                )
              })
          }
        </Grid>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions");
export default connect(select, {
  ...promotionActions
})(withRouter(PromotionsList));
