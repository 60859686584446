import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataGrid from '../../../components/DataGrid';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';

export default function AdminUsersList(props) {
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    logicOperator: 'and',
    quickFilterValues: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    console.log(newFilterModel)
  };

  React.useEffect(() => {
    fetchUsers(
      _.reduce(filterModel.items, (acc, i) => {
        acc[i.field] = i.value;
        return acc;
      }, {})
    ).finally(() => setLoading(false))
  }, [filterModel]);

  const fetchUsers = async query => {
    const { data } = await request.get(`users`, {
      params: {
        ...query
      }
    })

    setUsers(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150,
      valueGetter: (value) => {
        return _.upperCase(value)
      }
    },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'role', headerName: 'Role', width: 150 },
    { field: 'created_at', headerName: 'Created', width: 200 },
  ]

  if (loading) return <Loader />;

  return (
    <Box>
      <Box style={{ height: 550, width: '100%', overflowX: 'auto'}}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={hasMore ? users.length + 1 : users.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

        {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Outlet context={{
        users
      }} />
    </Box>
  )
}
