import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

import { setUser } from './user';

export const createMerchantUsers = (merchantId, value) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`merchants/${merchantId}/merchant_users`, value);

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchantUsers.CREATE,
        payload: {
          merchantUsers: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      })
    )

    return data;
  }
};


export const fetchMerchantUsers = merchantId => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`merchants/${merchantId}/merchant_users`)

    if (!data.errors) dispatch(
      dispatch({
        type: actionTypes.merchantUsers.INDEX,
        payload: {
          merchantUsers: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      })
    )

    return data;
  }
};
