import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Link from '../../../components/Link';
import withRouter from '../../../utils/withRouter';
import Loader from '../../../components/Loader';

import * as promotionActions from '../../../actions/promotions';
import request from '../../../utils/request';

import PromotionMerchantShoppersTable from './Table';
import Search from './Search';

function PromotionMerchantShoppersList(props) {
  const { user, promotions, regions, history} = props;

  const { promotion, updatePromotion } = useOutletContext();

  const [loading, setLoading] = React.useState(true);
  const [selectedMerchantShoppers, setSelectedMerchantShoppers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsCount, setRowsCount] = React.useState(0);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [user.id]);

  const fetchData = async () => {
    fetchMerchantShoppers()

    const {data } = await request.get(
      `promotions/${promotion.id}/promotion_merchant_shoppers/count`,
    );

    setRowsCount(data.count)
  }

  const fetchMerchantShoppers = async () => {
    const { data } = await request.get(
      `promotions/${promotion.id}/promotion_merchant_shoppers`,
      {
        offset: page * 20
      }
    );

    updatePromotion({
      ...promotion,
      promotion_merchant_shoppers: _.map(data, d => {
       return {
          id: d.id,
          ...d.attributes
        }
      })
    })
  }

  return (
    <Box>
      <PromotionMerchantShoppersTable
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        hiddenColumns={["last_ordered_at", "gmv", "gender"]}
        regions={regions}
        merchantShoppers={
          _.get(promotion, "promotion_merchant_shoppers", [])
        }
        selectedMerchantShoppers={selectedMerchantShoppers}
        setSelectedMerchantShoppers={setSelectedMerchantShoppers}
        page={page}
        rowsCount={rowsCount}
        onPageChange={page => {
          setPage(page.page);
        }} />

      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
          <Box flexGrow={{
            xs: 0,
            sm: 0
          }}>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Button variant="outlined" component={RouterLink} to="./upload" color="secondary" size="small">
                Add Shoppers
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Outlet
        context={{
          promotion,
          updatePromotion,
          regions
        }}/>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions", "promotionEntries", "regions");
export default connect(select, {
  ...promotionActions,
})(withRouter(PromotionMerchantShoppersList));
