import * as React from 'react';
import _ from "lodash";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PercentIcon from '@mui/icons-material/Percent';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import RedeemIcon from '@mui/icons-material/Redeem';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

import withRouter from '../../utils/withRouter';
import * as regionsActions from '../../actions/regions';
import * as leaguesActions from '../../actions/leagues';
import * as teamsActions from '../../actions/teams';

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const IconMapper = (page, active) => {
  switch(page) {
    case "users":
      return (
        <Tooltip title="Users">
          <PeopleIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "offers":
      return (
        <Tooltip title="Offers">
          <LocalActivityIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "cards":
      return (
        <Tooltip title="Cards">
          <RedeemIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "merchants":
      return (
        <Tooltip title="Merchants">
          <AddBusinessIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "musicians":
      return (
        <Tooltip title="Musicians">
          <LibraryMusicIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "payouts":
      return (
        <Tooltip title="Payouts">
          <AccountBalanceWalletIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "propositions":
      return (
        <Tooltip title="Propositions">
          <PercentIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "players":
      return (
        <Tooltip title="Players">
          <SportsGymnasticsIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "account_balances":
      return (
        <Tooltip title="Account Balances">
          <AccountBalanceIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "events":
      return (
        <Tooltip title="Events">
          <CalendarTodayIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    case "payments":
      return (
        <Tooltip title="Payments">
          <ReceiptIcon color={active ? "secondary" : "inherit"} />
        </Tooltip>
      );
    default:
      return null;
  }
}

function AdminMain(props) {
  const { fetchLeagues, fetchTeams } = props;

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const activePage = _.split(location.pathname, "/")[2];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // fetchData().finally(() => setLoading(false))
  }, [location.search]);

  const fetchData = async () => {
    Promise.all([
      fetchLeagues(),
      fetchTeams()
    ])
  }

  const pages = [
    'users',
    'merchants',
    'offers',
    "cards",
    'events',
    "propositions",
    'payouts',
    'account_balances',
    'payments',
    'musicians',
    'players'
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{justifyContent: "space-between"}}>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="body3" fontWeight={500} noWrap>
            Admin
          </Typography>

          <Link color="inherit" component={RouterLink} to={"/"} variant="body3">
            Return to App
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>

        </DrawerHeader>
        <Divider />

        <List dense>
          {_.map(pages, (page, index) => (
            <ListItem dense key={page} disablePadding sx={{ display: 'block' }} divider>
              <ListItemButton
                onClick={() => {
                  props.history.push(`./${page}`)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  {
                    IconMapper(page, page == activePage)
                  }
                </ListItemIcon>
                <ListItemText primary={
                  <Typography variant="body3" fontWeight={500}>
                  {
                    _.map(
                      _.split(page, "-"),
                      _.capitalize
                    ).join(" ")
                  }</Typography>
                } sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List dense>
          {[].map((page, index) => (
            <ListItem dense key={page} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  props.history.push(`./${page}`)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {IconMapper(page, page == activePage)}
                </ListItemIcon>
                <ListItemText primary={_.capitalize(page)} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, background: "#fff" }}>
        <DrawerHeader />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...leaguesActions,
  ...teamsActions
})(withRouter(AdminMain));
