import _ from "lodash";
import * as React from "react";
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import TextField from './TextField';

export default props => {
  const { name, label, options, value, onChange, error, helper } = props;

  return (
    <TextField
      fullWidth
      select
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helper={helper}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography variant="body3">
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
 )
}