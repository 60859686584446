import _ from 'lodash';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Loader from '../../../components/Loader';
import ClickablePaper from "../../../components/ClickablePaper";
import * as dates from "../../../utils/dates";
import * as request from '../../../utils/adminRequest';

function FeedItemCard(props) {
  const { title, start_at, image_url } = props;

  return (
    <ClickablePaper
      variant="outlined"
      sx={{
        backgroundColor: "black",
        backgroundImage: `url(${image_url})`,
        backgroundSize: "cover",
        minHeight: {
          xs: 235,
          md: 300,
        },
        backgroundPositionX: {
          xs: -60,
          md: -60
        },
        position: "relative",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          background: "rgba(29, 48, 121, 0.75)",
          position: "absolute",
          bottom: 10,
          p: 1,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={700}
          gutterBottom
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            lineHeight: 1.2,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body3"
          fontWeight={700}
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            lineHeight: 1.2,
          }}
        >
          Ends {dates.tzAwareDateTime(start_at, "MM/DD")}
        </Typography>
      </Box>
    </ClickablePaper>
  );
}

export default function AdminUserFeed(props) {
  const { user } = props;

  const [feedItems, setFeedItems] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    fetchFeedItems(user.id).finally(() => setLoading(false))
  }, []);

  const fetchFeedItems = async user_id => {
    const { data } = await request.get(`feed_items`, {
      params: {
        user_id
      }
    })

    if (data.errors) {

    } else {
      setFeedItems(
        _.map(data, f => {
          return {
            id: f.id,
            ...f.attributes
          }
        })
      )
    }
  }

  return (
    <Box>
      {
        loading ?
          <Loader /> :
          <Grid container spacing={1}>
            {
              _.map(feedItems, item => {
                return (
                  <Grid item xs={6} md={4} key={item.id}>
                    <FeedItemCard {...item} />
                  </Grid>
                )
              })
            }
          </Grid>
      }
    </Box>
  );
}
