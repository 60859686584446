import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import ClickablePaper from '../../../components/ClickablePaper';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

import Search from './Search';

export default function AdminMerchantsList(props) {
  const [loading, setLoading] = React.useState(false);
  const [accountBalances, setAccountBalances] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [location.search]);

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const fetchData = async () => {
    const { data } = await request.get(`account_balances`, {
      params: {
        ...search
      }
    })

    setAccountBalances(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'ownerable_type', headerName: 'Type', width: 200 },
    { field: 'internal', headerName: 'Internal', width: 100,
      valueGetter: (value) => {
        return _.upperCase(value)
      }
    },
    { field: 'owner', headerName: 'Owner', width: 200 },
    { field: 'owner_id', headerName: 'Owner ID', width: 350 },
  ]

  const rows = _.map(accountBalances, ab => {
    return {
      ...ab,
      owner: ab.ownerable.name,
      owner_id: ab.ownerable_id
    }
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) return <Loader />;

  return (
    <Container maxWidth="xl">
      <Box sx={{ height: 550, mt: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={hasMore ? rows.length + 1 : rows.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

        {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Outlet context={{
        accountBalances
      }} />
    </Container>
  )
}
