import _ from "lodash";
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

import currencyFormatter from '../../../utils/currencyFormatter';
import DataGrid from '../../../components/DataGrid';

const MerchantShoppersTable = props => {
  const {
    merchantShoppers,
    regions,
    onRowClick,
    checkboxSelection,
    disableRowSelectionOnClick,
    selectedMerchantShoppers,
    setSelectedMerchantShoppers,
    hiddenColumns,
    page,
    onPageChange,
    rowsCount,
  } = props;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [filterModel, setFilterModel] = useState({
    items: [],
    logicOperator: 'and',
    quickFilterValues: [],
  });

  const rows = _.map(
    merchantShoppers,
    ms => {
      return {
        ...ms.merchant_shopper,
        ...ms,
        proposition_title: ms.proposition.title
      }
    }
  );

  const columns = [
    { field: 'uuid', headerName: 'ID', width: 170 },
    { field: 'email', headerName: 'Email', width: 140 },
    {
      field: 'region_id',
      headerName: 'Region',
      width: 120,
      valueGetter: (value) => {
        return _.chain(regions)
          .find(r => _.toLower(r.id) == _.toLower(value))
          .get("name")
          .value()
      },
    }, {
      field: 'proposition_title',
      headerName: 'Proposition',
      width: 200,
    }, {
      field: 'created_at',
      headerName: 'Added',
      width: 100,
      type: "dateTime",
      valueGetter: (value) => {
        return !!value ? new Date(value) : value
      },
      valueFormatter: (value) => {
        return !!value ? moment(value).format("MM/DD/YY") : ""
      }
    },
    {
      field: 'gmv',
      headerName: 'GMV',
      width: 120,
      type: "number",
      align: 'left',
      headerAlign: "left",
      valueFormatter: (value) => {
        return currencyFormatter(value || 0, 2)
      }
    },
    {
      field: 'last_ordered_at',
      headerName: 'Last Ordered',
      width: 150,
      type: "dateTime",
      align: 'left',
      headerAlign: "left",
      valueGetter: (value) => {
        return !!value ? new Date(value) : value
      },
      valueFormatter: (value) => {
        return !!value ? moment(value).format("MM/DD/YY") : ""
      }
    },
  ]

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    _.reduce(hiddenColumns, (acc, val) => {
      return {
        ...acc,
        [val]: false
      }
    }, {
    }
  ));

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <DataGrid
        autoHeight
        checkboxSelection={checkboxSelection}
        columnVisibilityModel={columnVisibilityModel}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        onRowClick={({id, row}) => navigate(`./${id}`)}
        filterMode="server" // Set filter mode to "server"
        filterModel={filterModel} // Pass filter model to the DataGrid
        onFilterModelChange={handleFilterModelChange}
        disableColumnReorder={true}
        rows={rows}
        columns={columns}
        pagination
        page={page}
        pageSize={pageSize}
        onPaginationModelChange={model => {
          onPageChange(model.page)
        }}
        paginationMode="server"
        loading={isLoading}
        rowCount={rowsCount}
        rowsPerPageOptions={[2, 5, 10, 20]}
        rowSelectionModel={selectedMerchantShoppers}
        onRowSelectionModelChange={setSelectedMerchantShoppers}
        components={{
          Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
          HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
        }}
      />
      {isLoading && (
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default MerchantShoppersTable;