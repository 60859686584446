import _ from "lodash";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import ScienceIcon from '@mui/icons-material/Science';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import AuthButton from '../user/AuthButton';

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  textAlign: "center",
  width: "80px",
  position: "absolute",
  bottom: "-9px",
  left: "-20px"
}));

const getScreen = pathname => {
  switch (true) {
    case /banter/.test(pathname):
      return "banter";
    case /tests/.test(pathname):
      return "tests";
    case /profiles/.test(pathname):
      return "profiles";
    case /community/.test(pathname):
      return "community";
    default:
      return "home";
  }
}

function BottomNavBar(props) {
  const [authOpen, setAuthOpen] = React.useState(false);

  const { user, location, history } = props;

  const pathname = location.pathname;
  const screen = getScreen(pathname);

  return (
    <AppBar position="fixed" color="primary" sx={{
      top: 'auto',
      bottom: 0,
      display: { xs: 'block', sm: 'none' },
      background: "#fff",
      color: "text.primary",
      boxShadow: "none",
      borderTop: "1px solid #cccccc",
    }}>
      <Container maxWidth="sm">
        <Box pb={2}>
          <Stack direction="row" sx={{
            justifyContent: "space-between",
            width: "100%"
          }}>

            <Box sx={{position: "relative"}}>
              <IconButton
                color={screen == "home" ? "secondary" : "primary"}
                onClick={() => {
                  history.push("/app")
                }
              }>
                <HomeIcon />
              </IconButton>
              <Label color={screen == "home" ? "secondary" : "primary"}>
                Home
              </Label>
            </Box>

            <Box sx={{position: "relative"}}>
              <IconButton onClick={() => {
                history.push("/app/banter")
              }} color={screen == "banter" ? "secondary" : "primary"}>
                <ChatIcon />
              </IconButton>
              <Label color={screen == "banter" ? "secondary" : "primary"}>
                Banter
              </Label>
            </Box>

            <Box sx={{position: "relative"}}>
              <IconButton onClick={() => {
                props.history.push("/app/profiles")
              }} color={screen == "profiles" ? "secondary" : "primary"}>
                <AddPhotoAlternateIcon />
              </IconButton>
              <Label color={screen == "profiles" ? "secondary" : "primary"}>
                Profiles
              </Label>
            </Box>

            <Box sx={{position: "relative", cursor: "pointer"}} onClick={() => {
              history.push("/app/tests")
            }}>
              <IconButton
                color={screen == "tests" ? "secondary" : "primary"}>
                <ScienceIcon />
              </IconButton>

              <Label color={screen == "tests" ? "secondary" : "primary"}>
                Experiments
              </Label>
            </Box>

            {
              true ?
                <Box sx={{position: "relative", cursor: "pointer"}} onClick={() => {
                  history.push("/app/account")
                }}>
                  <IconButton color={screen == "account" ? "secondary" : "primary"}>
                    <AccountCircleIcon />
                  </IconButton>
                  <Label color={screen == "account" ? "secondary" : "text.secondary"}>
                    Account
                  </Label>
                </Box> :
                <Box sx={{position: "relative", cursor: "pointer"}} onClick={() => {
                  history.push("/app/community")
                }}>
                  <IconButton color={screen == "community" ? "secondary" : "primary"}>
                    <LocalLibraryIcon />
                  </IconButton>
                  <Label color={screen == "community" ? "secondary" : "text.secondary"}>
                    Community
                  </Label>
                </Box>
            }

          </Stack>
        </Box>
      </Container>
    </AppBar>
  );
};

export default BottomNavBar;
