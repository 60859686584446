import request from './request';

export const post = (url, data={}, options={}) => {
  return request.post(url, data, options, "admin")
}

export const get = (url, options={}) => {
  return request.get(url, options, "admin")
}

export const put = (url, data={}, options={}) => {
 return request.put(url, data, options, "admin")
}

export const patch = (url, data={}, options={}) => {
  return request.patch(url, data, options, "admin")
}

export const destroy = (url, options={}) => {
  return request.delete(url, options, "admin")
}
