import _ from "lodash";
import React from 'react';
import { useOutletContext } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddIcon from '@mui/icons-material/Add';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

import WidgetSummary from './WidgetSummary';
import Charts from './Charts';

import currencyFormatter from '../../../utils/currencyFormatter';
import request from '../../../utils/request';

export default function OverviewAnalyticsView() {
  const { promotion } = useOutletContext();

  const [loading, setLoading] = React.useState(true)
  const [stats, setStats] = React.useState({})

  React.useEffect(() => {
    Promise.all([
      fetchData(promotion.id)
    ]).finally(() => setLoading(false))
  }, [promotion.id]);



  const fetchData = async payload => {
    try {
      const { data } = await request.get(`promotions/${promotion.id}/promotion_stats`)

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setStats(data)
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {

    }
  }

  const summary = _.get(stats, ["summary"], {});

  const summaryStats = [{
    title: "Shoppers Entered",
    value: summary.promotion_entries || 0,
    color: "secondary",
    icon: <GroupIcon sx={{fontSize: 40}} />
  }, {
    title: "GMV",
    value: currencyFormatter(
      summary.gmv || 0,
      2
    ),
    color: "info",
    icon: <ReceiptIcon sx={{fontSize: 40}} />
  },
  // {
  //   title: "Incremental Revenue",
  //   value: currencyFormatter(
  //     summary.incremental_revenue || 0,
  //     2
  //   ),
  //   color: "primary",
  //   icon: <AddIcon sx={{fontSize: 40}} />
  // },
  {
    title: "Premium Value",
    value: currencyFormatter(
      summary.premiums || 0,
      2
    ),
    color: "primary",
    icon: <HealthAndSafetyIcon sx={{fontSize: 40}} />
  }]

  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        {
          _.map(summaryStats, stat => {
            return (
              <Grid key={stat.title} item xs={12} sm={6} md={3}>
                <WidgetSummary
                  title={stat.title}
                  total={stat.value}
                  color={stat.color}
                  icon={stat.icon}
                />
              </Grid>

            )
          })
        }

      </Grid>

      <Box mt={3}>
        {
          false &&
          <Charts
            title="Conversion Rates"
            subheader="Comparison to equivalent discounts or zero promotions"
            chart={{
              labels: [
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ],
              series: [
                {
                  name: 'Propositions',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Discounts',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Zero Promotion',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ],
            }}
          />
        }
      </Box>
    </Box>
  );
}
