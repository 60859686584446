import _ from 'lodash';
import * as React from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams, useOutletContext, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import FullScreenDrawer from '../../../components/FullScreenDrawer';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function AdminPayoutDetails(props) {
  const navigate = useNavigate();
  const { payoutId } = useParams();

  const { payouts } = useOutletContext()

  const payout = _.find(payouts, p => p.id == payoutId);

  if (payout == null) return <Box />;

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box p={2} width={550}>
        <Box>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" fontWeight={600}>
              {
                currencyFormatter(payout.amount)
              } Payout
            </Typography>
          </Stack>
        </Box>
        <Box mt={2}>
          <Stack spacing={3}>
            <Link component={RouterLink} to={
              `/admin/users/${payout.user_id}`
            } target="_blank" variant="body3" color="secondary">
              View User
            </Link>
          </Stack>
        </Box>
      </Box>

      <Outlet context={{
        payout,
      }} />

    </FullScreenDrawer>

  )
}
