import _ from 'lodash';
import { connect } from 'react-redux';

import moment from 'moment';
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import withRouter from '../../utils/withRouter';
import * as transactionActions from '../../actions/transactions';
import currencyFormatter from '../../utils/currencyFormatter';
import Loader from '../../components/Loader';

function TransactionsList(props) {
  const { transactions, fetchTransactions } = props;

  const [loading, setLoading] = React.useState(true);

  const label = type => {
    switch(_.toLower(type)) {
      case "payment":
        return "Deposit"
      case "promotionentryinsurance":
        return "Promotion Entry";
      default:
        return _.toUpper(type)
    }
  }

  console.log(transactions)
  return (
    <Box>
      <Stack spacing={1}>
        {
          _.map(transactions, (transaction, i) => (
            <Paper
              key={i}
              variant="outlined"
              sx={{p: 2}}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2}>

                  <Box>
                    <Typography variant="body3" fontWeight={500}>
                     { label(transaction.transactable_type) }
                    </Typography>
                    <Typography variant="body3" color="text.secondary">{
                      moment(transaction.created_at).format("MM/DD/YY")
                    }</Typography>
                  </Box>
                </Stack>

                <Chip color={
                  transaction.direction == "credit" ? "error" : "success"
                } size="small" label={
                  <Typography variant="body4" fontWeight={600}>
                    {currencyFormatter(transaction.amount, 2)}
                  </Typography>

                } />
              </Stack>
            </Paper>
          )
        )}
      </Stack>
    </Box>
  )
}

const select = $$state => _.pick($$state, "transactions");
export default connect(select, {
  ...transactionActions
})(withRouter(TransactionsList));

