import _ from "lodash";
import * as React from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Link from '../../../components/Link';
import TextField from '../../../components/TextField';
import ClickablePaper from '../../../components/ClickablePaper';

import withRouter from '../../../utils/withRouter';
import * as integrationsActions from '../../../actions/integrations';

import ShopifyLogo from '../logos/Shopify';

const validationSchema = yup.object({
  domain: yup
    .string()
    .required()
});

function IntegrationsShopifyDetails(props) {
  const { integrations, createIntegration, history } = props;

  const integration = _.find(integrations, i => i.type == "Integration::Shopify") || {}

  const domain = _.get(integration, ["meta", "domain"], "");

  const breadcrumbs = [
    <Link
      variant="body3"
      color="secondary"
      fontWeight={600}
      component={RouterLink}
      to="../">
      Integrations
    </Link>,
    <Typography variant="body3" key="3" color="text.secondary" fontWeight={600}>
      Shopify
    </Typography>,
  ];

  const formik = useFormik({
    initialValues: {
      domain: _.get(integration, ["meta", "domain"], ""),
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await createIntegration({
          ...values,
          type: "Integration::Shopify"
        });

        if (results.errors) {
          alert(results.errors.message)
        } else {
          const cbkUrl = 'https://www.tailrisk.ai/api/integrations/shopify/confirm';
          const encodedCallbackUrl = encodeURIComponent(cbkUrl);

          window.location.href = `https://${values.domain}/admin/oauth/authorize?client_id=7c2b117e4d1557702dc56266959a81aa
            &scope=read_orders%2Cwrite_order_edits%2Cread_customers%2Cwrite_discounts%2Cwrite_marketing_events%2Cwrite_price_rules%2Cre
            ad_users&redirect_uri=${encodedCallbackUrl}&}&grant_options%5B%5D=per-user`;
        }
      } catch(err) {

      }
    },
  });

  return (
    <Box>
      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <Box mt={3}>
          <Paper sx={{py: 5, px: 3}} variant="outlined">
            <form onSubmit={formik.handleSubmit}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  sx={{width: 400}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <Typography variant="body3">https://</Typography>
                    </InputAdornment>,
                  }}
                  name="domain"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  placeholder="Enter your store domain"
                  helperText={formik.touched.domain && formik.errors.domain}
                  error={formik.touched.domain && Boolean(formik.errors.domain)} />

                <LoadingButton
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={
                    !formik.values.domain ||
                    !!integration.access_token
                  }
                  size="large"
                  variant="contained">
                  Connect Store
                </LoadingButton>
              </Stack>
            </form>
          </Paper>
        </Box>
      </Container>

      <Outlet />
    </Box>
  );
}


const select = $$state => _.pick($$state, "integrations");
export default connect(select, {
  ...integrationsActions
})(withRouter(IntegrationsShopifyDetails));
