import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate, useParams, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../components/DialogCloseButton';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import CsvUploader from '../../components/CsvUploader';
import withRouter from '../../utils/withRouter';
import * as merchantShopperActions from '../../actions/merchantShoppers';

import SampleCsv from './SampleCsv';

function MerchantShoppersUpload(props) {
  const { merchantShoppers, createMerchantShoppers, user, history } = props;

  const [saving, setSaving] = React.useState(false);

  const onClose = () => history.replace("../")

  const handleData = rows => {
    setSaving(true)

    if (rows.length > 0) {
      const lowerCaseKeys = _.map(rows, r => _.mapKeys(r, (v, k) => _.toLower(k)));
      save(lowerCaseKeys)
    } else {
      alert("No valid entries found");
      setSaving(false)
    }

  }

  const save = async merchant_shoppers => {
    try {
      const data = await createMerchantShoppers(
        user.merchant_user.merchant_id, {
          merchant_shoppers
        })
      if (data.errors) {
        alert(data.error.message)
      } else {
        onClose()
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="sm">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={onClose} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            Manually upload your shoppers
          </Typography>

          <Typography variant="body3" color="text.secondary">
            This is intended as the easiest way to do this. For larger programs,
            we suggest you <Link underline="always" color="secondary" component={RouterLink} to="/app/integrations">check out our integrations</Link>.
          </Typography>
        </DialogTitle>

        <DialogContent>
          {
            saving ?
              <Loader /> :
              <CsvUploader onFileLoaded={handleData} />
          }
        </DialogContent>

        <DialogActions sx={{p: 3}}>
          <SampleCsv user={user} />
        </DialogActions>

      </Dialog>

    </Box>
  );
}

const select = $$state => _.pick($$state, "merchantShoppers", "user");
export default connect(select, {
  ...merchantShopperActions
})(withRouter(MerchantShoppersUpload));
