import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.merchants.CREATE:
      return [
        payload.merchant,
        ...$$state
      ];
    case actionTypes.merchants.INDEX:
      return payload.merchants;
    default:
      return $$state;
  }
};
