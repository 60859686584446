import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink, useLocation, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import * as merchantUserActions from '../../actions/merchantUsers';
import Loader from '../../components/Loader';

function SettingsMain(props) {
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();

  const {
    merchants,
    merchantUsers,
    fetchMerchantUsers,
    user,
    history
  } = props;

  const fetchData = () => Promise.all([
    fetchMerchantUsers(user.merchant_user.merchant_id)
  ])

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  return (
    <Box>
      <Box mb={3}>
        <Stack direction="row" justifyContent="flex-end">
          <Button component={RouterLink} to="./new" color="secondary" startIcon={<AddIcon />} >
            Add Teammates
          </Button>
        </Stack>
      </Box>

      <Paper variant="outlined">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Added
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Role
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Status
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                _.map(merchantUsers, mu => {
                  return (
                    <TableRow key={mu.id}>
                      <TableCell>
                        <Typography variant="body3" fontWeight={600}>
                          {mu.user.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body3" fontWeight={600}>
                          {mu.user.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body3" fontWeight={600}>
                          {
                            moment.unix(mu.created_at).format("MM/DD/YY")
                          }
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={600}>
                            {_.capitalize(mu.role)}
                          </Typography>
                        } />
                      </TableCell>
                      <TableCell>
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={600}>
                            {_.capitalize(mu.status)}
                          </Typography>
                        } />
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Outlet />
    </Box>
  )
}

const select = $$state => _.pick($$state, "user", "merchants",  "merchantUsers");
export default connect(select, {
  ...merchantUserActions
})(withRouter(SettingsMain));
