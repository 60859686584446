import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    fontSize: '0.9rem', // Adjust the font size here
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '0.9rem', // Adjust the font size for header titles
  },
  '& .MuiDataGrid-toolbarContainer': {
    fontSize: '0.9rem', // Adjust the font size for the toolbar
  },
  '& .MuiButtonBase-root': {
    fontSize: '0.9rem', // Adjust the font size for buttons in the toolbar
  },
}));


export default StyledDataGridPro