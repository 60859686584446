import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LoadingButton from '@mui/lab/LoadingButton';

import Loader from '../../../components/Loader';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import * as userActions from '../../../actions/user';
import currencyFormatter from '../../../utils/currencyFormatter';

import Search from './Search';

export default function AdminPlaidAccounts(props) {
  const { user } = props;

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);

  React.useEffect(() => {
    if (user.id) fetchData().finally(() => setLoading(false))
  }, [user.id]);

  const fetchData = async () => {
    const { data } = await request.get(`plaid_accounts`, {
      params: {
        user_id: user.id
      }
    })

    setAccounts(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }

  const refresh = async () => {
    try {
      setRefreshing(true);

      const { data } = await request.post(`plaid_accounts`, {
        user_id: user.id
      })

      setAccounts(
        _.uniqBy([
          ..._.map(data, u => {
            return {
              id: u.id,
              ...u.attributes
            }
          }),
          ...accounts,
        ], t => t.id)
      )

    } finally {
      setRefreshing(false)
    }
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 175 },
    { field: 'account_id', headerName: 'ID', width: 175 },
    { field: 'account_type', headerName: 'Type', width: 120 },
    { field: 'account_subtype', headerName: 'Subtype', width: 120 },
    { field: 'mask', headerName: 'Mask', width: 120},
    {
      field: 'balances',
      headerName: 'Balance',
      width: 200,
      valueGetter: (value) => {
        return !!value.available && currencyFormatter(value.available)
      }
    },
  ]

  if (loading) return <Loader />;

  return (
    <Box pb={14}>
      <Box sx={{ height: 550, mt: 2 }}>
        <DataGrid
          rows={accounts}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={accounts.length}
          // onPageChange={handlePageChange}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

      </Box>

      <AppBar position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "rgba(255, 255, 255, 0.7)",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <LoadingButton
            onClick={refresh}
            loading={refreshing}
            color="primary"
            variant="contained">
            Refresh
          </LoadingButton>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
