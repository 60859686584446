import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import CsvUploader from '../../../components/CsvUploader';
import request from '../../../utils/request';

import SampleCsv from './SampleCsv';

export default function PromotionEntriesUpload(props) {
  const { promotion, promotionEntries, setPromotionEntries } = useOutletContext();
  const navigate = useNavigate();

  const [saving, setSaving] = React.useState(false);

  const onClose = () => navigate("../", { replace: true})

  const handleData = rows => {
    setSaving(true)

    // const [header, ...rest] = _.chain(data)
    //   .map(_.values)
    //   .reject(v => _.every(v, v => !v))
    //   .value()

    const entries = _.map(rows, row => {

      return _.reduce(row, (result, value, k) => {
        const key = _.toLower(k);

        if (_.startsWith(key, "order") || _.startsWith(key, "shopper")) {
          const [prefix, suffix] = key.split('_');
          // Special handling for keys starting with 'order'
          const objectKey = prefix === 'order' ? 'entryable' : prefix;

          if (!result[objectKey]) {
            result[objectKey] = {};
          }

          if (objectKey === 'entryable') {
            result[objectKey].type = 'order';
          }

          result[objectKey][suffix == "id" ? "uuid" : suffix] = value;
        } else {
          result[key] = value;
        }

        return result;
      }, {});
    })

    if (entries.length > 0) {
      save(entries)
    } else {
      alert("No valid entries found");
      setSaving(false)
    }

  }

  const save = async promotion_entries => {
    try {
      const { data } = await request.post(`promotion_entries/bulk`, {
        promotion_id: promotion.id,
        promotion_entries
      });

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setPromotionEntries(
          _.uniqBy([
            ...promotionEntries,
            ...(
              _.map(data, d => {
                return {
                  id: d.id,
                  ...d.attributes
                }
              })
            )
          ], p => p.id)
        )
      }

      onClose()
    } catch(err) {
      console.log(err)
      alert("Unknown error, please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="sm">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={onClose} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            Manually upload entries for this promotion.
          </Typography>

          <Typography variant="body3" color="text.secondary">
            This is intended as the easiest way to
            run a small promotion. For larger or recurring campaigns,
            we suggest you <Link underline="all" color="secondary" component={RouterLink} to="/app/integrations">check out our integrations</Link>.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box mb={2}>
            <Alert icon={false} color="secondary">
              <Typography variant="body3" fontWeight={600}>
                <Link
                  color="secondary"
                  component={RouterLink}
                  to="../../shoppers/upload/">Make sure you have first uploaded your shoppers CSV here</Link>
              </Typography>
            </Alert>
          </Box>

          {
            saving ?
              <Loader /> :
              <CsvUploader onFileLoaded={handleData} />
          }
        </DialogContent>

        <DialogActions sx={{p: 3}}>
          <SampleCsv />
        </DialogActions>

      </Dialog>

    </Box>
  );
}
