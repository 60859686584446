import _ from 'lodash';
import moment from "moment";
import * as React from 'react';
import { useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';

import CardTransactions from '../cardTransactions/List';
import CardAccounts from '../cardTransactions/Accounts';
import Feed from './Feed';
import Actions from './Actions';

export default function AdminUsersDetails(props) {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState({})

  const params = useParams();

  const [tab, setTab] = React.useState(0)
  const pages = ["transactions", "accounts", "feed"];

  React.useEffect(() => {
    fetchUser()
  }, []);

  const fetchUser = async id => {
    const { data } = await request.get(`users/${params.userId}`)

    setUser({
      id: data.id,
      ...data.attributes
    })

    setLoading(false)
  }

  if (loading) return <Loader />;

  return (
    <Box p={2}>
      <Box mb={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body2">
              {
                user.name
              }
            </Typography>

            <Typography variant="body3">
              {
                user.phone
              }
            </Typography>

            <Typography variant="body3">
              {
                user.email
              }
            </Typography>
          </Box>

          <Actions user={user} />

        </Stack>

        <Typography variant="body3">
          Joined {
            moment(user.created_at).format("MMMM Do")
          }
        </Typography>
      </Box>

      <Divider />

      <Box mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, tab) => {
          setTab(tab)
        }}>
          {
            _.map(pages, page => {
              return (
                <Tab key={page} label={_.capitalize(page)} sx={{
                  textTransform: "inherit",
                  fontSize: 14
                }}  />

              )
            })
          }

        </Tabs>
      </Box>

      <Box>
        {
          tab == 0 &&
            <CardTransactions user={user} />
        }

        {
          tab == 1 &&
            <CardAccounts user={user} />
        }

        {
          tab == 2 &&
            <Feed user={user} />
        }
      </Box>
    </Box>
  )
}
