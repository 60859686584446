import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchPromotionEntries = (promotionId, query) => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`promotion_entries`, {
      params: {
        promotion_id: promotionId,
        limit: 50,
        ...query
      }
    });

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotionEntries.INDEX,
        payload: {
          promotionEntries: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const createPromotionEntries = (values) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`promotion_entries`, values);

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotionEntries.CREATE,
        payload: {
          promotionEntries: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const updatePromotionEntry = (id, values) => {
  return async (dispatch, getState) => {
    const { data } = await request.put(`promotion_entries/${id}`, values);

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotionEntries.UPDATE,
        payload: {
          promotionEntry: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data;
  }
};

export const deletePromotionEntry = (id) => {
  return async (dispatch, getState) => {
    const { data } = await request.delete(`promotion_entries/${id}`);

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotionEntries.DELETE,
        payload: {
          promotionEntry: {
            id
          }
        }
      });
    }

    return data;
  }
};
