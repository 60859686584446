import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Link from '../../../components/Link';
import ClickablePaper from '../../../components/ClickablePaper';
import withRouter from '../../../utils/withRouter';
import Loader from '../../../components/Loader';

import * as promotionActions from '../../../actions/promotions';
import request from '../../../utils/request';

import Search from './Search';

import PromotionEntriesTable from './Table';


function PromotionEntriesList(props) {
  const { user, promotions, history} = props;

  const { promotion, updatePromotion } = useOutletContext();

  const [loading, setLoading] = React.useState(true);
  const [promotionEntries, setPromotionEntries] = React.useState([]);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [user.id]);

  const fetchData = async () => {
    const { data } = await request.get(`promotion_entries/?promotion_id=${promotion.id}`);

    setPromotionEntries(
      _.map(data, d => {
       return {
          id: d.id,
          ...d.attributes
        }
      })
    )
  }

  return (
    <Box>
      {
        false &&
          <Box mb={2}>
            <Stack direction={{
              xs: "column",
              md: "row"
            }} spacing={{xs: 2, md: 2}} justifyContent={{
              xs: "initial",
              md: "space-between"
            }} alignItems={{
              xs: "initial",
              md: "center"
            }}>
              <Stack direction="row" spacing={2}>
                <Search />
              </Stack>

              <Stack direction="row" spacing={1}>

              </Stack>
            </Stack>
          </Box>

      }

      <Box mb={2}>
        <Alert icon={false} color="secondary">
          <Typography variant="body3" fontWeight={500}>
            Entries are shoppers who have taken qualifying actions
            for this promotion.
          </Typography>
        </Alert>
      </Box>

      <PromotionEntriesTable promotionEntries={promotionEntries} />


      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
          <Box flexGrow={{
            xs: 0,
            sm: 0
          }}>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Button component={RouterLink} to="./upload" color="secondary" startIcon={<AddIcon />} size="small" variant="outlined">
                Add Entries
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Outlet
        context={{
          promotion,
          promotionEntries,
          setPromotionEntries
        }}/>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions", "promotionEntries");
export default connect(select, {
  ...promotionActions,
})(withRouter(PromotionEntriesList));
