import _ from 'lodash';
import moment from "moment";
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Loader from '../../../components/Loader';
import Link from '../../../components/Link';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';

export default function AdminMusicianDetails(props) {
  const [loading, setLoading] = React.useState(true);
  const [musician, setMusician] = React.useState({})
  const [songs, setSongs] = React.useState([]);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchData(params.musicianId).finally(() => setLoading(false))
  }, []);


  const fetchData = async id => {
    const { data } = await request.get(`musicians/${id}`)

    if (data.errors) {
      alert(data.errors.message)
    } else {
      setMusician({
        id: data.id,
        ...data.attributes
      })

      fetchSongs(id)
    }
  }

  const fetchSongs = async id => {
    const { data } = await request.get(`musicians/${id}/songs`)

    if (data.errors) {
      alert(data.errors.message)
    } else {
      setSongs(
        _.map(data, d => {
          return {
            id: d.id,
            ...d.attributes
          }
        })
      )
    }

  }

  if (loading) return <Loader />;

 const breadcrumbs = [
    <Link
      variant="body3"
      color="secondary"
      fontWeight={600}
      component={RouterLink}
      to="../">
      Musicians
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      {musician.name}
    </Typography>,
  ];


  const columns = [
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'release_date', headerName: 'Released', width: 200 },
    {
      field: 'spotify_plays',
      headerName: 'Spotify Plays',
      width: 150,
      valueGetter: _.toUpper
    },

  ]

  const rows = songs;

  return (
    <Box p={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs}
      </Breadcrumbs>

      <Box mt={3} mb={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={3} alignItems="center">
            <Box width={150}>
              <img
                style={{
                  height: 200,
                }}
                src={musician.image_url} />
            </Box>

            <Typography variant="body3">
              {musician.bio}
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Box mt={2} sx={{ height: 550, width: '100%', overflowX: 'auto'  }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={rows.length}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />
      </Box>

      <Outlet
        context={{
          songs
        }}
       />
    </Box>
  )
}
