import _ from "lodash";
import * as React from "react";
import { Outlet, Link as RouterLink, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DialogCloseButton from '../../components/DialogCloseButton';
import Loader from '../../components/Loader';
import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import * as regionActions from '../../actions/regions';

function NewDatingProfilePhotoMain(props) {
  const {
    history,
    params,
    location,
    regions,
    newPromotion,
    deleteNewPromotion,
    user
  } = props;

  const onClose = () => {
    deleteNewPromotion()
    history.replace("../")
  }

  const skipped = !_.get(newPromotion, ["goal"]) && location.pathname != "/app/promotions/new"

  if (skipped) return <Navigate to="/app/promotions/new" />;
  if (regions.length == 0) return <Loader />;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={true}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box />

            <DialogCloseButton onClose={onClose} />
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{p: 0}}>
        <Container maxWidth="md">
          <Outlet />
        </Container>
      </DialogContent>
    </Dialog>
  )
}

const select = $$state => _.pick($$state, "user", "newPromotion", "regions");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewDatingProfilePhotoMain));
