import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import DialogCloseButton from '../../../components/DialogCloseButton';
import TextField from '../../../components/TextField';
import * as request from '../../../utils/adminRequest';

const validationSchema = yup.object({
  probability: yup.number().required(),
  premium: yup.number().required(),
});

export default function AdminPropositionsNew(props) {
  const context = useOutletContext();
  const { proposition, setProposition } = context;

  const navigate = useNavigate();

  const onClose = () => navigate("../", {replace: true});

  const formik = useFormik({
    initialValues: {
      probability: "",
      premium: ""
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.post(`propositions/${proposition.id}/proposition_probabilities`, values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          setProposition({
            ...proposition,
            probabilities: [
              {
                id: data.id,
                ...data.attributes
              },
              ...proposition.probabilities
            ]
          })
          onClose()
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Add an updated probability
        </Typography>
        <Typography variant="body2" color="text.secondary">
          The premium is what we end charge the merchant
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box mt={1}>
          <form onSubmit={formik.handleSubmit} id="probability-form">
            <Stack spacing={2}>
              <TextField
                type="number"
                name="probability"
                value={formik.values.probability}
                onChange={formik.handleChange}
                label="Probability"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                error={formik.touched.probability && Boolean(formik.errors.probability)}
                helperText={formik.touched.probability && formik.errors.probability} />

              <TextField
                type="number"
                name="premium"
                value={formik.values.premium}
                onChange={formik.handleChange}
                label="Premium"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                error={formik.touched.premium && Boolean(formik.errors.premium)}
                helperText={formik.touched.premium && formik.errors.premium} />

            </Stack>
          </form>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={formik.isSubmitting}
          type="submit"
          form="probability-form"
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
