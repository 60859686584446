import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import * as request from '../../../utils/adminRequest';

import EventForm from './Form';

export default function AdminEventsNew(props) {
  const context = useOutletContext();
  const { event, setEvent } = context;

  const navigate = useNavigate();

  const onClose = () => navigate("../", {replace: true});

  const onSubmit = async values => {
    try {
      const { data } = await request.put(`events/${event.id}`, values)
      if (data.errors) {
        alert(data.errors.message)
      } else {
        setEvent({
          id: data.id,
          ...data.attributes
        })

        onClose()
      }

    } catch(err) {
      alert("Unknown error")
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Edit {event.title}
        </Typography>

        <Typography variant="body3" color="text.secondary">
          Choose wisely my friend
        </Typography>
      </DialogTitle>

      <DialogContent>
        <EventForm
          {...context}
          event={event}
          onClose={onClose}
          onSubmit={onSubmit} />
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          type="submit"
          form="event-form"
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
