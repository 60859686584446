import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import DialogCloseButton from '../../components/DialogCloseButton';
import TabPanel from '../../components/TabPanel';
import withRouter from '../../utils/withRouter';
import request from '../../utils/request';
import * as promotionsActions from '../../actions/promotions';
import * as promotionEntryActions from '../../actions/promotionEntries';
import * as promotionPropositionActions from '../../actions/promotionPropositions';

import Alerts from '../Alerts';

function PromotionsDetails(props) {
  const { promotions, fetchPromotionEntries, fetchPromotionPropositions, location, params, history} = props;

  const [promotion, setPromotion] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const promotionId = params.promotionId;

  React.useEffect(() => {
    Promise.all([
      fetchPromotionDetails(promotionId),
      // fetchPromotionEntries(promotionId),
      // fetchPromotionPropositions(promotionId)
    ]).finally(() => setLoading(false))
  }, [promotionId])

  async function fetchPromotionDetails(id) {
    const { data } = await request.get(`promotions/${id}`);

    setPromotion({
      id: data.id,
      ...data.attributes
    })
  }


  const updatePromotion = data => {
    setPromotion({
      ...promotion,
      ...data
    })
  }

  const breadcrumbs = [
    <Link
      color='secondary'
      variant="body3"
      fontWeight={600}
      underline="hover"
      component={RouterLink}
      to="../">
        Promotions
    </Link>,
    <Typography variant="body3" key="3" color="text.secondary" fontWeight={600}>
      { promotion.title }
    </Typography>,
  ];

  const pages = [
    "overview",
    "propositions",
    "shoppers",
    "entries",
    "design",
    "notifications",
    "analytics"
  ];

  const tab = pages.findIndex(page => location.pathname.includes(page));


  if (_.isEmpty(promotion)) return <Box bgcolor="#fff" pt={3}><Loader /></Box>

  return (
    <Box mb={10}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>

        <Chip label={
          <Typography variant="body4" fontWeight={600} textTransform="uppercase">
            {promotion.status}
          </Typography>
        } />
      </Stack>

        <Paper sx={{mt: 2}} variant="outlined">
          <Box  mb={3}>
            <Tabs indicatorColor="secondary" textColor="secondary" value={tab} onChange={(e, tab) => {
              history.push(`./${pages[tab]}`)
            }} sx={{borderBottom: "1px solid #dcd9d9"}}>
              {
                _.map(pages, (page, i) => {
                  return <Tab key={i} index={i} label={
                    _.capitalize(page)
                  } sx={{
                    textTransform: "inherit",
                    fontSize: 14
                  }}  />

                })
              }
            </Tabs>
          </Box>

          <Box pl={2} pr={2} pb={3}>
            <Outlet
              context={{
                promotion,
                updatePromotion
              }} />
          </Box>
        </Paper>

    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions", "promotionPropositions", "promotionEntries");
export default connect(select, {
  ...promotionsActions,
  ...promotionEntryActions,
  ...promotionPropositionActions
})(withRouter(PromotionsDetails));
