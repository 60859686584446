import _ from "lodash";
import Promise from "bluebird";
import * as React from "react";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import WebhookIcon from '@mui/icons-material/Webhook';

import * as integrationActions from '../../actions/integrations';
import withRouter from '../../utils/withRouter';
import Loader from '../../components/Loader';

function IntegrationsMain(props) {
  const { integrations, fetchIntegrations, history } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    Promise.all([
      fetchIntegrations()
    ]).finally(() => setLoading(false))
  }, []);

  return (
    <Box>
      {
        loading ?
          <Loader /> :
          <Outlet />
      }
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "integrations");
export default connect(select, {
  ...integrationActions
})(withRouter(IntegrationsMain));
