import { combineReducers } from "redux";

import alerts from './alerts';
import newPromotion from './newPromotion';
import notifications from './notifications';
import promotions from './promotions';
import promotionEntries from './promotionEntries';
import promotionPropositions from './promotionPropositions';
import merchants from './merchants';
import merchantShoppers from './merchantShoppers';
import merchantUsers from './merchantUsers';
import regions from './regions';
import sports from './sports';
import sportsEvents from './sportsEvents';
import leagues from './leagues';
import teams from './teams';
import players from './players';
import payments from './payments';
import transactions from './transactions';
import user from './user';
import integrations from "./integrations";

export default combineReducers({
  alerts,
  integrations,
  promotions,
  promotionEntries,
  promotionPropositions,
  newPromotion,
  notifications,
  merchants,
  merchantShoppers,
  merchantUsers,
  regions,
  sports,
  sportsEvents,
  leagues,
  players,
  teams,
  payments,
  transactions,
  user,
});
