import _ from "lodash";
import * as React from "react";
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import AutocompleteSportsEvents from '../../components/AutocompleteSportsEvents';
import Link from '../../components/Link';

import Footer from './Footer';

const validationSchema = yup.object({
  events: yup.string()
});

function NewPromotionSportsEvents(props) {
  const { newPromotion, updateNewPromotion, history } = props;

  const formik = useFormik({
    initialValues: {
      sports_events: _.get(newPromotion, ["sports_events"], []),
    },
    validationSchema,
    onSubmit: async values => {
      updateNewPromotion(values);
      history.push("../terms")
    },
  });

  return (
    <Box pb={14}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 0,
          md: 0
        }}>
          <Grid item xs={12} md={12}>
            <Box mb={{
              xs: 2,
              sm: 2
            }}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                Are you targeting specific events?
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Do you specifically want to run the promotion
                around a championship, a single game, etc? If not,
                leave this blank.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mt={1}>
              <AutocompleteSportsEvents
                label="Optionally add sports events"
                leagues={_.map(newPromotion.leagues, "id")}
                regions={_.map(newPromotion.regions, "id")}
                teams={_.map(newPromotion.teams, "id")}
                multiple
                defaultValue={formik.values.sports_events}
                onChange={(e, newValue) => {
                  formik.setFieldValue("sports_events", newValue)
                }} />
            </Box>

              <Box mt={2}>
                <Stack direction="row" spacing={1}>
                  {
                    _.map(newPromotion.leagues, l => {
                      return <Chip key={l.name} label={
                        <Typography variant="body4" fontWeight={600}>
                          {_.toUpper(l.abbrv)}
                        </Typography>
                      }  />
                    })
                  }
                </Stack>
              </Box>
          </Grid>
        </Grid>

        <Footer
          goBack={history.goBack}
          loading={formik.isSubmitting}
          progress={85} />
      </form>
    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion", "sportsEvents");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionSportsEvents));
