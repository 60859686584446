import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import Box from '@mui/material/Box';

import withRouter from '../../utils/withRouter';

import * as promotionActions from '../../actions/promotions';
import * as merchantActions from '../../actions/merchants';
import * as regionsActions from '../../actions/regions';
import * as leaguesActions from '../../actions/leagues';
import * as sportsEventsActions from '../../actions/sportsEvents';

import Loader from '../../components/Loader';

import Alerts from './Alerts';
import Layout from './Layout';

function DashboardHome(props) {
  const {
    user,
    promotions,
    regions,
    merchants,
    fetchRegions,
    leagues,
    fetchLeagues,
    sportsEvents,
    history
  } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [user.id]);

  const fetchData = async () => {
    try {
      if (regions.length == 0) await fetchRegions();
      if (leagues.length == 0) await fetchLeagues();
    } catch(err) {

    }
  }

  if (loading) return <Loader />;

  if (!user.id) return <Navigate to="/" />;
  if (_.isEmpty(user.merchant_user)) return <Navigate to="/auth/merchants" />;

  return (
    <Box>
      <Alerts />
      <Layout />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "merchants", "promotions", "regions", "sports", "sportsEvents", "leagues");
export default connect(select, {
  ...promotionActions,
  ...merchantActions,
  ...regionsActions,
  ...leaguesActions,
  ...sportsEventsActions
})(withRouter(DashboardHome));
