import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation, Outlet } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import DataGrid from '../../../components/DataGrid';
import ClickablePaper from '../../../components/ClickablePaper';
import * as request from '../../../utils/adminRequest';
import * as dates from '../../../utils/dates';

function AdminPropositionsList(props) {
  const { regions, leagues, teams } = props;

  const [propositions, setPropositions] = React.useState([])
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    logicOperator: 'and',
    quickFilterValues: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetchData(
      _.reduce(filterModel.items, (acc, i) => {
        acc[i.field] = i.value;
        return acc;
      }, {})
    )
  }, [filterModel]);

  const fetchData = async query => {
    const { data } = await request.get(`propositions`, {
      params: {
        ...query
      }
    })

    setPropositions(
      _.map(data, u => {
        return {
          ...u,
          ...u.attributes,
        }
      })
    )
  }

  const columns = [
    {
      field: 'event',
      headerName: 'Feed Item',
      width: 300,
      valueGetter: (value) => {
        return _.get(value, ["title"])
      }
    },
    { field: 'title', headerName: 'Title', width: 300 },
    {
      field: 'eligibility_ends_at',
      headerName: 'Ends',
      width: 100,
      valueGetter: (value) => {
        return dates.tzAwareDateTime(value, "MM/DD hh:ssa")
      }
    },
  ]

  const rows = propositions;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) return <Loader />;

  return (
    <Container maxWidth="xl" sx={{pt: 2}}>
      <Box style={{ height: 550, width: '100%', overflowX: 'auto'  }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={hasMore ? rows.length + 1 : rows.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

        {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <AppBar position="fixed" sx={{
        display: "none",
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "rgba(255, 255, 255, 0.7)",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Button component={RouterLink} to="./new" variant="contained" color="secondary" startIcon={<AddIcon />} size="small">
            New Proposition
          </Button>
        </Toolbar>
      </AppBar>

      <Outlet context={{
        propositions,
        setPropositions
      }} />
    </Container>
  )
}

const select = $$state => _.pick($$state, "leagues", "teams");
export default connect(select, {
})(AdminPropositionsList);
