import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';

function BillingBalance(props) {
  const {
    transactions,
    payments,
    history
  } = props;

  const availableBalance = _.chain(transactions)
    .first()
    .get("balance_cents", 0)
    .value() / 100.0;

  const pendingPayments = _.chain(payments)
    .filter(p => p.status == "executing")
    .map("amount_cents")
    .sum()
    .value() / 100.0;

  const pendingBalance = availableBalance + pendingPayments;

  return (
    <Paper variant="outlined" sx={{pt: 8, pb: 8}}>
      <Container maxWidth="sm">
        <Grid container alignItems="space-between">
          <Grid item xs={6}>
            <Box>
              <Typography variant="body3" fontWeight={500} textAlign="center" gutterBottom>
                Available Balance
              </Typography>

              <Typography variant="h4" fontWeight={600} textAlign="center">
                {
                  currencyFormatter(availableBalance, 2)
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="body3" fontWeight={500} textAlign="center" gutterBottom>
                Pending Balance
              </Typography>

              <Typography variant="h4" fontWeight={600} color="primary" textAlign="center">
                {
                  currencyFormatter(pendingBalance, 2)
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Button variant="contained" color="secondary" fullWidth onClick={() => {
            history.push("./new")
          }}>
            Add Funds
          </Button>
        </Box>
      </Container>
    </Paper>
  );
}

const select = $$state => _.pick($$state, "transactions", "payments");
export default connect(select, {
})(withRouter(BillingBalance));

