import _ from "lodash";
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';

import TopNavBar from '../marketing/TopNavBar';
import Footer from './Footer';

function MarketingMain({user, history, location}) {
  React.useEffect(() => {
    analytics.logEvent("Page View", {pathname: location.pathname});
  }, [location.pathname])

  React.useEffect(() => {
    analytics.logEvent("Page View", {pathname: location.pathname});
  }, [location.pathname])

  if (location.pathname == "/" && !!user.id) {
    return <Navigate to="/app" />
  }

  return (
    <Box>
      <TopNavBar
        user={user}
        history={history}
        location={location} />

      <Box sx={{
        background: "#fff",
        pb: 0,
        pt: {
          xs: 11,
          sm: 12
        }
      }}>
        <Box sx={{minHeight: "calc(100vh - 150px)"}}>
          <Outlet />
        </Box>

        <Footer />
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {
})(withRouter(MarketingMain));
