import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../components/DialogCloseButton';
import * as promotionActions from '../../actions/promotions';
import withRouter from '../../utils/withRouter';

function PromotionsDelete(props) {
  const {
    deletePromotion,
    history,
    params
  } = props;

  const { promotionId } = params;

  const [saving, setSaving] = React.useState(false);

  const onClose = () => history.replace("../")

  const destroy = async () => {
    try {
      setSaving(true);
      const results = await deletePromotion(promotionId)
      if (results.errors) {
        alert(results.errors.message)
      } else {
        history.replace("/app/promotions/")
      }
    } catch(err) {
      alert("Unknown error, please try again.")
    } finally {
      setSaving(false);
    }
  }

  return (
    <Box>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={onClose} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            You are about to delete this promotion
          </Typography>

          <Typography variant="body3" color="text.secondary">
            Are you sure you want to do this?
          </Typography>
        </DialogTitle>

        <DialogContent>
        </DialogContent>

        <DialogActions sx={{p: 3}}>
          <LoadingButton
            onClick={destroy}
            loading={saving}
            variant="contained"
            color="error">
            Confirm
          </LoadingButton>

          <Button onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>

      </Dialog>

    </Box>
  );
}

const select = $$state => _.pick($$state, "promotions");
export default connect(select, {
  ...promotionActions
})(withRouter(PromotionsDelete));
