import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import TextField from '../../components/TextField';
import Link from '../../components/Link';
import Logo from '../../components/Logo';

import withRouter from '../../utils/withRouter';
import * as merchantActions from '../../actions/merchants';
import * as userActions from '../../actions/user';

const validationSchema = yup.object({
  name: yup
    .string()
    .required(),
  url: yup
    .string()
    .required(),
});

function MerchantForm(props) {
  const { createMerchant, user } = props;

  const [errors, setErrors] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      phone: ""
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await createMerchant(values);
        if (_.get(results.errors, ["message"])) {
          setErrors(results.errors.message)
        } else if (results.errors) {
          formik.setErrors(results.errors)
        } else {

        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  console.log(user);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        {
          errors != null &&
            <Alert icon={false} color="error" variant="filled">
              <Typography variant="body3" fontWeight={500}>
                {errors}
              </Typography>
            </Alert>
        }

        <TextField
          fullWidth
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          label="Company Name"
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name} />

        <TextField
          fullWidth
          name="url"
          value={formik.values.url}
          onChange={formik.handleChange}
          label="Website"
          error={formik.touched.url && Boolean(formik.errors.url)}
          helperText={formik.touched.url && formik.errors.url} />

        <TextField
          fullWidth
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          label="Phone #"
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone} />


        <LoadingButton
          loading={formik.isSubmitting}
          variant="contained"
          size="large"
          fullWidth
          type="submit">
          Continue
        </LoadingButton>

      </Stack>
    </form>
  )
}

function MerchantsCreate(props) {
  const { user, logout, history, onClose, setScreen} = props;

  const [errors, setErrors] = React.useState(null);

  if (_.isEmpty(user)) return <Navigate to="/" />

  return (
    <Box sx={{
      background: "rgb(250, 248, 245)",
      minHeight: "100vh",
      pt: 8
    }}>
      <Container maxWidth="sm">
        <Stack direction="row" justifyContent="center">
          <Logo />
        </Stack>

        <Paper variant="outlined" sx={{mt: 3, p: 3}}>
          <Typography variant="h5" fontWeight={600}>
            Tell us about your company
          </Typography>
          <Typography variant="body3" color="text.secondary">
            A member of our team will reach out shortly to help you set up your account.
          </Typography>

          <Box mt={2} mb={5}>
            <MerchantForm {...props} />
          </Box>


          <Typography variant="body3" color="text.secondary">
            Are you a shopper looking up your prize from a retailer?
            If so, <Link component={RouterLink} to="/shoppers" color="secondary">please use our shopper app</Link> to look up your rewards.
          </Typography>
        </Paper>

        <Box mt={4}>
          <Stack direction="row" justifyContent="center">
            <Button color="secondary" onClick={logout}>
              Log Out
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...merchantActions,
  ...userActions
})(MerchantsCreate);
