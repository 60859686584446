import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';
import FullScreenDrawer from '../../../components/FullScreenDrawer';
import currencyFormatter from '../../../utils/currencyFormatter';
import * as dates from '../../../utils/dates';

export default function AdminCardsDetails(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [card, setCard] = React.useState({})
  const [transactions, setTransactions] = React.useState([])

  const params = useParams();

  React.useEffect(() => {
    fetchData(params.cardId).finally(() => setLoading(false))
  }, [params.cardId]);

  const fetchData = async id => {
    const { data } = await request.get(`cards/${id}`)

    setCard({
      id,
      balance: data.balance,
      ...data.attributes
    })

    setTransactions(data.transactions);
  }

  const fetchTransactions = async id => {
    const { data } = await request.get(`cards/${id}/`)

    setCard({
      id,
      ...data.attributes
    })

    await fetchTransactions(id)
    setLoading(false)
  }

  if (loading) return <Box />;

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box p={2} width={550}>
        <Box mb={2}>

          <Typography variant="body1" fontWeight={600} textTransform="uppercase">
            {
              _.get(card.issuer, "name") ||
                "User Card"
            } Ending in ({card.last_4})
          </Typography>

          <Typography variant="body3" fontWeight={600}>
            Expires {card.expiration_month}/{card.expiration_year}
          </Typography>
        </Box>

        <Divider />

        <Box mt={3} mb={3}>
          <Stack direction="row" spacing={10}>
            <Box>
              <Typography variant="body1" fontWeight={600}>
                {
                  currencyFormatter(card.amount, 2)
                }
              </Typography>
              <Typography variant="body3">
                Initial Balance
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" fontWeight={600}>
                {
                  currencyFormatter(card.balance, 2)
                }
              </Typography>
              <Typography variant="body3">
                Current Balance
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Divider />

        <Box mt={3}>
          <Stack spacing={1}>
            {
              _.map(transactions, transaction => {
                return (
                  <Paper variant="outlined" key={transaction.id} sx={{p: 2}}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                          {
                            currencyFormatter(transaction.amount, 2)
                          }
                        </Typography>
                        <Typography variant="body3">
                         { transaction.merchant_name }
                        </Typography>
                        <Typography variant="body3">
                          {
                            dates.tzAwareDateTime(
                              transaction.authorized_at ||
                                transaction.created_at
                            )
                          }
                        </Typography>
                      </Box>

                      <Chip
                        size="small"
                        color={
                          transaction.status == "declined" ? "error" : "default"
                        }
                        label={
                          <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                            {transaction.status}
                          </Typography>
                        } />
                    </Stack>
                  </Paper>
                )
              })
            }
          </Stack>
        </Box>
      </Box>
    </FullScreenDrawer>

  )
}
