import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

import ImageUpload from '../../../components/ImageUpload';
import ColorPicker from '../../../components/ColorPicker';
import withRouter from '../../../utils/withRouter';
import request from '../../../utils/request';

import FaqEditor from './FaqEditor';

export default function PromotionsDesignDetails(props) {
  const { promotion, updatePromotion } = useOutletContext();

  const [editedFaQ, setEditedFaq] = React.useState(null);

  const design = _.get(promotion, "design", {});

  const photos = _.get(design, ["photos"], [])
  const colors = _.get(design, ["colors"], {})
  const logo = _.get(design, ["logo"], {})

  const updateDesign = async payload => {
    try {
      const { data } = await request.put(
        `promotions/${promotion.id}/promotion_designs/${design.id}`,
        payload
      )

      if (data.errors) {
        alert(data.errors.message)
      } else {
        updatePromotion({
          design: {
            id: design.id,
            ...data.attributes
          }
        })
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {

    }
  }

  const updateColors = colors => {
    updateDesign({
      ...design,
      colors
    })
  }

  const saveFaqs = faqs => {
    return updateDesign({
      ...design,
      faqs
    }).then(() => {
      setEditedFaq(null)
    })
  }

  return (
    <Box>
      <Box mb={4}>

        <Box mt={2}>
          <Grid container spacing={10}>
            <Grid item md={3} xs={12}>
              <ImageUpload
                photo={logo}
                cropping={false}
                height={170}
                addPhoto={logo => {
                  updateDesign({
                    ...design,
                    logo
                  })
                }}
                label={"Company Logo"} />

            </Grid>

            <Grid item md={4} xs={12}>
              <Box>
                <Typography variant="body4" fontWeight={600} gutterBottom>
                  Brand primary color
                </Typography>
                <ColorPicker color={
                  _.get(colors, ["primary"], "")
                } onChange={color => {
                  updateColors({
                    ...colors,
                    primary: color.hex
                  })
                }} />
              </Box>

              <Box mt={2}>
                <Typography variant="body4" fontWeight={600} gutterBottom>
                  Brand secondary color
                </Typography>
                <ColorPicker color={
                  _.get(colors, ["secondary"], "")
                } onChange={color => {
                  updateColors({
                    ...colors,
                    secondary: color.hex
                  })
                }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider />

      <Box mt={4} mb={4}>
        <Typography variant="body3" fontWeight={600}>
          Promotion collateral
        </Typography>
        <Typography variant="body3" color="text-secondary">
          Optionally add any photos that could be displayed to customers.
        </Typography>

        <Box mt={2}>
          <Grid container spacing={2}>
            {
              _.times(3, i => {
                const photo = photos[i] || {};

                return (
                  <Grid key={i} lg={4} item sm={6} xs={12}>
                    <ImageUpload
                      key={i}
                      photo={photo}
                      cropping={true}
                      height={250}
                      addPhoto={newPhoto => {
                        const updated = _.times(3, n => i == n ? newPhoto : photos[n]);

                        updateDesign({ ...design, photos: updated })
                      }}
                      label={"Upload Image"} />
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </Box>

      <Divider />

      <Box mt={4} mb={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body3" fontWeight={600}>
              Promotion FAQs
            </Typography>
            <Typography variant="body3" color="text-secondary">
              Optionally add as many as is helpful for your shoppers.
            </Typography>
          </Box>
          <Box mb={2} mt={2}>
            <Button variant="outlined" size="small" startIcon={<AddIcon />} onClick={() => {
              setEditedFaq({})
            }}>
              Add FAQ
            </Button>
          </Box>
        </Stack>

        <Stack direction="column" spacing={2}>
          {
            _.map(design.faqs, (faq, i) => {
              return (
                <Paper variant="outlined" key={i} sx={{px: 2, py: 1}}>
                  <Typography variant="body3" fontWeight={600}>
                    {faq.question}
                  </Typography>
                  <Typography variant="body3" color="text-secondary" sx={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}>
                    {faq.answer}
                  </Typography>

                  <Stack direction="row" justifyContent="end" alignItems="center">
                    <Button color="error" size="small" onClick={() => {
                      saveFaqs(_.reject(design.faqs, f => f.question == faq.question))
                    }}>Delete</Button>
                  </Stack>
                </Paper>
              )
            })
          }
        </Stack>

        <FaqEditor
          open={editedFaQ != null}
          onClose={() => setEditedFaq(null)}
          onSubmit={values => saveFaqs([...design.faqs, values])} />
      </Box>

      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
          <Box flexGrow={{
            xs: 0,
            sm: 0
          }}>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Button color="primary"
                variant="outlined"
                component={RouterLink}
                to={`/p/${promotion.token}`}
                target="_blank">
                Preview
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>


    </Box>
  );
}
