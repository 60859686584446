import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckIcon from '@mui/icons-material/Check';

import Link from '../../../components/Link';
import ClickablePaper from '../../../components/ClickablePaper';
import withRouter from '../../../utils/withRouter';
import Loader from '../../../components/Loader';

import * as promotionActions from '../../../actions/promotions';
import request from '../../../utils/request';

import Actions from './Actions';

function PromotionPropositionsList(props) {
  const { user, promotions, history} = props;

  const { promotion } = useOutletContext();
  const propositions = promotion.propositions;

  return (
    <Box>
      <Stack spacing={2}>
        {
          propositions.length == 0 ?
          <Alert icon={false}  color="secondary">
            <Typography variant="body3" fontWeight={500}>
              Propositions are being computed for this promotion.
            </Typography>
          </Alert> :

          _.map(propositions, p => {
            const proposition = p.proposition;

            return (
                <ClickablePaper key={proposition.id} onClick={() => {
                  history.push(`./${proposition.id}`)
                }}>
                  <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Box flexGrow={1}>
                      <Typography variant="body3" fontWeight={500}>
                        {proposition.title}
                      </Typography>

                      <Box>
                        {
                          proposition.start_at == proposition.end_at ?
                            <Typography variant="body3" color="text.secondary">
                              {
                                moment(proposition.start_at).format("MMMM Do YYYY")
                              }
                            </Typography> :
                            <Typography variant="body3" color="text.secondary">
                              {
                                moment(proposition.start_at).format("MMMM Do YYYY")
                              } to {
                                moment(proposition.end_at).format("MMMM Do YYYY")
                              }
                            </Typography>
                        }
                      </Box>
                    </Box>

                      <Stack direction="row" spacing={1} alignItems="center">
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={500}>
                            {promotion.cost.value}% PREMIUM
                          </Typography>
                        } />
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={500}>
                            {_.upperCase(proposition.status)}
                          </Typography>
                        } />

                        {
                          false &&
                            <Actions
                              proposition={proposition}
                              promotion={promotion} />
                        }
                      </Stack>
                  </Stack>
                </ClickablePaper>
              )
            })
        }
      </Stack>

      <Outlet context={{
        promotion
      }} />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "promotions");
export default connect(select, {
  ...promotionActions
})(withRouter(PromotionPropositionsList));
