import _ from "lodash";
import * as React from "react";
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PublicIcon from '@mui/icons-material/Public';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import TextField from '../../components/TextField';
import Link from '../../components/Link';

import Footer from './Footer';

function NewPromotionGeo(props) {
  const { user, newPromotion, updateNewPromotion, history } = props;

  const formik = useFormik({
    initialValues: {
      description: _.get(newPromotion, ["description"], ""),
    },
    onSubmit: async values => {
      updateNewPromotion(values);

      history.push("./save")
    },
  });

  return (
    <Box mb={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 0,
          md: 0
        }}>
          <Grid item xs={12} md={12}>
            <Box mb={{
              xs: 2,
              md: 4
            }}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                Last step, design the copy
              </Typography>

              <Typography variant="body2" color="text.secondary">
                This language will be displayed to your customers.
                You will later be able to also add FAQs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
              />

              <TextField
                fullWidth
                name="subtitle"
                label="Subtitle"
                value={formik.values.subtitle}
                onChange={formik.handleChange}
                error={formik.touched.subtitle && Boolean(formik.errors.subtitle)}
              />
              <TextField
                fullWidth
                name="description"
                label="Describe the promotion in an easily understood way for shoppers"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
              />

            </Stack>
          </Grid>
        </Grid>

        <Footer
          title="finish"
          goBack={history.goBack}
          loading={formik.isSubmitting}
          progress={100} />
      </form>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionGeo));
