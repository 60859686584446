import _ from "lodash";
import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Box from '@mui/material/Box';

export default function AutocompletePlaces(props) {
  const { error, onChange, placeholder, value } = props;

  const geocode = async place => {
    try {
      const results = await geocodeByPlaceId(place.value.place_id)
      const result = results[0]
      const address_components = result.address_components;

      const country = _.find(address_components, a => _.includes(a.types, "country"))
      const state = _.find(address_components, a => _.includes(a.types, "administrative_area_level_1"))
      const city = _.find(address_components, a => _.includes(a.types, "locality"))

      onChange({
        id: result.place_id,
        types: result.types,
        formatted_address: result.formatted_address,
        geometry: result.geometry,
        city,
        state,
        country,
        address_components
      })
    } catch(err) {
      alert("Sorry, could not find that location")
    }
  }

  const styles = _.get(props, ["styles"], {});

  return (
    <Box>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyDsVz0R3kM7NiP-zyewdGoo6mDe22zO7I8"
        selectProps={{
          styles: {
            placeholder: (provided) => ({
              ...provided,
              color: "#726d6d",
              fontSize: _.get(styles, ["placeholder", "fontSize"], "18px")
            }),
            input: (provided) => ({
              ...provided,
              padding: "10px",
            }),
            control: (provided) => ({
              ...provided,
              border: error ? '1px solid red' : "1px solid rgba(0, 0, 0, 0.23)",
              fontSize: _.get(styles, ["control", "fontSize"], "16px")
            }),
            option: (provided) => ({
              ...provided,
              zIndex: 1000000000000,
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 1000000000000,
            }),
          },

          value,
          onChange: geocode,
          placeholder: placeholder || "Search for a location"
        }}
      />
    </Box>
  )
};
