import _ from "lodash";
import * as React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import DialogResponsive from '../../../components/DialogResponsive';
import TextField from '../../../components/TextField';
import request from '../../../utils/request';

export default function PromotionsDesignsFaqEditor(props) {
  const { promotion, updatePromotion } = useOutletContext();

  const navigate = useNavigate();

  const [saving, setSaving] = React.useState(false);

  const notifications = _.get(promotion, "notification", {});
  const [promotionEvents, setPromotionEvents] = React.useState(
    _.get(notifications, ["sms"], {})
  )

  const onClose = () => navigate("../", {replace: true})

  const events = [{
    status: "eligible",
    label: "Notify eligible shoppers about this promotion"
  }, {
    status: "entered",
    label: "Notify shoppers when they are entered into this promotion"
  }, {
    status: "win",
    label: "Notify shoppers when they win or lose this promotion"
  }];

  const save = async () => {
    try {
      setSaving(true)
      const { data } = await request.put(
        `promotions/${promotion.id}/promotion_notifications/${notifications.id}`,
        {
          sms: promotionEvents
        }
      )

      if (data.errors) {
        alert(data.errors.message)
      } else {
        updatePromotion({
          notification: {
            ...promotion.notification,
            sms: promotionEvents
          }
        })

        onClose();
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <DialogResponsive
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={500}>
            Promotion SMS
          </Typography>

          <Typography variant="body3" color="text.secondary">
            Optionally, we can notify your shoppers on various promotional events for you.
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={1}>
          {
            _.map(events, (e, i) => {
              return (
                <Stack key={i} direction="row" alignItems="center" justifyContent="space-between"  sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                }}>
                  <Typography variant="body3" fontWeight={500}>
                    {e.label}
                  </Typography>

                  <Switch
                    checked={
                      promotionEvents[e.status]
                    }
                    onChange={() => {
                      setPromotionEvents({
                        ...promotionEvents,
                        [e.status]: !promotionEvents[e.status]
                      })
                    }} />
                </Stack>
              )
            })
          }
        </Stack>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={saving}
          variant="contained"
          onClick={save}>
          Save
        </LoadingButton>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogResponsive>
  )
}
