import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../components/DialogCloseButton';
import * as newPromotionActions from '../../actions/newPromotion';
import withRouter from '../../utils/withRouter';
import TextField from '../../components/TextField';


function NewPromotionConfirm(props) {
  const {
    createPromotion,
    newPromotion,
    history,
    params
  } = props;

  const [saving, setSaving] = React.useState(false);

  const save = async values => {
    try {
      setSaving(true)
      const results = await createPromotion(newPromotion);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        history.push(`/app/promotions/${results.id}`)
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false)
    }
  };

  const onClose = () => history.replace("../")

  return (
    <Box>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={onClose} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            You are about to save this promotion
          </Typography>
        </DialogTitle>

        <DialogContent>
        </DialogContent>

        <DialogActions sx={{p: 3}}>
          <LoadingButton
            loading={saving}
            onClick={save}
            variant="contained">
            Save
          </LoadingButton>
        </DialogActions>

      </Dialog>

    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionConfirm));
