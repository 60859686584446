import _ from 'lodash';
import * as React from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import DataGrid from '../../../components/DataGrid';
import DialogCloseButton from '../../../components/DialogCloseButton';
import ImageUpload from '../../../components/ImageUpload';
import AutocompletePlaces from '../../../components/AutocompletePlaces';
import * as request from '../../../utils/adminRequest';

export default function MerchantLocations({merchant, setMerchant}) {
  const [locations, setLocations] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open)

  const formik = useFormik({
    initialValues: {
      location: ""
    },
    onSubmit: async ({location}) => {
      try {
        const { data } = await request.post(`merchants/${merchant.id}/merchant_locations`, location)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          window.location.reload()
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  React.useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    const { data } = await request.get(`merchants/${merchant.id}/merchant_locations`)
    setLocations(
      _.map(data, d => {
        return {
          id: d.id,
          ...d.attributes
        }
      })
    )
  }

  const columns = [
    { field: 'formatted_address', headerName: 'Address', width: 200 },
    { field: 'city', headerName: 'City', width: 200 },
    { field: 'state', headerName: 'State', width: 200,},
    { field: 'country', headerName: 'Country', width: 200 },
  ]

  const rows = locations;

  const handlePageChange = (newPage) => {

  };

  return (
    <Box>
      <Stack direction="row" justifyContent="end">
        <Button variant="contained" color="primary" onClick={toggleOpen}>
          Add new
        </Button>
      </Stack>

      <Box mt={2}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowCount={false ? rows.length + 1 : rows.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          onRowSelectionModelChange={row => {
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />
      </Box>

      <Dialog
        open={open}
        onClose={toggleOpen}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={toggleOpen} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            Add Location
          </Typography>

        </DialogTitle>

        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="merchant-new-form">
            <Box mt={1} mb={3}>
              <AutocompletePlaces
                placeholder={
                  _.get(formik.values.location, ["formatted_address"], "Location")
                }
                error={formik.touched.location && Boolean(formik.errors.location)}
                styles={{
                  placeholder: {
                    fontSize: "16px"
                  }
                }}
                onChange={location  => {
                  debugger;
                  formik.setFieldValue(
                    "location",
                    location
                  )
                }} />
              </Box>

              <LoadingButton
                fullWidth
                loading={formik.isSubmitting}
                type="submit"
                variant="contained"
                color="secondary">
                Save
              </LoadingButton>

            </form>

        </DialogContent>
      </Dialog>
    </Box>
  )
}