import _ from 'lodash';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../components/DialogCloseButton';
import DialogBackButton from '../../components/DialogBackButton';
import TextField from '../../components/TextField';
import withRouter from '../../utils/withRouter';
import request from '../../utils/request';

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

function PasswordReset(props) {
  const [error, setError] = React.useState(false);

  const onClose = () => {
    props.history.push("/")
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const { data } = await request.post("users/password_reset", {
        ...values,
        token: props.match.params.id
      })

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setError(false)
        props.history.push("/")
      }
    },
  });

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" fontWeight={600}>
          Set new password
        </Typography>

        {
          error &&
            <Alert sx={{mt: 2}} severity="error" variant="filled">
              <Typography variant="body2">
                There was an error resetting your password.
                Please try again and, if you are once again unsuccessful,
                request a new reset
                <Link to="/auth/password/reset/request" component={RouterLink} color="inherit" sx={{
                  ml: 1
                }}>link here</Link>.
              </Typography>
            </Alert>
        }

        <Box mt={2}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              name="password"
              label="Enter your new password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <LoadingButton sx={{ mt: 2}}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}>
              Submit
            </LoadingButton>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  )
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {})(withRouter(PasswordReset));
