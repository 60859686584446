import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.promotionEntries.CREATE:
      return [
        ...payload.promotionEntries,
        ...$$state
      ];
    case actionTypes.promotionEntries.INDEX:
      return _.uniqBy([
        ...$$state,
        ...payload.promotionEntries
      ], "id");
    case actionTypes.promotionEntries.UPDATE:
      return _.map($$state, d => d.id == payload.promotionEntry.id ?
          {
            ...d,
            ...payload.promotionEntry
          } : d
      )
    case actionTypes.promotionEntries.DELETE:
      return _.reject($$state, d => d.id == payload.promotionEntry.id)
    default:
      return $$state;
  }
};
