import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { deepPurple } from '@mui/material/colors';

export default function Logo(props) {
  return (
    <Link component={RouterLink} to={props.path || "/"} underline="none">
      <Avatar sx={{
        height: 40,
        width: 40,
        bgcolor: deepPurple[500],
        fontWeight: 600
      }}>
        T
      </Avatar>
    </Link>
  );
}