import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchPromotions = query => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`promotions`, {
      params: {
        limit: 20,
        offset: 0,
        ...query
      }
    });

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotions.INDEX,
        payload: {
          promotions: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const createPromotion = (values) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`promotions`, values);

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotions.CREATE,
        payload: {
          promotion: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data;
  }
};

export const updatePromotion = (id, values) => {
  return async (dispatch, getState) => {
    const { data } = await request.put(`promotions/${id}`, values);

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotions.UPDATE,
        payload: {
          promotion: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data;
  }
};

export const deletePromotion = (id) => {
  return async (dispatch, getState) => {
    const { data } = await request.delete(`promotions/${id}`);

    if (!data.errors) {
      dispatch({
        type: actionTypes.promotions.DELETE,
        payload: {
          promotion: {
            id
          }
        }
      });
    }

    return data;
  }
};
