import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.promotionPropositions.CREATE:
      return [
        ...payload.promotionPropositions,
        ...$$state
      ];
    case actionTypes.promotionPropositions.INDEX:
      return _.uniqBy([
        ...$$state,
        ...payload.promotionPropositions
      ], "id");
    case actionTypes.promotionPropositions.UPDATE:
      return _.map($$state, d => d.id == payload.promotionProposition.id ?
          {
            ...d,
            ...payload.promotionProposition
          } : d
      )
    case actionTypes.promotionPropositions.DELETE:
      return _.reject($$state, d => d.id == payload.promotionProposition.id)
    default:
      return $$state;
  }
};
