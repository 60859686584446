import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import * as request from '../../../utils/adminRequest';

export default function AdminPropositionsWin(props) {
  const context = useOutletContext();
  const { proposition, setProposition } = context;

  const navigate = useNavigate();

  const [saving, setSaving] = React.useState(false);

  const onClose = () => navigate("../", {replace: true});

  const save = async () => {
    try {
      setSaving(true);
      const { data } = await request.post(`propositions/${proposition.id}/win`)
      if (data.errors) {
        alert(data.errors.message)
      } else {
        window.location.href = "../"
      }

    } catch(err) {
      alert("Unknown error")
    } finally {
      setSaving(false);
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Are you sure this prop WON?
        </Typography>

        <Typography variant="body3" color="text.secondary">
          There is no going back
        </Typography>
      </DialogTitle>

      <DialogContent>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          onClick={save}
          loading={saving}
          color="secondary"
          variant="contained">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
