import _ from 'lodash';
import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function FullScreenDrawer(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SwipeableDrawer
      onOpen={() => {}}
      disableBackdropTransition={true}
      sx={{
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: fullScreen ? "calc(100vw - 70px)" : "initial"
      },
      ...props.sx
     }}
      {..._.omit(props, ["children", "sx"])}>
     {props.children}
    </SwipeableDrawer>
  );
}

