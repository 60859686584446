import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchRegions = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`regions`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.regions.INDEX,
        payload: {
          regions: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};