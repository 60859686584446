import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';

import DialogCloseButton from '../../components/DialogCloseButton';
import * as newPromotionActions from '../../actions/newPromotion';
import withRouter from '../../utils/withRouter';
import TextField from '../../components/TextField';
import states from '../../utils/states';

function NewPromotionConfirm(props) {
  const {
    updateNewPromotion,
    newPromotion,
    regions,
    history,
    params
  } = props;

  const cities = _.filter(regions, r => r.type == "city");

  const formik = useFormik({
    initialValues: {
      regions: _.get(newPromotion, ["regions"], [])
    },
    onSubmit
  });

  const onClose = () => history.goBack()


  function onSubmit() {
    updateNewPromotion(formik.values);
    onClose();
  }

  return (
    <Box>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={onClose} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            In which cities should this promotion run?
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Add as many as you'd like and we will present different underwritable sports events for each region.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Autocomplete
              multiple
              sx={{ mt: 1 }}
              options={cities}
              getOptionLabel={(option) => option.name}
              defaultValue={formik.values.regions}
              filterSelectedOptions
              onChange={(e, newValue) => {
                formik.setFieldValue("regions", newValue)
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="cities"
                  label="Search cities"
                />
              )} />

          </form>
        </DialogContent>

        <DialogActions sx={{p: 3}}>
          <LoadingButton
            onClick={onSubmit}
            disabled={formik.values.regions.length == 0}
            variant="contained">
            Continue
          </LoadingButton>

          <Button onClick={onClose}>
            Back
          </Button>
        </DialogActions>

      </Dialog>

    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion", "regions");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionConfirm));
