import _ from "lodash";
import * as React from "react";
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import TextField from '../../components/TextField';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import Link from '../../components/Link';

import Footer from './Footer';

const validationSchema = yup.object({
  duration: yup.string().required(),
  start_at: yup.string(),
  end_at: yup.string()
});

function NewPromotionGeo(props) {
  const { user, newPromotion, updateNewPromotion, history } = props;

  const formik = useFormik({
    initialValues: {
      duration: _.get(newPromotion, ["duration"], ""),
      start_at: _.get(newPromotion, ["start_at"], ""),
      end_at: _.get(newPromotion, ["end_at"], ""),
    },
    validationSchema,
    onSubmit: async values => {
      updateNewPromotion({
        ...values,
      });

      history.push("../geo")
    },
  });

  return (
    <Box mb={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 0,
          md: 2
        }}>
          <Grid item xs={12} md={12}>
            <Box mb={{
              xs: 2,
              sm: 2
            }}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                How long should this run?
              </Typography>

              <Typography variant="body2" color="text.secondary">
                You can either explicitly set a start and end date or run it indefinitely.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <Select
                name="duration"
                label={"How long should this promotion run?"}
                value={formik.values.duration}
                onChange={formik.handleChange}
                error={formik.touched.duration && Boolean(formik.errors.duration)}
                options={[
                  {
                    label: "For a set period of time",
                    value: "fixed",
                  },
                  {
                    label: "Indefinitely",
                    value: "indefinitely",
                  }]
                } />

              <Box mt={3} display={formik.values.duration == "fixed" ? "initial" : "none"} >
                <Stack direction="row" spacing={2}>

                  <DatePicker
                    sx={{mb: 2}}
                    label="Start date"
                    inputFormat="MM/DD/yyyy"
                    value={formik.values.start_at}
                    minDate={moment().add(2,'days')}
                    maxDate={moment().add(60,'days')}
                    onChange={date => {
                      formik.setFieldValue("start_at", date);
                    }}
                    renderInput={(params) =>
                      <TextField
                        name="start_at"
                        fullWidth
                        {...params}
                        helperText={formik.touched.start_at && formik.errors.start_at}
                        error={formik.touched.start_at && Boolean(formik.errors.start_at)} />
                    }
                    />

                    <DatePicker
                      label="End date"
                      inputFormat="MM/DD/yyyy"
                      value={formik.values.end_at}
                      minDate={moment().add(2,'days')}
                      maxDate={moment().add(60,'days')}
                      onChange={date => {
                        formik.setFieldValue("end_at", date);
                      }}
                      renderInput={(params) =>
                        <TextField
                          name="end_at"
                          fullWidth
                          {...params}
                          helperText={formik.touched.end_at && formik.errors.end_at}
                          error={formik.touched.end_at && Boolean(formik.errors.end_at)} />
                      } />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Footer
          goBack={history.goBack}
          loading={formik.isSubmitting}
          progress={25} />
      </form>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionGeo));
