import _ from 'lodash';
import moment from "moment";
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import DateTimePicker from '../../../components/DateTimePicker';
import TextField from '../../../components/TextField';
import ImageUpload from '../../../components/ImageUpload';

import * as request from '../../../utils/adminRequest';

const validationSchema = yup.object({
  title: yup.string().required(),
  type: yup.string().required(),
  category: yup.string().required(),
  url: yup.string().required(),
  start_at: yup.string().required(),
  end_at: yup.string().required(),
  photo: yup.object().required(),
});


export default function AdminPropositionForm(props) {
  const { event, onClose, onSubmit } = props;

  const formik = useFormik({
    initialValues: {
      title: _.get(event, ["title"], ""),
      type: _.get(event, ["type"], ""),
      category: _.get(event, ["category"], ""),
      url: _.get(event, ["url"], ""),
      oracle_url: _.get(event, ["oracle_url"], ""),
      description: _.get(event, ["description"], ""),
      story: _.get(event, ["story"], ""),
      category: _.get(event, ["category"], ""),
      start_at: _.get(event, ["start_at"], ""),
      end_at: _.get(event, ["end_at"], ""),
      photo: _.get(event, ["photo"], {}),
      propositions: _.map(
        event.propositions,
        p => {
          return {
            title: p.title,
            probability: p.probability * 100
          }
        }
      )
    },
    validationSchema,
    onSubmit: async values => {
      try {
        // hack because I can't get the propositions array working
        // It resolves currently as an object with keys 0, 1, etc.
        const { data } = await (
          event.id ?
            request.put(`events/${event.id}`, values) :
            request.post(`events`, {
              ...values,
              propositions: _.values(values.propositions)
            })
        )

        if (data.errors) {
          alert(data.errors.message)
        } else {
          window.location.href = "/admin/events/"
        }
      } catch(error) {
        alert(error.message)
      }
    }
  });

  return (
    <Box mt={1}>
      <form onSubmit={formik.handleSubmit} id="event-form">
        <Stack spacing={2}>
          <TextField
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            label="Title"
            fullWidth
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title} />

          <TextField
            select
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            label="Type"
            fullWidth
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}>
            {
              _.map(["Polymarket", "Gravy"], option => (
                <MenuItem key={option} value={option}>
                  { _.capitalize(option) }
                </MenuItem>
              ))
            }
          </TextField>

          <TextField
            select
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            label="Category"
            fullWidth
            error={formik.touched.category && Boolean(formik.errors.category)}
            helperText={formik.touched.category && formik.errors.category}>
            {
              _.map(["crypto", "news", "sports", "culture"], option => (
                <MenuItem key={option} value={option}>
                  { _.capitalize(option) }
                </MenuItem>
              ))
            }
          </TextField>

          <TextField
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            label="Description"
            fullWidth
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description} />

          <TextField
            name="story"
            multiline
            rows={3}
            value={formik.values.story}
            onChange={formik.handleChange}
            label="Story"
            fullWidth
            error={formik.touched.story && Boolean(formik.errors.story)}
            helperText={formik.touched.story && formik.errors.story} />

          <TextField
            name="url"
            value={formik.values.url}
            onChange={formik.handleChange}
            label="URL"
            fullWidth
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url} />

          <TextField
            name="oracle_url"
            value={formik.values.oracle_url}
            onChange={formik.handleChange}
            label="Oracle URL"
            fullWidth
            error={formik.touched.oracle_url && Boolean(formik.errors.oracle_url)}
            helperText={formik.touched.oracle_url && formik.errors.oracle_url} />

          <Stack direction="row" spacing={2}>

            <DateTimePicker
              sx={{mb: 2}}
              label="Start at (UTC)"
              value={formik.values.start_at}
              onChange={date => {
                formik.setFieldValue("start_at", date);
              }}
              renderInput={(params) =>
                <TextField
                  name="start_at"
                  fullWidth
                  {...params}
                  helperText={formik.touched.start_at && formik.errors.start_at}
                  error={formik.touched.start_at && Boolean(formik.errors.start_at)} />
              }
              />

              <DateTimePicker
                label="End at (UTC)"
                value={formik.values.end_at}
                onChange={date => {
                  formik.setFieldValue("end_at", date);
                }}
                renderInput={(params) =>
                  <TextField
                    name="end_at"
                    fullWidth
                    {...params}
                    helperText={formik.touched.end_at && formik.errors.end_at}
                    error={formik.touched.end_at && Boolean(formik.errors.end_at)} />
                } />
          </Stack>

          <ImageUpload
            photo={formik.values.photo}
            cropping={true}
            height={250}
            addPhoto={newPhoto => {
              formik.setFieldValue("photo", newPhoto)
            }}
            label={"Upload Image"} />


        <Box mt={3}>
          <Stack spacing={1}>
            {
              _.times(!!event.id ? 0 : 10, i => (
                <Stack direction="row" spacing={1} key={i}>
                  <TextField
                    name={`propositions[${i}].title`}
                    value={formik.values.propositions[i]?.title || ''}
                    onChange={formik.handleChange}
                    label="Proposition Title"
                    fullWidth />
                  <TextField
                    name={`propositions[${i}].probability`}
                    value={formik.values.propositions[i]?.probability || ''}
                    onChange={formik.handleChange}
                    label="Chance"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }} />

                </Stack>
              ))
            }
          </Stack>
        </Box>
        </Stack>
      </form>
    </Box>
  )
}