import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import qs from 'qs';

import withRouter from '../../../utils/withRouter';
import * as integrationsActions from '../../../actions/integrations';

function IntegrationsShopifyConfirm(props) {
  const { saveIntegrationAccessToken, integrations, history } = props;

  const shopifyIntegrationId = _.get(_.find(integrations, i => i.type == "Integration::Shopify"), "id");

  // code is a URL parameter
  const { code } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const saveAccessToken = async () => {
    try {
      const results = await saveIntegrationAccessToken(shopifyIntegrationId, {code})
      if (results.errors) {
        alert(results.errors);
      } else {
        history.replace("/app/integrations/shopify")
      }
    } catch (e) {
      // history.replace("/app/integrations/shopify")
    }
  }

  React.useEffect(() => {
    saveAccessToken();
  }, []);

  return (
    <Box />
  );
}

const select = $$state => _.pick($$state, "integrations");
export default connect(select, {
  ...integrationsActions
})(withRouter(IntegrationsShopifyConfirm));
