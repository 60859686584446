import _ from "lodash";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import CsvDownloader from 'react-csv-downloader';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function PromotionEntriesSampleCsv(props) {
  const [downloading, setDownloading] = React.useState(false);

  const columns = [{
    id: "shopper_uuid",
    displayName: "Shopper_ID"
  }, {
    id: 'order_id',
    displayName: 'Order_ID'
  }, {
    id: 'order_amount',
    displayName: 'Order_Amount'
  }, {
    id: 'order_currency',
    displayName: 'Order_Currency'
  }, {
    id: 'order_purchased_at',
    displayName: 'Order_Purchased_At'
  }];

  const rows = [{
    shopper_uuid: "ID FROM YOUR SYSTEM OR EMAIL / PHONE",
    order_id: 'A5123',
    order_amount: 50.25,
    order_currency: "usd",
    order_purchased_at: "10/02/35",
  }];

  return (
    <Box>
      <CsvDownloader
        filename="tailrisk-sample"
        extension=".csv"
        separator=","
        wrapColumnChar=""
        columns={columns}
        datas={rows}>
        <Button color="secondary" variant="outlined" size="small">
          Download Sample
        </Button>
      </CsvDownloader>
    </Box>
  );
}
