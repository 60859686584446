import _ from 'lodash';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from "react-redux";
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import './index.css';
import theme from './theme';
import createStore from './store';

import Routing from './containers/Routing';

const INTERCOM_APP_ID = 'g2qdey8c';

export default function App(props) {
  const store = createStore(props);

  return (
    <Provider store={store}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routing />
        </ThemeProvider>
      </IntercomProvider>
    </Provider>
  );
}
