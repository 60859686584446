import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinearProgress from '@mui/material/LinearProgress';

function Progress({value}) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="secondary" variant="determinate" value={value} />
    </Box>
  )
}

export default function NewGiftCardListingFooter(props) {
  return (
    <AppBar position="fixed" sx={{
      top: 'auto',
      bottom: 0,
      borderTop: 1,
      borderColor: 'divider',
      bgcolor: "rgba(255, 255, 255, 0.7)",
      boxShadow: "none",
      color: "inherit",
    }}>
      <Progress value={props.progress || 0} />
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          {
            props.goBack != null &&
              <IconButton onClick={props.goBack}>
                <ArrowBackIcon />
              </IconButton>
          }
        </Box>

        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          disabled={props.disabled || props.loading}
          loading={props.loading}>

          {props.title || "Next"}
        </LoadingButton>
      </Toolbar>
    </AppBar>
  );
}