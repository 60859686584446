import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import Link from '../../../components/Link';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function AdminPayoutApprove(props) {
  const { payout } = useOutletContext();

  const [saving, setSaving] = React.useState(false);

  const navigate = useNavigate();

  const onClose = () => navigate("../", {replace: true});

  const save = async () => {
    try {
      const { data } = await request.post(`payouts/${payout.id}/approve`)
      if (data.errors) {
        alert(data.errors.message)
      } else {
        window.location.href = "/admin/payouts"
      }

    } catch(err) {
      alert("Unknown error")
    }
  }

  if (_.isEmpty(payout)) return <Box />;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Approve this payout
        </Typography>
      </DialogTitle>

      <DialogContent>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          onClick={save}
          loading={saving}
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
