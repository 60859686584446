import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

import FullScreenDialog from '../../components/FullScreenDialog';

import TextField from '../../components/TextField';

import withRouter from '../../utils/withRouter';
import * as merchantUserActions from '../../actions/merchantUsers';

const validationSchema = yup.object({
  emails: yup
    .string()
    .required(),
});

function MerchantUsersForm(props) {
  const { merchantId, createMerchantUsers, onClose } = props;

  const [errors, setErrors] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      emails: ""
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await createMerchantUsers(merchantId, values);
        if (_.get(results.errors, ["message"])) {
          setErrors(results.errors.message)
        } else if (results.errors) {
          formik.setErrors(results.errors)
        } else {
          onClose()
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        multiline
        fullWidth
        rows={3}
        value={formik.values.emails}
        onChange={formik.handleChange}
        label="Comma separate email addresses"
        name="emails"
        error={formik.touched.emails && Boolean(formik.errors.emails)}/>

      <Stack direction="row" justifyContent="flex-end">
        <LoadingButton sx={{ mt: 2}}
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}>
          Invite
        </LoadingButton>
      </Stack>
    </form>
  )
}

function MerchantUsersNew(props) {
  const [errors, setErrors] = React.useState(null);

  const { user, merchants, merchantUsers, history } = props;
  const onClose = () => history.replace("../");

  const formik = useFormik({
    initialValues: {
      emails: "",
    },
    validationSchema,
    onSubmit: async values => {
    }
  });

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" fontWeight={600}>
          Invite colleagues
        </Typography>
        <Typography variant="body3">
          Enter as many email addresses as you'd like below.
        </Typography>

        <Box mt={2}>
          <MerchantUsersForm
            onClose={onClose}
            merchantId={user.merchant_user.merchant_id}
            {...props} />
        </Box>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </FullScreenDialog>
  );
}

const select = $$state => _.pick($$state, "user", "merchants",  "merchantUsers");
export default connect(select, {
  ...merchantUserActions
})(withRouter(MerchantUsersNew));