import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const createPayment = values => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`payments`, values);

    if (!data.errors) {
      dispatch({
        type: actionTypes.payments.CREATE,
        payload: {
          payment: {
            id: data.id,
            ...data.attributes
          }
        },
      })
    }

    return data;
  }
}

export const fetchPayments = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get("payments", {
      params: {
        status: "executing"
      }
    })

    if (!data.errors) {
      dispatch({
        type: actionTypes.payments.INDEX,
        payload: {
          payments: _.map(data, p => {
            return {
              id: p.id,
              ...p.attributes
            }
          })
        },
      })
    }

    return data;
  }
};
