import _ from "lodash";
import * as React from "react";
import { Navigate, Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';

import AccountAvatar from './Avatar';

function AccountDetails(props) {
  const { user, history, logout, updateAvatar } = props;

  return (
    <Box>
      <Container maxWidth="sm">
      	<Stack direction='row' spacing={2} alignItems="center">
          <AccountAvatar user={user} updateAvatar={src => updateAvatar(user, src)}  />

          <Box>
        		<Typography variant="body2" fontWeight={500}>
              {user.name}
            </Typography>

            <Stack direction={{
              xs: "column",
              sm: "row"
            }} spacing={{
              xs: 0,
              sm: 2
            }} alignItems={{
              xs: "initial",
              sm: "center"
            }}>
  	        	<Typography
  	        		color="text.secondary"
  	        		variant="body3">{user.email}</Typography>
  	        </Stack>
          </Box>
	      </Stack>

        <Paper variant="outlined" sx={{mt: 2}}>
          <List
          	dense
            disablePadding
          	sx={{
          		width: "100%",
          		maxWidth: "100vw"
          	 }}>

            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                props.history.push("./contact")
              }}>
                <ListItemText>Contact</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                props.history.push("./funding")
              }}>
                <ListItemText>Billing</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>

          	<ListItem disablePadding divider>
			        <ListItemButton onClick={() => {
			        	props.history.push("./security")
			        }}>
			          <ListItemText>Security</ListItemText>
								<ArrowForwardIosIcon sx={{
									fontSize: 14,
									color: "text.secondary"
								}}  />
			        </ListItemButton>
			      </ListItem>

            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                props.history.push("/help")
              }}>
                <ListItemText>Help</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>

            {
              user.role == "admin" &&
                <ListItem disablePadding divider>
                  <ListItemButton onClick={() => {
                    props.history.push("/admin")
                  }}>
                    <ListItemText fontWeight={600}>Admin</ListItemText>
                    <ArrowForwardIosIcon sx={{
                      fontSize: 14,
                      color: "text.secondary"
                    }}  />
                  </ListItemButton>
                </ListItem>
            }

            <ListItem disablePadding>
              <ListItemButton onClick={() => {
                props.logout();
              }}>
                <ListItemText fontWeight={600}>Logout</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>
        	</List>
        </Paper>
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(withRouter(AccountDetails));
