import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import * as request from '../../../utils/adminRequest';
import * as userActions from '../../../actions/user';

export default function ActionsPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const {
    user,
  } = props;

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget)
  };

  const handleClose = e => {
    if (e) e.stopPropagation();
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  const contextify = async e => {
    handleClose(e);

    const { data } = await request.post(`users/${user.id}/contextify`)

    userActions.setUser({
      id: data.id,
      jwt: data.jwt,
      impersonater_id: data.impersonater_id,
      ...data.attributes
    });

    window.location.reload();
  }

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>

        <List dense disablePadding>

          <ListItem disablePadding divider>
            <ListItemButton onClick={contextify}>
              <ListItemText>
                <Typography variant="body3" fontWeight={600} textAlign="center">
                  Contextify
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
}
