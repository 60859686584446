import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import TextField from '../../../components/TextField';
import AutocompletePlaces from '../../../components/AutocompletePlaces';

import ImageUpload from '../../../components/ImageUpload';
import * as request from '../../../utils/adminRequest';

const validationSchema = yup.object({
  name: yup
    .string()
    .required(),
  url: yup
    .string()
    .required(),
});

export default function AdminMerchantsEdit(props) {
  const { merchant, setMerchant } = useOutletContext();

  const navigate = useNavigate();

  const onClose = () => navigate("../", {replace: true});

  const formik = useFormik({
    initialValues: merchant,
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.put(`merchants/${merchant.id}`, values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          setMerchant({
            ...merchant,
            ...data.attributes
          })

          navigate("../", {replace: true})
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          New Merchant
        </Typography>

      </DialogTitle>

      <DialogContent>
        <Box mt={1}>
          <form onSubmit={formik.handleSubmit} id="merchant-new-form">
            <Stack spacing={2}>
              <TextField
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                label="Name"
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name} />

              <TextField
                name="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                label="Website"
                fullWidth
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url} />

              <AutocompletePlaces
                placeholder={
                  _.get(formik.values.address, ["formatted_address"], "Location")
                }
                error={formik.touched.address && Boolean(formik.errors.address)}
                styles={{
                  placeholder: {
                    fontSize: "16px"
                  }
                }}
                onChange={place => {
                  debugger;
                  formik.setFieldValue("address", place)
                }} />

              <TextField
                name="plaid_merchant_entity_id"
                value={formik.values.plaid_merchant_entity_id}
                onChange={formik.handleChange}
                label="Plaid Merchant Entity ID"
                fullWidth
                error={formik.touched.plaid_merchant_entity_id && Boolean(formik.errors.plaid_merchant_entity_id)}
                helperText={formik.touched.plaid_merchant_entity_id && formik.errors.plaid_merchant_entity_id} />

              <TextField
                type="number"
                name="rebate_delay_days"
                value={formik.values.rebate_delay_days}
                onChange={formik.handleChange}
                label="# of days to rebate"
                fullWidth
                error={formik.touched.rebate_delay_days && Boolean(formik.errors.rebate_delay_days)}
                helperText={formik.touched.rebate_delay_days && formik.errors.rebate_delay_days} />

              <ImageUpload
                photo={formik.values.logo}
                cropping={false}
                height={250}
                addPhoto={newPhoto => {
                  formik.setFieldValue("logo", newPhoto)
                }}
                label={"Upload Logo"} />

            </Stack>
          </form>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          type="submit"
          form="merchant-new-form"
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}
