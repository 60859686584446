import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const createPromotion = values => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`promotions/`, values)

    if (!data.errors) {
      dispatch(deleteNewPromotion());
    }

    return data;
  }
};

export const updateNewPromotion = values => {
  return {
    type: actionTypes.newPromotion.UPDATE,
    payload: {
      newPromotion: values
    },
  };
};

export const deleteNewPromotion = () => {
  return {
    type: actionTypes.newPromotion.DELETE,
    payload: {

    },
  };
};