import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import ClickablePaper from '../../../components/ClickablePaper';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function AdminPaymentsList(props) {
  const [loading, setLoading] = React.useState(false);
  const [payments, setPayments] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [location.search]);

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const fetchData = async () => {
    const { data } = await request.get(`payments`, {
      params: {
        ...search
      }
    })

    setPayments(
      _.map(data, p => {
        return {
          id: p.id,
          ...p.attributes,
          merchant: p.attributes.merchant.name
        }
      })
    )
  }

  const columns = [
    { field: 'merchant', headerName: 'Merchant', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 120,
      valueGetter: v => currencyFormatter(v)
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 150,
      valueGetter: v => moment(v).format("MM/DD/YY")
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      valueGetter: v => _.upperCase(v)
    },
  ]

  const rows = payments;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) return <Loader />;

  return (
    <Container maxWidth="xl">
      <Box sx={{ height: 550, mt: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={hasMore ? rows.length + 1 : rows.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

        {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Outlet />
    </Container>
  )
}
