import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink, useLocation, useOutletContext } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Loader from '../../../components/Loader';
import Link from '../../../components/Link';
import * as request from '../../../utils/adminRequest';

import Actions from './Actions';

export default function AdminMerchantUsers(props) {
  const [loading, setLoading] = React.useState(true);
  const [merchantUsers, setMerchantUsers] = React.useState([]);

  const { merchant } = useOutletContext()

  const fetchData = async () => {
    try {
      const { data } = await request.get(`merchant_users`, {
        params: {
          merchant_id: merchant.id
        }
      });

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setMerchantUsers(
          _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        )
      }
    } catch(err) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  return (
    <Box>
      <Paper variant="outlined">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Added
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Role
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" fontWeight={600}>
                    Status
                  </Typography>
                </TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                _.map(merchantUsers, mu => {
                  return (
                    <TableRow key={mu.id}>
                      <TableCell>
                        <Typography variant="body3" fontWeight={600}>
                          {mu.user.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body3" fontWeight={600}>
                          {mu.user.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body3" fontWeight={600}>
                          {
                            moment(mu.created_at).format("MM/DD/YY")
                          }
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={600}>
                            {_.capitalize(mu.role)}
                          </Typography>
                        } />
                      </TableCell>
                      <TableCell>
                        <Chip size="small" label={
                          <Typography variant="body4" fontWeight={600}>
                            {_.capitalize(mu.status)}
                          </Typography>
                        } />
                      </TableCell>
                      <TableCell>
                        <Actions merchantUser={mu} />
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>


      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>

          <Button variant="outlined" color="secondary" component={RouterLink} to={`./new`}>
            Invite Users
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
