import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import DialogCloseButton from '../../components/DialogCloseButton';
import DialogBackButton from '../../components/DialogBackButton';

import LoginMethod from './LoginMethod';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import Username from './Username';

import AccountPopover from './AccountPopover';

function UserAuth(props) {
  const { user, onAuthentication, open, onClose, history, match } = props;

  const [screenStack, setScreenStack] = React.useState(
    [props.screen || "signup"]
  );

  const screen = _.last(screenStack);

  const next = s => {
    setScreenStack([...screenStack, s])
  };

  const replace = s => {
    setScreenStack([s])
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {
            screenStack.length > 1 ?
              <DialogBackButton onClick={() => {
                setScreenStack(screenStack.slice(0, -1))
              }} /> :
              <Box />
          }

          <DialogCloseButton onClose={onClose} />
        </Stack>
      </DialogTitle>

      <DialogContent>
        {
          screen == "method" &&
            <LoginMethod setScreen={next} />
        }

        {
          screen == "login" &&
            <Login
              {...props}
              onAuthentication={onAuthentication}
              setScreen={next}
              onClose={onClose}  />
        }

        {
          screen == "signup" &&
            <Signup
              {...props}
              onAuthentication={onAuthentication}
              setScreen={replace}
              onClose={onClose}  />
        }

        {
          screen == "forgot_password" &&
            <ForgotPassword {...props} setScreen={next} onClose={onClose}  />
        }

      </DialogContent>
    </Dialog>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, userActions)(withRouter(UserAuth));
