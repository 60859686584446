import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ClickablePaper from './ClickablePaper';

function CsvParser({ onFileLoaded }) {
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setError(null); // Clear any previous errors

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    Papa.parse(file, {
      header: true,       // Indicates that the first row of the CSV file contains column headers
      skipEmptyLines: true,
      complete: (results, file) => {
        if (results.errors.length) {
          setError('Error parsing CSV file.');
          console.error('CSV Parse Errors:', results.errors);
        } else {
          onFileLoaded(results.data, file);
        }
      },
      error: (error) => {
        setError('Error reading CSV file.');
        console.error('CSV Read Error:', error);
      }
    });
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <ClickablePaper
        variant="outlined"
        component="div"
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          padding: 10,
          textAlign: 'center',
          minHeight: 100 }}
      >
        <Typography variant="body3" fontWeight={600}>Upload CSV File</Typography>
      </ClickablePaper>

      {error && <div style={{ color: 'red' }}>{error}</div>}
    </Box>
  );
}

export default CsvParser;