import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoadingButton from '@mui/lab/LoadingButton';

import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';
import TextField from '../../components/TextField';

const validationSchema = yup.object({
});

const AccountSecurity = props => {
  const { updateUserPhone, user, createAlert } = props;

  const formik = useFormik({
    initialValues: {
      phone: _.get(user, ["phone"], ""),
    },
    validationSchema,
    onSubmit: async ({phone}) => {
      const results = await updateUserPhone(user, phone)
      if (results.errors) {
        formik.setErrors(results.errors)
      } else {
        createAlert({title: "Phone updated"})
      }
    }
  });

  React.useEffect(() => {
    if (user.id && !!user.phone && !formik.values.phone) {
      formik.setFieldValue("phone", user.phone)
    }
  }, [user.id])

  const breadcrumbs = [
    <Link
      variant="body3"
      fontWeight={600}
      key="1"
      component={RouterLink}
      to="../">
      Account
    </Link>,
    <Typography variant="body3" key="3" color="text.secondary" fontWeight={600}>
      Contact
    </Typography>,
  ];

  return (
    <Container maxWidth="sm">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Box mt={3}>
        <Paper sx={{p: 3}} variant="outlined">
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                value={props.user.email}
                label="Email"
                disabled={true} />

              <TextField
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                label="Phone" />

              <LoadingButton
                sx={{ mt: 2 }}
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={user.phone == formik.values.phone}
                loading={formik.isSubmitting}>
                Update
              </LoadingButton>
            </Stack>
          </form>

        </Paper>
      </Box>
    </Container>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {
  ...alertActions,
  ...userActions,
})(withRouter(AccountSecurity));
