import React, { useState, useRef, useEffect } from 'react';
import { CustomPicker, ChromePicker } from 'react-color';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ClickablePaper from './ClickablePaper';

function MyColorPicker(props) {
  const { color, onChange } = props;

  const [displayPicker, setDisplayPicker] = useState(false);
  const [hex, setHex] = useState(color);
  const pickerRef = useRef();

  const togglePicker = () => {
    setDisplayPicker(!displayPicker);
  };

  // Event handler for closing the picker if clicked outside
  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      onChange(hex)
      setDisplayPicker(false);
    }
  };

  useEffect(() => {
    // Attach the listeners on component mount
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hex]); // Empty dependency array ensures this effect runs only once after mount

  return (
    <Box>
      <ClickablePaper variant="outlined" onClick={togglePicker}
        sx={{
          padding: '10px',
          cursor: 'pointer',
          background: !!hex ? hex : "#fff",
          height: 40
        }}>
      </ClickablePaper>
      {displayPicker && (
        <Box ref={pickerRef} sx={{ position: 'absolute', zIndex: 2 }}>
          <ChromePicker
            color={hex}
            onChangeComplete={c => setHex(c.hex)}
          />
        </Box>
      )}
    </Box>
  );
}

export default CustomPicker(MyColorPicker);