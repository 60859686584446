import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function LoginMethod(props) {
  const { user, setScreen} = props;
  return (
    <Stack spacing={1}>
      <Box mb={2}>
        <Typography variant="body1" fontWeight={600}>
          Log in to your account
        </Typography>

        <Typography variant="body3" color="text.secondary">
          Select your login method below. You can  connect your crypto wallet
          in the future if you sign up with email or SMS.
        </Typography>
      </Box>

      <Button
        variant="contained"
        fullWidth
        onClick={() => setScreen("web3")}>
        Connect Crypto Wallet
      </Button>

      <Button
        color="secondary"
        variant="contained"
        fullWidth
        onClick={() => setScreen("login")}>
        Log in with Email
      </Button>
    </Stack>
  )
}