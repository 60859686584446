import _ from 'lodash';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { connect } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import * as account from './account';
import * as dashboard from './dashboard';
import * as billing from './billing';
import * as integrations from './integrations';
import * as marketing from './marketing';
import * as merchants from './merchants';
import * as merchantUsers from './merchantUsers';
import * as merchantShoppers from './merchantShoppers';
import * as promotions from './promotions';
import * as promotionAnalytics from './promotions/analytics';
import * as promotionNotifications from './promotions/notifications';
import * as promotionMerchantShoppers from './promotions/merchantShoppers';
import * as promotionDesigns from './promotions/designs';
import * as promotionPropositions from './promotions/propositions';
import * as promotionEntries from './promotions/entries';
import * as newPromotion from './newPromotion';
import * as notifications from './notifications';
import * as settings from './settings';
import * as transactions from './transactions';
import * as user from './user';

import * as integrationsShopify from './integrations/shopify';

import * as administration from './administration';
import * as administrationAccountBalances from './administration/accountBalances';
import * as administrationCards from './administration/cards';
import * as administrationInvoices from './administration/invoices';
import * as administrationPayments from './administration/payments';
import * as administrationPayouts from './administration/payouts';
import * as administrationPropositions from './administration/propositions';
import * as administrationOffers from './administration/offers';
import * as administrationMerchants from './administration/merchants';
import * as administrationMerchantUsers from './administration/merchantUsers';
import * as administrationMerchantDesign from './administration/merchantDesigns';
import * as administrationCardTransactions from './administration/cardTransactions';
import * as administrationPlayers from './administration/players';
import * as administrationMusicians from './administration/musicians';
import * as administrationEvents from './administration/events';
import * as administrationTransactions from './administration/transactions';
import * as administrationUsers from './administration/users';

import Consumer from './Consumer';
import * as consumerPromotions from './consumers/promotions';
import * as demosProxr from './consumers/demos/proxr';

import * as userActions from '../actions/user';

function Routing(props) {
  React.useEffect(() => {
    const user = window.localStorage.getItem("user");

    if (user) {
      props.updateUser(JSON.parse(user));
    } else {

    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">

          <Route path="" element={<marketing.Main />}>
            <Route path="" element={<marketing.Home />} />

            <Route path="how-it-works" element={<marketing.How />} />
            <Route path="about" element={<marketing.About />} />
            <Route path="pricing" element={<marketing.Pricing />} />
            <Route path="privacy" element={<marketing.Privacy />} />
            <Route path="shoppers" element={<marketing.Shoppers />} />
            <Route path="terms" element={<marketing.Terms />} />
          </Route>

          <Route path="auth">
            <Route path="password/reset/:id" element={<user.PasswordReset />} />
            <Route path="merchants" element={<merchants.Create />} />
          </Route>

          <Route path="app" element={<dashboard.Main />}>
            <Route index element={<dashboard.Home />} />

            <Route path="promotions" element={<promotions.Main />}>
              <Route path="">
                <Route index element={<promotions.List />} />
                <Route path=":promotionId" element={<promotions.Details />}>

                  <Route index element={<Navigate to="./overview" /> } />

                  <Route path="overview" element={<promotions.Overview /> }>
                    <Route path="delete" element={<promotions.Delete /> } />
                    <Route path="end" element={<promotions.End /> } />
                    <Route path="start" element={<promotions.Start /> } />
                  </Route>

                  <Route path="shoppers" element={<promotionMerchantShoppers.List />}>
                    <Route path="upload" element={<promotionMerchantShoppers.Upload />} />
                    <Route path=":promotionMerchantShopperId" element={<promotionMerchantShoppers.Details />} />
                  </Route>

                  <Route path="entries" element={<promotionEntries.List />}>
                    <Route path="upload" element={<promotionEntries.Upload />} />
                    <Route path=":promotionEntryId" element={<promotionEntries.Details />} />
                  </Route>

                  <Route path="propositions">
                    <Route path="" element={<promotionPropositions.List />}>
                      <Route path=":propositionId" element={<promotionPropositions.Details />}>
                      </Route>
                    </Route>
                  </Route>

                  <Route path="notifications" element={<promotionNotifications.Details />}>
                    <Route path="sms" element={<promotionNotifications.Sms /> } />
                    <Route path="email" element={<promotionNotifications.Email /> } />
                  </Route>

                  <Route path="analytics" element={<promotionAnalytics.Details />}>
                  </Route>

                  <Route path="design" element={<promotionDesigns.Details /> }>
                  </Route>
                </Route>
              </Route>

              <Route path="new" element={<newPromotion.Main />}>
                <Route path="" element={<newPromotion.Goal />} />

                <Route path="geo" element={<newPromotion.Geo />}>
                  <Route path="regions" element={<newPromotion.Regions />} />
                </Route>

                <Route path="leagues" element={<newPromotion.Leagues />} />
                <Route path="events" element={<newPromotion.Events />} />

                <Route path="schedule" element={<newPromotion.Schedule />} />
                <Route path="requirements" element={<newPromotion.Requirements />} />

                <Route path="terms" element={<newPromotion.Terms />}>
                  <Route path="save" element={<newPromotion.Confirm />} />
                </Route>
              </Route>
            </Route>

            <Route path="shoppers">
              <Route path="" element={<merchantShoppers.List />}>
                <Route path="upload" element={<merchantShoppers.Upload />} />
                <Route path=":merchantShopperId" element={<merchantShoppers.Details />} />
              </Route>
            </Route>

            <Route path="billing" element={<billing.Main />}>
              <Route path="new" element={<billing.New />}>
                <Route index element={<billing.Methods />} />
                <Route path="wire" element={<billing.Wire />} />
                <Route path="wired" element={<billing.Wired />} />
              </Route>

              <Route path="">
                <Route index element={<transactions.List />} />
              </Route>
            </Route>

            <Route path="integrations" element={<integrations.Main />}>
              <Route index element={<integrations.List />} />
              <Route path="shopify" element={<integrationsShopify.Details />}>
                <Route path="confirm" element={<integrationsShopify.Confirm />} />
              </Route>

            </Route>

            <Route path="settings" element={<settings.Main />}>
              <Route index element={<Navigate to="./users" />} />
              <Route path="users" element={<merchantUsers.List />}>
                <Route path="new" element={<merchantUsers.New />} />
              </Route>
            </Route>
          </Route>

          <Route path="demos">
            <Route path="proxr">
              <Route path="checkout" element={<demosProxr.Checkout />}>
                <Route path=":promotionId" element={<consumerPromotions.Details />} />
              </Route>
            </Route>
          </Route>

          <Route path="admin" element={<administration.Main />}>
            <Route index element={<Navigate to="./merchants" />} />

            <Route path="account_balances">
              <Route path="" element={<administrationAccountBalances.List />} />
              <Route path=":accountBalanceId" element={<administrationAccountBalances.Details />}>
                <Route path="" element={<administrationTransactions.List />}>
                  <Route path="transactionId" element={<administrationTransactions.Details />} />
                </Route>
              </Route>
            </Route>

            <Route path="cards">
              <Route path="" element={<administrationCards.List />}>
                <Route path="new" element={<administrationCards.New />} />
                <Route path="promise" element={<administrationCards.Promise />} />

                <Route path=":cardId" element={<administrationCards.Details />}>
                </Route>
              </Route>
            </Route>

            <Route path="musicians">
              <Route path="">
                <Route path="" element={<administrationMusicians.List />}>
                  <Route path="new" element={<administrationMusicians.New />} />
                </Route>

                <Route path=":musicianId" element={<administrationMusicians.Details />}>
                  <Route path=":songId" element={<administrationMusicians.Song />} />
                </Route>
              </Route>
            </Route>

            <Route path="payments">
              <Route path="" element={<administrationPayments.Main />}>
                <Route path="" element={<administrationPayments.List />}>
                  <Route path=":paymentId" element={<administrationPayments.Details />} />
                </Route>

                <Route path="invoices" element={<administrationInvoices.List />} />
              </Route>
            </Route>

            <Route path="merchants">
              <Route path="" element={<administrationMerchants.List />}>
                <Route path="new" element={<administrationMerchants.New />} />
                <Route path="bulk" element={<administrationMerchants.Bulk />} />
                <Route path="bulk_merchant_locations" element={<administrationMerchants.BulkLocations />} />
              </Route>

              <Route path=":merchantId" element={<administrationMerchants.Details />}>
                <Route index element={<administrationMerchantUsers.List />} />
                <Route path="design" element={<administrationMerchantDesign.Details />} />
                <Route path="delete" element={<administrationMerchants.Delete />} />
                <Route path="edit" element={<administrationMerchants.Edit />} />
              </Route>
            </Route>

            <Route path="players">
              <Route path="" element={<administrationPlayers.List />}>
                <Route path="new" element={<administrationPlayers.New />} />
              </Route>

              <Route path=":playerId" element={<administrationPlayers.Details />}>
                <Route path="edit" element={<administrationPlayers.Edit />} />
              </Route>
            </Route>

            <Route path="offers">
              <Route path="" element={<administrationOffers.List />}>
                <Route path=":offerId" element={<administrationOffers.Details />}>
                  <Route path="approve" element={<administrationOffers.Approve />} />
                  <Route path="reject" element={<administrationOffers.Reject />} />
                </Route>
              </Route>
            </Route>

            <Route path="payouts">
              <Route path="" element={<administrationPayouts.List />}>
                <Route path=":payoutId" element={<administrationPayouts.Details />}>
                  <Route path="approve" element={<administrationPayouts.Approve />} />
                  <Route path="reject" element={<administrationPayouts.Reject />} />
                </Route>
              </Route>
            </Route>

            <Route path="events">
              <Route path="" element={<administrationEvents.List />}>
                <Route path="new" element={<administrationEvents.New />}>
                </Route>
              </Route>

              <Route path=":eventId" element={<administrationEvents.Details />}>
                <Route path="edit" element={<administrationEvents.Edit />} />
                <Route path="delete" element={<administrationEvents.Delete />} />
                <Route path="publish" element={<administrationEvents.Publish />} />
                <Route path="propositions">
                  <Route path=":propositionId" element={<administrationEvents.PropositionEdit />} />
                  <Route path="new" element={<administrationEvents.PropositionNew />} />
                </Route>
              </Route>
            </Route>

            <Route path="propositions">
              <Route path="" element={<administrationPropositions.List />}>
                <Route path=":propositionId" element={<administrationPropositions.Details />}>
                  <Route path="delete" element={<administrationPropositions.Delete />} />
                  <Route path="win" element={<administrationPropositions.Win />} />
                  <Route path="lose" element={<administrationPropositions.Lose />} />
                  <Route path="probability/new" element={<administrationPropositions.ProbabilityNew />} />
                </Route>
              </Route>
            </Route>


            <Route path="users" element={<administrationUsers.Main />}>
              <Route path="" element={<administrationUsers.List />} />

              <Route path=":userId" element={<administrationUsers.Details />}>
                <Route path="transactions" element={<administrationUsers.Details />}>
                  <Route path=":transactionId" element={<administrationCardTransactions.Details />} />
                </Route>
              </Route>
            </Route>


          </Route>

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(Routing);
