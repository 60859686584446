import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import ClickablePaper from '../../components/ClickablePaper';
import withRouter from '../../utils/withRouter';

function BillingMethods(props) {
  const { history } = props;

  const paymentMethods = [
    {
      name: "Wire",
      slug: "wire",
      fee: 0
    },
    {
      slug: "invoice",
      name: "Invoice",
      fee: 0
    },
    {
      slug: "bank",
      name: "ACH Transfer",
      status: "pending",
      fee: 0
    },
    // {
    //   slug: "credit",
    //   name: "Credit",
    //   fee: 3
    // }
  ];

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Funding Method
      </Typography>
      <Typography variant="body3">
        Fund your promotions with one of the following methods.
      </Typography>

      <Box mt={2}>
        <Stack direction="column" spacing={1}>
          {
            _.map(paymentMethods, (method, index) => (
                <ClickablePaper
                  key={method.name}
                  variant="outlined"
                  onClick={() => {
                    if (method.slug == "wire") {
                      history.push("./wire")
                    }
                  }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body3" fontWeight={500} color={
                      method.status == "pending" ? "text.secondary" : "text.primary"
                    }>
                      {
                        `${method.name}`
                      }
                    </Typography>

                    <Stack spacing={1} direction="row" alignItems="center">
                      {
                          !!method.fee ?
                            <Chip label={`${method.fee}% fee`} /> :
                            null
                      }

                      {
                          method.status == "pending" ?
                            <Chip size="small" label={"Coming Soon"} /> :
                            null
                      }

                      <KeyboardArrowRightIcon />
                    </Stack>
                  </Stack>
                </ClickablePaper>
              )
            )
          }
        </Stack>
      </Box>
    </Box>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
})(withRouter(BillingMethods));
