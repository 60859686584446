import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, useLocation, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import TabPanel from '../../components/TabPanel';
import Loader from '../../components/Loader';

function SettingsMain(props) {
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();

  const {
    history,
    user
  } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [tab, setTab] = React.useState(
    search.tab ? 1 : 0
  );

  const fetchData = () => Promise.all([
  ])

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const breadcrumbs = [
    <Link
      color='secondary'
      variant="body3"
      key="1"
      fontWeight={600}
      underline="hover"
      key="1"
      component={RouterLink}
      to="../">
        Account
    </Link>,
    <Typography variant="body3" key="3" color="text.secondary" fontWeight={600}>
      Billing
    </Typography>,
  ];

  const pages = ["team", "security"];

  return (
    <Container maxWidth="lg">
      {
        false &&
          <Typography variant="body2" fontWeight={500}>
            {user.merchant_user.merchant_id}
          </Typography>

      }

      <Box mt={3}>
        <TabPanel value={tab} index={0}>
          <Outlet />
        </TabPanel>
      </Box>
    </Container>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {

})(withRouter(SettingsMain));
