import _ from 'lodash';
import React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import withRouter from '../../utils/withRouter';

import {Title} from './Components';

export default function MarketingShoppers(props) {
  const interests = [
    "Meals",
    "Drinks",
    "Game Tickets",
    "Furniture",
    "Clothing",
  ];

  return (
    <Box sx={{
      position: "relative",
      top: {
        xs: 10,
        md: -25
      },
      bottom: "-35px"
    }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={5}>
            <Title>
              Amazing deals tied to your local sports teams and players.
            </Title>

            <Box mt={1}>
              <Typography variant="body2" fontWeight={400}>
                Get your entire purchases at major online and local retailers refunded when your sports teams crush it!
              </Typography>
            </Box>

            <Box mt={3}>
              <Button
                component={RouterLink}
                to="/shop"
                fullWidth
                variant="contained"
                size="large">
                View Offers
              </Button>

                <Button
                  sx={{mt: 1}}
                  component={RouterLink}
                  to="./signup"
                  fullWidth
                  variant="outlined"
                  size="large">
                  I've already received an offer
                </Button>
            </Box>

            <Box mt={3}>
              <Typography variant="body2" color="text.secondary" fontWeight={400}>
                If a pitcher throws a no-hitter this week, I win a free...
              </Typography>

              <Box mt={1}>
                {
                  _.map(interests, interest => (
                    <Chip
                      key={interest}
                      label={interest}
                      size="small"
                      variant="outlined"
                      color="primary"
                      icon={
                        <FavoriteBorderIcon style={{fontSize: "14px"}} />
                      }
                      sx={{
                        mr: 2,
                        mb:1,
                        fontSize: 12,
                        fontWeight: 600
                      }} />
                  ))
                }
              </Box>

              <Typography mt={4} variant="body3" color="text.secondary" fontWeight={400} sx={{
                display: {
                  xs: "none",
                  md: "block"
                }
              }}>
                * The HotelTonight promotion is meant as a demonstration only and not a live promotion on TailRisk.
              </Typography>
            </Box>

          </Grid>
          <Grid item xs={12} md={7} sx={{display:
            {
              xs: "none",
              sm: "block"
            }
          }}>
            <Grid item>
              <Box
                maxHeight={"100vh"}
                minHeight="608px"
                component="img"
                src={"https://p49.tr4.n0.cdn.zight.com/items/2Nu9lpzp/2904e93e-17ed-473f-9e69-b16dc2611e91.jpeg?source=viewer&v=5353efa8c18289f58d0a86c3180977c9"} />
            </Grid>
          </Grid>
        </Grid>

        <Outlet />
      </Container>
    </Box>
  )
}
