import _ from "lodash";
import * as React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';

import FullScreenDrawer from '../../components/FullScreenDrawer';

export default function MarketingSideNavBar(props) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const onClose = () => setOpen(false);

  const pathFromPage = page => {
    switch(_.lowerCase(page)) {
      case "about":
        return "/about";
      case "pricing":
        return "/pricing";
      case "how it works":
        return "/how-it-works";
      case "help":
        return "/help";

    }
  }

  return (
    <Box>
      <IconButton sx={{
        pl: {
          xs: 0,
          sm: 0
        }
      }} onClick={() => setOpen(true)}>
        <MenuIcon />
      </IconButton>

      <FullScreenDrawer
        open={open}
        anchor="right"
        onClose={onClose}>

        <Box p={{
          xs: 2,
          sm: 3
        }}>

          <Box mt={3}>
            <List dense>
              {
                [
                  "About",
                  "Pricing",
                  "Help",
                  "Privacy",
                  "Terms"
                ].map((page, index) => (
                <ListItem divider dense key={page} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() => {
                      navigate(pathFromPage(page));
                      onClose();
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}>
                    <ListItemText primary={
                      <Typography variant="body3" fontWeight={500}>
                        {_.capitalize(page)}
                      </Typography>
                    }  />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </FullScreenDrawer>
    </Box>
  );
};
