import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import TextField from '../../../components/TextField';
import AutocompleteLeagues from '../../../components/AutocompleteLeagues';
import AutocompleteTeams from '../../../components/AutocompleteTeams';
import ImageUpload from '../../../components/ImageUpload';
import * as request from '../../../utils/adminRequest';

const validationSchema = yup.object({
  name: yup
    .string()
    .required(),
  avatar: yup
    .object()
    .required()
});

function AdminNewPlayer(props) {
  const { leagues, teams } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onClose = () => navigate("../", {replace: true});

  const formik = useFormik({
    initialValues: {
      name: "",
      league_id: "",
      team: {},
      avatar: {}
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.post(`players`, {
          ...values,
          team_id: values.team.id
        })
        if (data.errors) {
          alert(data.errors.message)
        } else {
          window.location.href = "/admin/players"
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  console.log(formik.values)
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          New Player
        </Typography>

      </DialogTitle>

      <DialogContent>
        <Box mt={1}>
          <form onSubmit={formik.handleSubmit} id="merchant-new-form">
            <Stack spacing={2}>
              <TextField
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                label="Name"
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name} />

              <AutocompleteTeams
                multiple={false}
                defaultValue={formik.values.team}
                onChange={(e, newValue) => {
                  formik.setFieldValue("team", newValue)
                }} />

              <AutocompleteLeagues
                value={formik.values.league_id}
                onChange={value => {
                  formik.setFieldValue("league_id", value)
                }} />

              <ImageUpload
                photo={formik.values.avatar}
                cropping={true}
                croppingAspectRatio={1}
                height={250}
                addPhoto={newPhoto => {
                  formik.setFieldValue("avatar", newPhoto)
                }}
                label={"Upload Profile Photo"} />

            </Stack>
          </form>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          type="submit"
          form="merchant-new-form"
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>

      </DialogActions>

    </Dialog>
  );
}

const select = $$state => _.pick($$state, "teams", "leagues");
export default connect(select, {
})(AdminNewPlayer);
