import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import DataGrid from '../../../components/DataGrid';
import DialogCloseButton from '../../../components/DialogCloseButton';
import * as request from '../../../utils/adminRequest';
import * as dates from '../../../utils/dates';

export default function AdminPropositionDetails(props) {
  const navigate = useNavigate();

  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [proposition, setProposition] = React.useState({})

  const onClose = () => navigate("../", {replace: true});


  const params = useParams();

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async id => {
    const { data } = await request.get(`propositions/${params.propositionId}`)

    setProposition({
      id: data.id,
      ...data.attributes
    })

    setLoading(false)
  }

  const probabilityColumns = [
    {
      field: 'probability',
      headerName: 'Probability',
      width: 200,
      valueGetter: (value) => {
        return `${value}%`
      }
    },
    {
      field: 'premium',
      headerName: 'Premium',
      width: 200,
      valueGetter: (value) => {
        return `${value}%`
      }
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 150,
      valueGetter: (value) => {
        return moment(value).format("MM/DD/YY")
      }
    },
  ]

  if (loading) return <Loader />;
  if (_.isEmpty(proposition)) return <Loader />;

  const date = moment(proposition.eligbility_ends_at).format("YYYYMMDD");

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          {proposition.title}
        </Typography>

        <Typography variant="body3" color="text.secondary">
          {proposition.description}
        </Typography>

        {
          !!proposition.event_id &&
            <Link
              target="_blank"
              href={`/admin/events/${proposition.event_id}`}
              variant="body3"
              fontWeight={600}
              color="secondary">
              View Event
            </Link>
        }
      </DialogTitle>

      <DialogContent>
        <Box>
          <Typography variant="body3" fontWeight={600}>
            Eligibility Ends At
          </Typography>

          <Typography variant="body3" color="text.secondary">
            {
              dates.tzAwareDateTime(
                proposition.eligibility_ends_at
              )

            }
          </Typography>
        </Box>

        <Box mt={3} mb={3}>
          <Box sx={{ height: 400, mt: 2 }}>
            <DataGrid
              rows={proposition.probabilities}
              columns={probabilityColumns}
              pageSize={20}
              paginationMode="server"
              components={{
                Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
                HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
              }}
            />
          </Box>
        </Box>

      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button
          component={RouterLink}
          to="./win"
          color="secondary"
          variant="contained">
          Won
        </Button>
        <Button
          component={RouterLink}
          to="./lose"
          color="error"
          variant="contained">
          Lost
        </Button>
      </DialogActions>
      <Outlet context={{
        proposition,
        setProposition,
      }} />
    </Dialog>
  )
}
