import _ from "lodash";
import * as React from "react";
import qs from 'qs';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ScienceIcon from '@mui/icons-material/Science';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

import ClickablePaper from '../../components/ClickablePaper';
import DialogCloseButton from '../../components/DialogCloseButton';
import DialogResponsive from '../../components/DialogResponsive';
import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';

function marketingFeatures(props) {
  const {user, history, location} = props;

  const onClose = () => history.replace("/")
  return (
    <DialogResponsive
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      sx={{
        zIndex: 100000000,
      }}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Welcome to TailRisk
        </Typography>
        <Typography variant="body3" color="text.secondary">
          AI and human expertise to help you promote
        </Typography>

      </DialogTitle>

      <DialogContent>
        <Stack spacing={1}>
          <ClickablePaper disabled={true} variant="outlined" onClick={() => history.push("/app/onboarding")}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body3" fontWeight={500}>
                    Blarg
                  </Typography>
                </Stack>

                <Typography variant="body3" color="text.secondary">
                  Actionable insights from AI
                </Typography>
              </Box>

            </Stack>
          </ClickablePaper>
          <ClickablePaper disabled={true} variant="outlined" onClick={() => history.push("/app/onboarding")}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body3" fontWeight={500}>
                      Content Experiments
                    </Typography>
                  </Stack>

                  <Typography variant="body3" color="text.secondary">
                    Discover your best dating profile photos with science.
                  </Typography>
                </Box>

              </Stack>
            </ClickablePaper>

          <ClickablePaper variant="outlined" onClick={() => history.push("/app/onboarding")}>
            <Stack direction="row" spacing={1} alignItems="center">

              <Box>
                <Typography variant="body3" fontWeight={500}>
                  Banter AI
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Clever replies that increase matches with low effort.
                </Typography>
              </Box>

            </Stack>
          </ClickablePaper>

          <ClickablePaper variant="outlined" onClick={() => history.push("/app/onboarding")}>
            <Stack direction="row" spacing={1.5}>
              <Box>
                <Typography variant="body3" fontWeight={500}>
                  Community
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Online dating content from experts and other daters.
                </Typography>
              </Box>
            </Stack>
          </ClickablePaper>
        </Stack>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button onClick={onClose}>
          Close
        </Button>

      </DialogActions>

    </DialogResponsive>
  );
}

const select = $$state => _.pick($$state, "user", "marketingFeatures");
export default connect(select, {
})(withRouter(marketingFeatures));
