import _ from 'lodash';
import { connect } from 'react-redux';
import * as React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import * as leagueActions from "../actions/leagues";

function AutocompleteLeagues(props) {
  const { leagues, value, onChange } = props;

  return (
    <Select
      label={"Select league"}
      value={value || ""}
      onChange={e => onChange(e.target.value)}>
        {
          _.map(leagues, l => {
            return <MenuItem key={l.id} value={l.id}>
              <Typography variant="body2">{l.name}</Typography>
            </MenuItem>
          })
        }

      </Select>
  )
}

const select = $$state => _.pick($$state, "leagues");
export default connect(select, {
  ...leagueActions
})(AutocompleteLeagues);
