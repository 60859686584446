import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import GoogleSignup from './GoogleSignup';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import TextField from '../../components/TextField';
import Link from '../../components/Link';

const validationSchema = yup.object({
  login: yup
    .string()
    .required(),
  password: yup
    .string()
    .required(),
});

function LoginForm({login, onClose, onAuthentication}) {
  const [errors, setErrors] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const results = await login(values);
        if (_.get(results.errors, ["message"])) {
          setErrors(results.errors.message)
        } else if (results.errors) {
          formik.setErrors(results.errors)
        } else {
          setErrors(null)

          if (onAuthentication) {
            onAuthentication()
          } else {
            onClose()
          }
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        {
          errors != null &&
            <Alert icon={false} color="error" variant="filled">
              <Typography variant="body3" fontWeight={500}>
                {errors}
              </Typography>
            </Alert>
        }

        <TextField
          fullWidth
          name="login"
          value={formik.values.login}
          onChange={formik.handleChange}
          label="Enter email"
          error={formik.touched.login && Boolean(formik.errors.login)}
          helperText={formik.touched.login && formik.errors.login} />

        <TextField
          fullWidth
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          label="Enter password"
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password} />

        <LoadingButton loading={formik.isSubmitting}
          variant="contained"
          fullWidth
          type="submit">
          Log in
        </LoadingButton>

      </Stack>
    </form>
  )
}

export default function Login(props) {
  const { user, disableSignup, history, login, onClose, setScreen} = props;
  const [errors, setErrors] = React.useState(null);

  const onGoogleSignin = async values => {
    const results = await login(values);
    if (results.errors) {
      setErrors(results.errors.message)
    } else {
      setErrors(null);
      onClose();
    }
  }

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Login
      </Typography>

      {
        !disableSignup &&
          <Typography variant="body3" color="text.secondary">
            Don't have an account? Please <Link
              underline={"always"} href="#" onClick={e => {
                e.preventDefault();
                setScreen("signup")
              }}>sign up</Link>.
          </Typography>
      }

      <Box mt={2}>
        <GoogleSignup onSuccess={credentials => {
          onGoogleSignin({google_oauth_token: credentials.credential})
        }} onError={() => {
          alert("Failed to login, please try again")
        }} />

        {
          errors != null &&
            <Alert icon={false} color="error" variant="filled" sx={{mt: 2}}>
              <Typography variant="body3" fontWeight={500}>
                {errors}
              </Typography>
            </Alert>
        }

        <Divider sx={{mt: 2, mb: 2}}>
          <Typography variant="body3">Or</Typography>
        </Divider>

        <LoginForm {...props} />

        <Box mt={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button sx={{textTransform: "initial"}} onClick={() => {
              setScreen("forgot_password")
            }}>
              Forgot password
            </Button>

            {
              !disableSignup &&
                <Button sx={{textTransform: "initial"}} onClick={() => {
                  setScreen("signup")
                }}>
                  Sign Up
                </Button>
            }
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
