import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.newPromotion.CREATE:
      return payload.newPromotion;
    case actionTypes.newPromotion.DELETE:
      return {}
    case actionTypes.newPromotion.UPDATE:
      return {
        ...$$state,
        ...payload.newPromotion
      }
    default:
      return $$state;
  }
};
