import _ from "lodash";
import * as React from "react";
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import * as sportsActions from '../../actions/sports';
import ClickablePaper from '../../components/ClickablePaper';
import Link from '../../components/Link';

import Footer from './Footer';

function NewPromotionSports(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { leagues, newPromotion, updateNewPromotion, history } = props;

  const formik = useFormik({
    initialValues: {
      leagues: _.get(
        newPromotion,
        ["leagues"],
        []
      )
    },
    onSubmit: async values => {
      updateNewPromotion(values)
      history.push("../requirements")
    },
  });

  return (
    <Box mb={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 2,
          md: 0
        }}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                Which leagues are you targeting?
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Choose one or more. Our underwriting engine will propose events across
                those sports for you to choose among.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <List direction="column">
              {
                _.map(leagues, (league, i) => {
                  return (
                    <ListItem key={i} disablePadding divider={i < leagues.length - 1}>
                      <ListItemButton onClick={() => {
                        formik.setFieldValue(
                          "leagues",
                          _.xor(formik.values.leagues, [league])
                        )
                      }}>
                        <ListItemIcon>
                          <Checkbox
                            color="secondary"
                            disableRipple
                            edge="start"
                            checked={_.includes(formik.values.leagues, league)}
                            tabIndex={-1}
                          />
                        </ListItemIcon>

                        <ListItemText>
                          <Typography variant="body2" color="text.primary" fontWeight={500}>
                            {
                              _.upperCase(league.abbrv)
                            }
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }
            </List>
          </Grid>
        </Grid>

        <Footer
          goBack={history.goBack}
          disabled={formik.values.leagues.length == 0}
          loading={formik.isSubmitting}
          progress={70} />
      </form>
    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion", "leagues", "sports");
export default connect(select, {
  ...newPromotionActions,
  ...sportsActions
})(withRouter(NewPromotionSports));
