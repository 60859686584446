import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import withRouter from '../../utils/withRouter';
import request from '../../utils/request';
import TextField from '../../components/TextField';
import Link from '../../components/Link';

const validationSchema = yup.object({
  email: yup
    .string()
    .required(),
});

export default function ForgotPassword(props) {
  const { user, history, login, onClose, setScreen} = props;
  const [submitted, setSubmitted] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.post("users/password_reset_request", values);
        if (data.errors) {
          alert(data.errors.message)
        } else {
          setSubmitted(true)
        }
      } catch (err) {
        alert("Unknown server error")
      }
    }
  });

  return (
    <Box>
      <Typography variant="body2" fontWeight={600}>
        Forgot Password
      </Typography>

      <Typography variant="body3" color="text.secondary">
        Enter your email address below to reset your password.
      </Typography>

      <Box mt={2}>
        {
          submitted ?
            <Typography variant="body3" fontWeight={500}>
              Please check your inbox for a password reset email
            </Typography> :
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  label="Enter email"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email} />

                <LoadingButton loading={formik.isSubmitting}
                  variant="contained"
                  fullWidth
                  type="submit">
                  Request Reset
                </LoadingButton>

              </Stack>
            </form>


        }
      </Box>
    </Box>
  );
}
