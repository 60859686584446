import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useParams, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DialogCloseButton from '../../../components/DialogCloseButton';
import DataGrid from '../../../components/DataGrid';

export default function AdminNewMusician(props) {
  const { songs } = useOutletContext();

  const navigate = useNavigate();
  const params = useParams();

  const onClose = () => navigate("../", {replace: true});

  const song = _.find(songs, s => s.id == params.songId)

  const columns = [
    { field: 'created_at', headerName: 'Added', width: 200 },
    {
      field: 'spotify_plays',
      headerName: 'Spotify Plays',
      width: 150,
      valueGetter: _.toUpper
    },
  ]

  const rows = song.metrics

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          {song?.title}
        </Typography>

      </DialogTitle>

      <DialogContent>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowCount={rows.length}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

      </DialogContent>

      <DialogActions sx={{p: 3}}>

      </DialogActions>

    </Dialog>
  );
}
