import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const setUser = user => {
  window.localStorage.setItem("user", JSON.stringify(user));

  return {
    type: actionTypes.user.SHOW,
    payload: {
      user
    },
  };
};

export const updateUser = user => {
  return {
    type: actionTypes.user.UPDATE,
    payload: {
      user
    },
  };
};

export const login = value => {
  return async (dispatch, getState) => {
    const { data } = await request.post("user_sessions", value);

    if (!data.errors) {
      const user = {
        id: data.id,
        jwt: data.jwt,
        ...data.attributes
      };

      dispatch(setUser(user))
    }
    return data;
  }
};

const clearUserAndReloadPage = () => {
  window.localStorage.removeItem("user");
  window.location.reload();
  window.location.href = "/";
}

export const logout = value => {
  return async (dispatch, getState) => {
    const { data } = await request.post("user_sessions/logout");
    clearUserAndReloadPage();

    // dispatch({
    //   type: actionTypes.user.DELETE,
    //   payload: {}
    // })
  }
};

export const ping = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get("user_sessions");

    if (data.errors && data.errors.message == "Not authenticated") {
      clearUserAndReloadPage()
    }
  }
};

export const createUser = (values) => {
  return async (dispatch, getState) => {
    const { data } = await request.post("users", {
      ...values,
      role: "merchant"
    });

    if (!data.errors) {
      const user = {
        id: data.id,
        jwt: data.jwt,
        ...data.attributes
      }

      dispatch(setUser(user));
    }

    return data;
  }
};

export const activateUser = (user, code) => {
  return async (dispatch, getState) => {
    const { data } = await request.post("users/activate", { code });

    if (!data.errors) dispatch(
      setUser({
        ...user,
        status: "activated"
      })
    )

    return data;
  }
};

export const updateUserPassword = body => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`users/password_update`, body)

    if (!data.errors) dispatch({
      type: actionTypes.user.PASSWORD_UPDATE,
      payload: {}
    })

    return data;
  }
}

export const updateUserPhone = (user, phone) => {
  return async (dispatch, getState) => {
    const { data } = await request.put(`users/${user.id}`, {phone})

    if (!data.errors) {
      dispatch(
        setUser({
          ...user,
          phone
        })
      )
    }

    return data;
  }
}

