import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import FullScreenDialog from '../../components/FullScreenDialog';
import withRouter from '../../utils/withRouter';

function BillingNew(props) {
  const { history, location } = props;

  const onClose = () => history.replace("../");

  const home = location.pathname == "/app/billing/new" ||
    location.pathname == "/app/billing/new/";

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {
              home ? <Box /> :
                <IconButton color="primary" onClick={() => {
                  history.replace("./")
                }} sx={{position: "relative", left: -15}}>
                  <ArrowBackIcon />
                </IconButton>
            }

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Outlet />
      </DialogContent>
      <DialogActions>

      </DialogActions>
    </FullScreenDialog>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
})(withRouter(BillingNew));
