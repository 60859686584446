import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import Chip from '@mui/material/Chip';

import AutocompleteAsync from "./AutocompleteAsync";
import TextField from "./TextField";

import request from '../utils/request';

export default function AutocompleteSportsEvents(props) {
  const {
    label,
    defaultValue,
    onChange,
    multiple,
    leagues,
    regions,
    teams
  } = props;

  const toLabel = event => {
    return `${event.name} (${moment(event.start_at).format("MM/DD/YY")})`
  };

  return (
    <AutocompleteAsync
      multiple={multiple}
      label={label || "Add sports event"}
      fetchResponse={async inputValue => {
        const { data } = await request.get("sports_events", {
          params: {
            search: inputValue,
            leagues: leagues || [],
            regions: regions || [],
            teams: teams || []
          }
        });

        return _.map(data, d=> {
          return {
            id: d.id,
            ...d.attributes
          }
        })
      }}
      getOptionLabel={(option) => toLabel(option)}
      defaultValue={defaultValue}
      onChange={onChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined"
            label={toLabel(option)}
            {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{fontSize: 12}}
          name="sports_events"
        />
      )} />
  )
}
