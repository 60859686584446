import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import CsvUploader from '../../../components/CsvUploader';
import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';

export default function AdminMerchantsBulkLocation(props) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const onClose = () => navigate("../", {replace: true});

  const createBulk = async merchant_locations => {
    try {
      const { data } = await request.post("merchant_locations/bulk", { merchant_locations })

      if (data.errors) {

      } else {
        window.location.href = "/admin/merchants"
      }
    } catch(err) {
      alert(err.message)
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Bulk Upload Merchant Locations
        </Typography>

      </DialogTitle>

      <DialogContent>
        <Box mt={1}>
          {
            loading ?
              <Loader /> :
              <CsvUploader onFileLoaded={createBulk} />
          }
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
      </DialogActions>

    </Dialog>
  );
}
