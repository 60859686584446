import _ from 'lodash';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';

export default function AdminUsersMain(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const pages = [
    "merchants",
    "shoppers",
  ];

  const getPage = path => {
    switch(true) {
      case /shoppers/.test(path):
        return "shoppers"
      case /merchants/.test(path):
        return "merchants"
      default:
        return "merchants"
    }
  }

  const page = getPage(location.pathname);

  return (
    <Box>
      <Outlet />
    </Box>
  )
}
