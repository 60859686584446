import _ from "lodash";
import * as React from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import TextField from '../../components/TextField';
import Select from '../../components/Select';
import Link from '../../components/Link';

import Footer from './Footer';

const validationSchema = yup.object({
  goal: yup
    .string()
    .required(),
  cost: yup
    .number()
    .required(),
  budget: yup
    .number()
});

function NewPromotionGoal(props) {
  const { user, newPromotion, updateNewPromotion, history } = props;

  const formik = useFormik({
    initialValues: {
      goal: _.get(newPromotion, ["goal"], ""),
      cost: _.get(newPromotion, ["cost", "amount"], ""),
      budget: _.get(newPromotion, ["budget", "amount"], ""),
      max_entries_per_merchant_shopper: _.get(newPromotion, ["max_entries_per_merchant_shopper"], ""),
    },
    validationSchema,
    onSubmit: async values => {
      updateNewPromotion({
        ...values,
        goal: values.goal == "custom" ?
          (values.goal || "custom") :
          values.goal,
        budget: {
          amount: values.budget,
          currency: "usd"
        },
        cost: {
          type: values.goal == "order" ? "variable" : "fixed",
          amount: values.cost
        }
      });

      history.push("./schedule")
    },
  });

  return (
    <Box mb={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 0,
          md: 0
        }}>
          <Grid item xs={12} md={12}>
            <Box mb={{
              xs: 4,
              md: 4
            }}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                Let's get started
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Set the goal, cost, and budget for this promotion.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Select
                  name="goal"
                  label={"Goal for this promotion"}
                  value={formik.values.goal}
                  onChange={formik.handleChange}
                  error={formik.touched.goal && Boolean(formik.errors.goal)}
                  options={[
                    {
                      label: "Signup",
                      value: "signup",
                    },
                    {
                      label: "Purchase",
                      value: "order",
                    },{
                      label: "Referral",
                      value: "referral",
                    }, {
                      value: "custom",
                      label: "Custom"
                    }]
                  } />


                {
                  formik.values.goal == "custom" &&
                    <TextField
                      fullWidth
                      name="goal_custom"
                      label="What is the goal?"
                      value={formik.values.goal_custom}
                      onChange={formik.handleChange}
                      error={formik.touched.goal_custom && Boolean(formik.errors.goal_custom)}
                    />

                }

              </Stack>

              <Stack direction="row" spacing={1} alignItems="center">
                {
                  formik.values.goal == "order" ?
                    <TextField
                      fullWidth
                      type="number"
                      name="cost"
                      label="Cost per goal"
                      value={formik.values.cost}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                      error={formik.touched.cost && Boolean(formik.errors.cost)} /> :

                      <TextField
                        fullWidth
                        type="number"
                        name="cost"
                        label="Cost per goal"
                        value={formik.values.cost}
                        onChange={formik.handleChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        error={formik.touched.cost && Boolean(formik.errors.cost)} />

                }
              </Stack>

              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  fullWidth
                  type="number"
                  name="budget"
                  label="Maximum budget"
                  value={formik.values.budget}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  error={formik.touched.buget && Boolean(formik.errors.buget)}
                />

                <TextField
                  fullWidth
                  type="number"
                  name="max_entries_per_merchant_shopper"
                  label="Maximum entries per shopper"
                  value={formik.values.max_entries_per_merchant_shopper}
                  onChange={formik.handleChange}
                  error={formik.touched.max_entries_per_merchant_shopper && Boolean(formik.errors.max_entries_per_merchant_shopper)}
                />
              </Stack>


            </Stack>
          </Grid>
        </Grid>

        <Footer
          goBack={history.goBack}
          loading={formik.isSubmitting}
          progress={0} />
      </form>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionGoal));
