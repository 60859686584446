import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function Faq({question, answer}) {
  return (
    <Accordion variant="outlined">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body3" fontWeight={500}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body3" color="text.secondary" sx={{
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
        }}>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}