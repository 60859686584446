import _ from 'lodash';
import amplitude from 'amplitude-js';

let initialized = false;
const api_key = "cb14c61ed2fe17bc57cb3fef1e999159";

const initialize = () => {
  if (initialized) return;

  initialized = true;
  const user = window.localStorage.getItem("user");
  const userId = (user ? JSON.parse(user) : {}).id;
  amplitude.getInstance().init(api_key, userId);
}

export const logEvent = (event, data={}) => {
  initialize();
  amplitude.getInstance().logEvent(event, data);
}

export const setUserProperties = properties => {
  const user = window.localStorage.getItem("user");
  const userId = (user ? JSON.parse(user) : {}).id;

  amplitude.getInstance().init(api_key, userId).setUserProperties(properties);
}


