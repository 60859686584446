import _ from 'lodash';
import moment from "moment";
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditIcon from '@mui/icons-material/Edit';

import Loader from '../../../components/Loader';
import Link from '../../../components/Link';
import ImageUpload from '../../../components/ImageUpload';
import * as request from '../../../utils/adminRequest';

import Categories from './Categories';
import Locations from './Locations';

export default function AdminMerchantsDetails(props) {
  const [loading, setLoading] = React.useState(true);
  const [merchant, setMerchant] = React.useState({})
  const [activeTab, setActiveTab] = React.useState(0);

  const tabs = ["categories", "locations",  "design"];

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchData()
  }, []);

  React.useEffect(() => {
    setActiveTab(
      _.includes(location.pathname, "design") ? 1 : 0
    )
  }, [location.pathname]);

  const fetchData = async id => {
    const { data } = await request.get(`merchants/${params.merchantId}`)

    setMerchant({
      id: data.id,
      ...data.attributes
    })

    setLoading(false)
  }

  const updateMerchant = async values => {
    const { data } = await request.put(`merchants/${params.merchantId}`, values)

    setMerchant({
      ...merchant,
      ...data.attributes
    })
  }

  if (loading) return <Loader />;

 const breadcrumbs = [
    <Link
      variant="body3"
      color="secondary"
      fontWeight={600}
      component={RouterLink}
      to="../">
      Merchants
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      {merchant.name}
    </Typography>,
  ];

  return (
    <Box p={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs}
      </Breadcrumbs>

      <Box mt={3} mb={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={3} alignItems="center">
            <Box width={150}>
              <ImageUpload
                photo={
                  merchant.logo
                }
                cropping={false}
                height={150}
                addPhoto={logo => updateMerchant({logo})}
                label={"Company Logo"} />
            </Box>

            <Box>
              <Typography variant="body2">
                {
                  merchant.url
                }
              </Typography>

              <Typography variant="body3">
                Created {
                  moment(merchant.created_at).format("MMMM Do")
                }
              </Typography>

              {
                !!merchant.plaid_merchant_entity_id &&
                  <Box mt={2}>
                    <Typography variant="body4" fontWeight={600}>
                      Plaid Merchant Entity ID
                    </Typography>

                    <Typography variant="body4">
                      {
                        merchant.plaid_merchant_entity_id
                      }
                    </Typography>
                  </Box>
              }
            </Box>
          </Stack>

          {
            merchant.status == "pending" ?
              <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to="./start">
                  Approve
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to={`./delete`}>
                  Reject
                </Button>
              </Stack> :
              <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <Button
                  size="small"
                  variant="contained"
                  component={RouterLink}
                  to={`./edit`}>
                  Edit
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to={`./delete`}>
                  Delete
                </Button>
              </Stack>
          }
        </Stack>
      </Box>

      <Box mt={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs indicatorColor="secondary" textColor="secondary" value={activeTab}
          onChange={(e, tab) => setActiveTab(tab)
        }>
          {
            _.map(tabs, t => {
              return (
                <Tab key={t} label={_.toUpper(t)} sx={{
                  textTransform: "inherit",
                  fontSize: 14
                }}  />
              )
            })
          }

        </Tabs>
      </Box>

      <Box mt={2}>
        {
          activeTab == 0 &&
            <Categories merchant={merchant} setMerchant={setMerchant} />
        }

        {
          activeTab == 1 &&
            <Locations merchant={merchant} setMerchant={setMerchant} />
        }


      </Box>

    </Box>
  )
}
