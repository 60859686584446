import _ from "lodash";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as React from "react";
import { Link as RouterLink, Outlet, useParams, useNavigate, useOutletContext } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import FullScreenDrawer from '../../../components/FullScreenDrawer';
import withRouter from '../../../utils/withRouter';
import request from '../../../utils/request';
import * as promotionsActions from '../../../actions/promotions';
import * as promotionEntryActions from '../../../actions/promotionEntries';

export default function PromotionPropositionDetails(props) {
  const params = useParams();
  const navigate = useNavigate();

  const { promotion } = useOutletContext();

  const [proposition, setProposition] = React.useState({});
  const [loading, setLoading] = React.useState({});

  const propositionId = params.propositionId;

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [propositionId])

  const fetchData = async () => {
    const { data } = await request.get(`propositions/${propositionId}`)
    if (data.errors) {

    } else {
      setProposition({
        id: data.id,
        ...data.attributes
      })
    }
  }

  if (_.isEmpty(proposition)) return <Box />;

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box minWidth={600} py={3} px={2}>

        {
          loading ?
            <Loader /> :
            <Box>
              <Box mb={3}>
                <Box mb={3}>
                  <Stack direction="row" spacing={3} justifyContent="space-between" alignItems='center'>
                    <Box>
                      <Typography variant="body4" fontWeight={600} textTransform="uppercase" color="text.secondary">
                        Proposition
                      </Typography>

                      <Typography variant="body3" fontWeight={500}>
                        {proposition.title}
                      </Typography>
                    </Box>

                    <CopyToClipboard
                      text={proposition.id}
                      onCopy={() => {
                      }}>
                      <Chip icon={<ContentCopyIcon sx={{fontSize: 14}} />} label={
                        <Typography variant="body4" fontWeight={600}>
                          COPY ID
                        </Typography>
                        } />
                    </CopyToClipboard>

                  </Stack>
                </Box>

                <Stack direction="row" spacing={10}>
                  <Box>
                    <Typography variant="body4" fontWeight={600}>
                      PREMIUM
                    </Typography>

                    <Typography variant="body3" color="text.secondary">
                      {
                        promotion.cost.value
                      }%
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body4" fontWeight={600}>
                      STARTS
                    </Typography>

                    <Typography variant="body3" color="text.secondary">
                      {
                        proposition.start_date || "TBD"
                      }
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body4" fontWeight={600}>
                      ENDS
                    </Typography>

                    <Typography variant="body3" color="text.secondary">
                      {
                        proposition.end_date || "TBD"
                      }
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Divider />

              <Box mt={2} mb={3}>
                <Typography variant="body3" color="text.secondary">
                  {
                    proposition.description
                  }
                </Typography>
              </Box>

              <Divider />

              <Box mt={3}>
                <Stack spacing={3}>

                  {

                    proposition.regions.length > 0 &&
                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                          Regions
                        </Typography>

                        <Box>
                          <Stack direction="column" spacing={2}>
                            {
                              _.map(proposition.regions, r => {
                                return <Typography key={r.id} variant="body3" color="text.secondary">
                                  {r.name}
                                </Typography>
                              })
                            }
                          </Stack>
                        </Box>
                      </Box>
                  }

                  {
                    !!proposition.league &&
                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                          League
                        </Typography>

                        <Box>
                          <Typography variant="body3" color="text.secondary">
                            {proposition.league.name}
                          </Typography>
                        </Box>
                      </Box>
                  }

                  {
                    !!proposition.sports_event &&
                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                          Event
                        </Typography>

                        <Box>
                          {
                            !!proposition.sports_event &&
                              <Typography variant="body3" color="text.secondary">
                                {proposition.sports_event.name}
                              </Typography>
                          }
                        </Box>
                      </Box>
                  }

                  {

                    proposition.teams.length > 0 &&
                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                          Teams
                        </Typography>

                        <Box>
                          <Stack direction="column" spacing={2}>
                            {
                              _.map(proposition.teams, team => {
                                return (
                                  <Typography key={team.id} variant="body3" color="text.secondary">
                                    {team.name}
                                  </Typography>
                                )
                              })
                            }
                          </Stack>
                        </Box>
                      </Box>
                  }

                  {
                    <Box>
                      <Typography variant="body3" fontWeight={600}>
                        Players
                      </Typography>

                      <Box>
                        {
                          !!proposition.player &&
                            <Typography variant="body3" color="text.secondary">
                              {proposition.player.name}
                            </Typography>
                        }
                      </Box>
                    </Box>
                  }

                </Stack>
              </Box>
            </Box>

        }
      </Box>
    </FullScreenDrawer>
  );
}
