import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../components/DialogCloseButton';
import * as promotionActions from '../../actions/promotions';
import withRouter from '../../utils/withRouter';
import request from '../../utils/request';

function PromotionEnd(props) {
  const { history, params } = props;

  const { promotion, updatePromotion } = useOutletContext();

  const [saving, setSaving] = React.useState(false);
  const onClose = () => history.replace("../");

  const save = async () => {
    try {
      setSaving(true);
      const { data } = await request.post(`promotions/${promotion.id}/end`)
      if (data.errors) {
        alert(data.errors.message)
      } else {
        window.location.href = "../"
      }
    } catch(err) {

    } finally {
      setSaving(false)
    }
  }

  const launchable = promotion.propositions.length > 0;

  return (
    <Box>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={onClose} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            You are about to end this promotion
          </Typography>

          <Typography variant="body2" color="text.secondary">
            This promotion will be no longer eligible for shoppers.
          </Typography>
        </DialogTitle>

        <DialogContent>
        </DialogContent>

        <DialogActions sx={{p: 3}}>
          <LoadingButton
            onClick={save}
            loading={saving}
            variant="contained"
            color="error">
            Confirm
          </LoadingButton>

          <Button onClick={onClose}>
            Nevermind
          </Button>
        </DialogActions>

      </Dialog>

    </Box>
  );
}

const select = $$state => _.pick($$state, "promotions");
export default connect(select, {
  ...promotionActions
})(withRouter(PromotionEnd));
