import _ from 'lodash';
import * as React from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import ImageUpload from '../../../components/ImageUpload';
import AutocompleteMerchantCategories from '../../../components/AutocompleteMerchantCategories';
import * as request from '../../../utils/adminRequest';

export default function MerchantCategories({merchant, setMerchant}) {
  const {merchant_categories} = merchant;

  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open)

  const formik = useFormik({
    initialValues: {
      merchant_category_id: merchant_categories
    },
    onSubmit: async values => {
      try {
        const { data } = await request.put(`merchants/${merchant.id}`, values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          window.location.href = "../"
        }

      } catch(err) {
        alert("Unknown error")
      }
    }
  });

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        {
          _.map(merchant_categories, mc => {
            return <Chip key={mc.id} label={
              <Typography variant="body3" fontWeight={600} textTransform="upperCase">
                {mc.full_name}
              </Typography>
            } />
          })
        }

        <IconButton color="primary" onClick={toggleOpen}>
          <EditIcon />
        </IconButton>
      </Stack>

      <Dialog
        open={open}
        onClose={toggleOpen}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box />
            <DialogCloseButton onClose={toggleOpen} />
          </Stack>

          <Typography variant="body2" fontWeight={500}>
            Edit Categories
          </Typography>

        </DialogTitle>

        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="merchant-new-form">
            <Box mt={1} mb={3}>
              <AutocompleteMerchantCategories
                multiple={true}
                placeholder={
                  _.get(formik.values.merchant_category_id, ["merchant_category_id"], "Categories")
                }
                error={formik.touched.merchant_category_id && Boolean(formik.errors.merchant_category_id)}
                styles={{
                  placeholder: {
                    fontSize: "16px"
                  }
                }}
                onChange={(e, merchant_categories)  => {
                  formik.setFieldValue(
                    "merchant_category_id",
                    merchant_categories
                  )
                }} />
              </Box>

              <LoadingButton
                fullWidth
                loading={formik.isSubmitting}
                type="submit"
                variant="contained"
                color="secondary">
                Save
              </LoadingButton>

            </form>

        </DialogContent>
      </Dialog>
    </Box>
  )
}