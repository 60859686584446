import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import ClickablePaper from '../../../components/ClickablePaper';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function AdminInvoicesList(props) {
  const [loading, setLoading] = React.useState(false);
  const [invoices, setInvoices] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    logicOperator: 'and',
    quickFilterValues: [],
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchTransactions(
      _.reduce(filterModel.items, (acc, i) => {
        acc[i.field] = i.value;
        return acc;
      }, {})
    ).finally(() => setLoading(false))
  }, [filterModel]);

  const fetchTransactions = async query => {
    const { data } = await request.get(`invoices`, {
      params: {
        ...query
      }
    })

    setInvoices(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }


  const columns = [
    { field: 'invoice_account', headerName: 'Account', width: 120,
      valueGetter: (value) => {
        return _.capitalize(_.get(value, ["name"], ""))
      }
    },
    { field: 'description', headerName: 'Description', width: 220},

    { field: 'amount', headerName: 'Amount', width: 120,
      valueGetter: (value) => {
        return currencyFormatter(value, 2)
      }
    },
    { field: 'net', headerName: 'Net Terms', width: 120},
    { field: 'start_date', headerName: 'Start', width: 200},
    { field: 'end_date', headerName: 'End', width: 200},
    { field: 'completed_at', headerName: 'Paid', width: 120},
  ]

  const rows = invoices;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) return <Loader />;

  return (
    <Container maxWidth="xl">
      <Box sx={{ height: 550, mt: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={hasMore ? rows.length + 1 : rows.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

        {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  )
}
