import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import withRouter from '../../utils/withRouter';
import Link from '../../components/Link';

import {Title, Section, HeroImage} from './Components';

export default function MarketingAbout(props) {
  return (
    <Box mb={{
      xs: 4,
      sm: 8
    }} mt={{
      xs: 1,
      sm: 2
    }}>
      <Container maxWidth="lg">
        <Container maxWidth="sm" sx={{
          background: "#111029",
          borderRadius: "16px",
          color: "#fff",
          padding: {
            xs: 5,
            sm: "80px"
          }
        }}>

          <Title textAlign="center">
            Not our first rodeo
          </Title>

          <Typography variant="body2" textAlign="center" mt={1} lineHeight={1.3}>
            We've previously built financial technology at some of the world’s
            leading startups. We're experts at building financial products for
            businesses and end consumers.
          </Typography>
        </Container>

        <Box mt={{
          xs: 6,
          md: 6
        }}>
            <Stack direction="row" justifyContent="center">
              <img
                style={{
                  height: "90px"
                }}
                src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg" />

            </Stack>
        </Box>

        <Box mt={3}>
          <Stack alignItems="center">
            <ArrowDownwardIcon />
          </Stack>
        </Box>

        <Box mt={{
          xs: 3,
          md: 3
        }}>
          <Stack alignItems="center">
            <Typography variant="body2" textAlign="center" textTransform="uppercase" fontWeight={700} sx={{
              borderBottom: "4px solid #0e9185",
              pb: 1,
              display: "inline-block"
            }}>
              Our Team
            </Typography>
          </Stack>

          <Box mt={4}>
            <Container maxWidth="lg" sx={{p: 0}}>
              <Grid container spacing={{xs: 2, sm: 4}}>
                <Grid item sm={6}>
                  <Paper sx={{p: 3, background: "#f8f8f8"}} variant="outlined">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar sx={{
                        width: 80,
                        height: 80,
                      }}
                        src={"https://res.cloudinary.com/dirtrv8w6/image/upload/v1684777763/user_uploads/attractive_t82opo.jpg"} />

                      <Box>
                        <Typography variant="body2" fontWeight={600}>
                          Ben Plesser
                        </Typography>

                        <Typography variant="body3">
                          Founder and CEO
                        </Typography>

                      </Box>
                    </Stack>

                    <Box mt={2}>
                      <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        Ben founded and grew his previous payments startup, <Link color="secondary" target="_blank" href="https://www.tremendous.com">Tremendous.com</Link>,
                        to $30M+ in annual revenue. Ben was previously a first 10 employee at two unicorn startups
                        (<Link color="secondary" target="_blank" href="https://plaid.com">Plaid</Link> and <Link color="secondary" target="_blank" href="https://yipitdata.com">YipitData</Link>).
                      </Typography>

                    </Box>

                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Paper sx={{p: 3, background: "#f8f8f8"}} variant="outlined">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar sx={{
                        width: 80,
                        height: 80,
                      }}
                        src={"https://media.licdn.com/dms/image/C5603AQFE0zN2WZE1Dw/profile-displayphoto-shrink_400_400/0/1525902487802?e=1718236800&v=beta&t=S20O2SlaME851tCanNUdQs9IlCw9gQag7RJ7Du3KFrQ"} />

                      <Box>
                        <Typography variant="body2" fontWeight={600}>
                          Ben Perez
                        </Typography>

                        <Typography variant="body3">
                          Founder and CTO
                        </Typography>

                      </Box>
                    </Stack>

                    <Box mt={2}>
                      <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        Prior to TailRisk, Ben was the fifth engineer at Plaid and subsequently founded and sold
                        his own AI / ML infrastructure company for autonomous vehicles.
                      </Typography>
                    </Box>

                  </Paper>
                </Grid>
              </Grid>


              <Box mt={{xs: 3, sm: 5}}>
                  <Grid container spacing={{
                    xs: 2,
                    md: 4
                  }} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Box sx={{
                        background: "rgba(164, 112, 255, 0.1)",
                        border: "1px solid rgba(229,231,235)",
                        borderRadius: "30px",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: {
                          xs: 200,
                          sm: 300
                        }
                      }}>
                        <Typography color="secondary" variant="h1" fontWeight={600}>
                          2
                        </Typography>

                        <Typography variant="body1" fontWeight={500} mt={1} lineHeight={1.5}>
                          Unicorn startups where we were among the first 10 employees.
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box sx={{
                        background: "rgba(164, 112, 255, 0.1)",
                        border: "1px solid rgba(229,231,235)",
                        borderRadius: "30px",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: {
                          xs: 200,
                          sm: 300
                        }
                      }}>
                        <Typography color="secondary" variant="h1" fontWeight={600}>
                          2
                        </Typography>

                        <Typography variant="body1" fontWeight={500} mt={1} lineHeight={1.5}>
                          VC backed startups founded previously in our careers.
                        </Typography>
                      </Box>
                    </Grid>


                    <Grid item xs={12} sm={4}>
                      <Box sx={{
                        background: "rgba(164, 112, 255, 0.1)",
                        border: "1px solid rgba(229,231,235)",
                        borderRadius: "30px",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: {
                          xs: 200,
                          sm: 300
                        }
                      }}>
                        <Typography color="secondary" variant="h1" fontWeight={600}>
                          $500M+
                        </Typography>

                        <Typography variant="body1" fontWeight={500} mt={1} lineHeight={1.5}>
                          Dollars in payments that our startups have processed globally
                        </Typography>
                      </Box>
                    </Grid>

                  </Grid>
              </Box>


            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
