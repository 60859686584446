import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import Loader from '../../../components/Loader';
import CsvUploader from '../../../components/CsvUploader';
import * as request from '../../../utils/adminRequest';

export default function AdminNewMusician(props) {
  const navigate = useNavigate();

  const [saving, setSaving] = React.useState(false);

  const onClose = () => navigate("../", {replace: true});

  const save = async musicians => {
    try {
      setSaving(true);

      const { data } = await request.post(`musicians`, {musicians})
      if (data.errors) {
        alert(data.errors.message)
      } else {
        window.location.href = "/admin/musicians"
      }

    } catch(err) {
      alert("Unknown error")
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Typography variant="body2" fontWeight={500}>
          Upload new songs
        </Typography>

      </DialogTitle>

      <DialogContent>
          {
            saving ?
              <Loader /> :
              <CsvUploader onFileLoaded={save} />
          }
      </DialogContent>

      <DialogActions sx={{p: 3}}>

      </DialogActions>

    </Dialog>
  );
}
