import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';

import withRouter from '../../../utils/withRouter';
import request from '../../../utils/request';

export default function PromotionsDesignDetails() {
  const { promotion, updatePromotion } = useOutletContext();

  return (
    <Box>
      <Paper variant="outlined" sx={{p: 3}}>
        <Box mb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <SmsIcon />

              <Box>
                <Typography variant="body2" fontWeight={600}>
                  SMS Promotion Entrants
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Enable this feature and we will message your shoppers
                  automatically about new promotions.
                </Typography>
              </Box>
            </Stack>

            <Button color="secondary" variant="outlined" size="small" component={RouterLink} to="./sms">
              Setup
            </Button>

          </Stack>
        </Box>

        <Divider />

        <Box mt={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <EmailIcon />

              <Box>
                <Typography variant="body2" fontWeight={600}>
                  Email Promotion Entrants
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Enable this feature and we will message your shoppers
                  automatically about new promotions.
                </Typography>
              </Box>
            </Stack>

            <Button disabled color="secondary" variant="outlined" size="small" component={RouterLink} to="./email">
              Setup
            </Button>
          </Stack>
        </Box>
      </Paper>

      <Outlet context={{
        promotion,
        updatePromotion
      }} />
    </Box>
  );
}
