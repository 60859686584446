import _ from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogCloseButton from '../../../components/DialogCloseButton';
import DialogResponsive from '../../../components/DialogResponsive';
import TextField from '../../../components/TextField';
import * as request from '../../../utils/adminRequest';

const validationSchema = yup.object({
  question: yup
    .string()
    .required(),
  answer: yup
    .string()
    .required(),
});

export default function PromotionsDesignsFaqEditor(props) {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: ""
    },
    onSubmit: async values => {
      formik.resetForm()
    }
  });

  const onClose = () => navigate("../", {replace: true})

  return (
    <DialogResponsive
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={500}>
            Promotion Emails
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Configure your promotion settings below
          </Typography>
        </Box>

      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="faq-form">
          <Stack spacing={2}>

          </Stack>
        </form>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={formik.isSubmitting}
          variant="contained"
          type="submit"
          form="faq-form">
          Save
        </LoadingButton>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogResponsive>
  )
}
