import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.merchantUsers.CREATE:
      return _.uniqBy([
        ...$$state,
        ...payload.merchantUsers,
      ], m => m.id);
    case actionTypes.merchantUsers.INDEX:
      return payload.merchantUsers;
    default:
      return $$state;
  }
};
