const url = window.location.href;

const isDevelopment = url.includes('localhost') || url.includes('127.0.0.1');

export const production = !isDevelopment;

export const debugModeEnabled = () => isDevelopment;

export const httpsHost = isDevelopment ?
  "http://localhost:3000" :
  "https://tailrisk.ai"


export const APIHost = isDevelopment ?
  "http://localhost:5000" :
  "https://tailriskai-backend-5d7693bf1193.herokuapp.com"
