import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function GoogleSignup(props) {
  return (
    <Box sx={{margin: "0 auto"}}>
      <GoogleOAuthProvider
        clientId={
          "431787081004-8l3rtnt4vq7r82vtnl2fkn8u78mvvp40.apps.googleusercontent.com"
        }>
        <GoogleLogin
          useOneTap
          onSuccess={props.onSuccess}
          onError={props.onError}
          render={renderProps => (
            <Button
              size="large"
              fullWidth
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              startIcon={
                <img
                  height="15px"
                  width="15px"
                  src="https://app.tremendous.com/static/media/Google.7ca52dfb.svg" />
            } variant="outlined" {...props}>
              {
                props.title || "Log in with Google"
              }
            </Button>
          )}
        />
      </GoogleOAuthProvider>
    </Box>
  )
}