import _ from "lodash";
import * as React from "react";
import Script from 'react-load-script';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

export default function AccountAvatar(props) {
  const { user, updateAvatar } = props;

  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  const openUpload = () => {
    if (!scriptLoaded) return;

    window.cloudinary.openUploadWidget({
      cloudName: "dirtrv8w6",
      uploadPreset: "giyjsoyh",
      sources: [ 'local', 'instagram', 'facebook', 'dropbox', 'google_drive'],
      croppingAspectRatio: _.get(props, "croppingAspectRatio", 1),
      cropping: true,
      showSkipCropButton: false
     }, (error, result) => {
      if (!error && result && result.event === "success") {
        updateAvatar(result.info.secure_url)
      }
    });
  }

  return (
    <Box>
      <Script
        url="https://upload-widget.cloudinary.com/global/all.js"
        onError={() => setScriptLoaded(false)}
        onLoad={() => setScriptLoaded(true)}
      />

      <IconButton onClick={openUpload}>
        <Avatar
          sx={{height: 70, width: 70}}
          src={user.avatar_url} />
      </IconButton>
    </Box>
  );
};
