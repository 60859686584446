import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink, Outlet, useParams, useNavigate, useOutletContext } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import ClickablePaper from '../../../components/ClickablePaper';
import FullScreenDrawer from '../../../components/FullScreenDrawer';
import request from '../../../utils/request';
import currencyFormatter from '../../../utils/currencyFormatter';

export default function PromotionMerchantShopperDetails(props) {
  const [saving, setSaving] = React.useState(true);

  const params = useParams();
  const navigate = useNavigate();

  const { promotion, regions } = useOutletContext();
  const { promotionMerchantShopperId } = params;

  const promotionMerchantShopper = _.find(promotion.promotion_merchant_shoppers, p => p.id == promotionMerchantShopperId);
  const merchantShopper = _.get(promotionMerchantShopper, "merchant_shopper", {});
  const proposition = _.get(promotionMerchantShopper, ["proposition"], {});

  const attrs = [
    {
      key: "email"
    },
    {
      key: "phone"
    },
    {
      key: "gender"
    },
    {
      label: "Region",
      key: "region_id",
      valueGetter: v => _.chain(regions).find(r => r.id == v).get("name").value()
    },
    {
      label: "Account Created",
      key: "account_created_at",
      valueGetter: v => !!v ? moment(v).format("MM/DD/YY") : 0
    },
    {
      label: "Last Ordered",
      key: "last_ordered_at",
      valueGetter: v => !!v ? moment(v).format("MM/DD/YY") : 0
    },
    {
      key: "gmv",
      valueGetter: v => !!v ? currencyFormatter(v, 2) : 0
    },
    {
      label: "Orders Count",
      key: "orders_count",
    },
  ];


  const destroy = async () => {
    // try {
    //   setSaving(true);

    //   const data = await deleteMerchantShopper(merchantId, merchantShopperId)
    //   if (data.errors) {

    //   } else {
    //     navigate("../")
    //   }
    // } catch(err) {

    // } finally {
    //   setSaving(true)
    // }
  }

  if (promotionMerchantShopper == null ) return <Box />;

  return (
    <FullScreenDrawer
      sx={{zIndex: 1300}}
      open={true}
      anchor="right"
      onClose={() => navigate("../", {replace: true})}>
      <Box minWidth={450} py={3} px={2}>
        <Box>
          <Box mb={2}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography variant="body2" fontWeight={500}>
                  {merchantShopper.name}
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Created {moment(promotionMerchantShopper.created_at).format("MM/DD/YY")}
                </Typography>
              </Box>
            </Stack>
          </Box>

          <ClickablePaper sx={{px: 2, py: 1}} selected>
            <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center">
              <Typography color="secondary" variant="body3" fontWeight={500} onClick={() => {
                 navigate(`/app/promotions/${promotion.id}/propositions/${proposition.id}`)
              }}>
                {
                  _.truncate(
                    proposition.title, {
                      'length': 25,
                      'omission': '...'
                    }
                  )

                }
              </Typography>

              <Chip size="small" label={
                <Typography variant="body4" fontWeight={600} textTransform={"uppercase"}>
                  {proposition.status}
                </Typography>
              } />
            </Stack>
          </ClickablePaper>

          {
            _.map(attrs, attr => {
              return (
                <Box key={attr} sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  py: 2
                }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body4" fontWeight={500}>
                      { _.toUpper(attr.label || attr.key) }
                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                      {
                        !!attr.valueGetter ?
                          attr.valueGetter(merchantShopper[attr.key]) :
                          merchantShopper[attr.key]
                      }
                    </Typography>

                  </Stack>
                </Box>
              )
            })
          }
        </Box>

      </Box>
    </FullScreenDrawer>
  );
}
