import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

import Loader from '../../../components/Loader';
import ClickablePaper from '../../../components/ClickablePaper';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';

export default function AdminOffersList(props) {
  const [offers, setOffers] = React.useState([])
  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    const { data } = await request.get(`offers`, {
      params: {
      }
    })

    setOffers(
      _.map(data, u => {
        return {
          id: u.id,
          ...u.attributes
        }
      })
    )
  }

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueGetter: _.toUpper
    },
    {
      field: 'proposition',
      headerName: 'Proposition',
      width: 280,
      valueGetter: (value) => {
        return value.title
      }
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 150,
      valueGetter: (value) => {
        return moment(value).format("MM/DD/YY")
      }
    },
  ]

  const rows = offers;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) return <Loader />;

  return (
    <Container maxWidth="xl" sx={{pt: 2}}>
      <Box style={{ height: 550, width: '100%', overflowX: 'auto'  }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowCount={hasMore ? rows.length + 1 : rows.length}
          onPageChange={handlePageChange}
          paginationMode="server"
          onRowSelectionModelChange={row => {
            navigate(`./${row}`)
          }}
          components={{
            Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
            HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
          }}
        />

        {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Outlet context={{
        offers,
        setOffers
      }} />
    </Container>
  )
}
