import _ from "lodash";
import * as React from "react";
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import Select from '../../components/Select';
import AutocompleteMerchants from '../../components/AutocompleteMerchants';
import Link from '../../components/Link';
import currencyFormatter from '../../utils/currencyFormatter';

import Footer from './Footer';

const validationSchema = yup.object({
  merchant_id: yup
    .string()
    .required()
});

function NewPromotionRequirements(props) {
  const { user, newPromotion, updateNewPromotion, history } = props;

  const formik = useFormik({
    initialValues: {
      type: _.get(newPromotion, ["requirement", "type"], "self"),
      merchant_id: _.get(
        newPromotion, ["requirement", "merchant_id"],
        user.merchant_user.merchant_id
      ),
    },
    validationSchema,
    onSubmit: async (values) => {
      updateNewPromotion({
        requirement: values
      });

      history.push("../terms")
    },
  });

  return (
    <Box mb={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 3,
          md: 3
        }}>
          <Grid item xs={12} md={12}>
            <Box mb={{
              xs: 2,
              sm: 2
            }}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                Set requirements for your customers
              </Typography>

              <Typography variant="body2" color="text.secondary">
                How should a customer qualify for this promotion?
                Is it based on an event (i.e. purchase) with you or another merchant?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={2} sx={{mt: 1}}>
              <Select
                fullWidth
                name="type"
                value={formik.values.type}
                onChange={e => {
                  const type = e.target.value;

                  formik.setFieldValue("type", type);

                  if (type == "self") {
                    formik.setFieldValue("merchant_id", user.merchant_user.merchant_id)
                  } else {
                    formik.setFieldValue("merchant_id", "")
                  }
                }}
                label="Which business will trigger this promotion?"
                helperText={formik.touched.type && formik.errors.type}
                error={formik.touched.type && Boolean(formik.errors.type)}
                options={[
                  {
                    label: "My business",
                    value: "self"
                  }, {
                    label: "Another merchant",
                    value: "other"
                  }
                ]} />

              {
                formik.values.type == "other" &&
                  <AutocompleteMerchants
                    fullWidth
                    name="merchant_id"
                    value={formik.values.merchant_id}
                    onChange={(e, value) => {
                      formik.setFieldValue("merchant_id", value.id)
                    }}
                    label="Search merchants"
                    helperText={formik.touched.merchant_id && formik.errors.merchant_id}
                    error={formik.touched.merchant_id && Boolean(formik.errors.merchant_id)} />
              }
            </Stack>
          </Grid>
        </Grid>

        <Footer
          disabled={!formik.values.merchant_id}
          goBack={history.goBack}
          loading={formik.isSubmitting}
          progress={90} />
      </form>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "newPromotion");
export default connect(select, {
  ...newPromotionActions
})(withRouter(NewPromotionRequirements));
