import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink, useLocation, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';

import withRouter from '../../utils/withRouter';
import * as merchantShopperActions from '../../actions/merchantShoppers';
import * as promotionActions from '../../actions/promotions';
import Loader from '../../components/Loader';
import request from '../../utils/request';

import Search from './Search';
import Actions from './Actions';
import MerchantShoppersTable from './Table';

function MerchantShoppersList(props) {
  const {
    regions,
    merchantShoppers,
    fetchMerchantShoppers,
    deleteMerchantShopper,
    promotions,
    fetchPromotions,
    user,
    history
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [selectedMerchantShoppers, setSelectedMerchantShoppers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsCount, setRowsCount] = React.useState(0);

  const merchantId = user.merchant_user.merchant_id;

  const fetchShoppers = async () => {
    await fetchMerchantShoppers(merchantId, {
      offset: page * 20
    })
  }

  const fetchShopperCount = async () => {
    const {data } = await request.get(`merchants/${merchantId}/merchant_shoppers/count`);
    setRowsCount(data.count)
  }

  React.useEffect(() => {
    fetchShoppers(page)
  }, [page]);

  React.useEffect(() => {
    if (promotions.length == 0) fetchPromotions();
    fetchShopperCount().finally(() => setLoading(false))
  }, []);

  return (
    <Box pb={10}>
      <Box mb={2}>
        <Stack direction="row" spacing={{xs: 2, md: 2}} alignItems={{
          xs: "initial",
          md: "center"
        }}>
          <Stack direction="row" spacing={1}>
            <Search merchantId={merchantId} onSelect={s => {
              history.push(`./${s.id}`)
            }} />

          </Stack>
        </Stack>
      </Box>

      <Paper variant="outlined" sx={{
        width: "100%",
        overflowX: "auto"
      }}>
        <MerchantShoppersTable
          {...props}
          page={page}
          checkboxSelection={false}
          onPageChange={page => {
            debugger;
            setPage(page);
          }}
          onRowClick={id => history.push(`./${id}`)}
          rowsCount={rowsCount}
          selectedMerchantShoppers={selectedMerchantShoppers}
          setSelectedMerchantShoppers={s => setSelectedMerchantShoppers(s)} />
      </Paper>


      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
          <Box flexGrow={{
            xs: 0,
            sm: 0
          }}>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Button variant="outlined" component={RouterLink} to="./upload" color="secondary" size="small">
                Add New Shoppers
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Outlet context={{
        merchantId,
        deleteMerchantShopper,
        regions
      }} />
    </Box>
  )
}

const select = $$state => _.pick($$state, "user", "regions",  "merchantShoppers", "promotions");
export default connect(select, {
  ...merchantShopperActions,
  ...promotionActions
})(withRouter(MerchantShoppersList));
