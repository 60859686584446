import _ from "lodash";
import * as React from "react";
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PublicIcon from '@mui/icons-material/Public';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import withRouter from '../../utils/withRouter';
import * as newPromotionActions from '../../actions/newPromotion';
import * as regionActions from '../../actions/regions';
import ClickablePaper from '../../components/ClickablePaper';
import Link from '../../components/Link';
import Loader from '../../components/Loader';

import Footer from './Footer';

function NewPromotionGeo(props) {
  const { user, newPromotion, updateNewPromotion, regions, history } = props;

  const usa = _.find(regions, r => r.type == "country");

  const formik = useFormik({
    initialValues: {
      regions: _.get(newPromotion, "regions", [])
    },
    onSubmit: async values => {
      history.push("../leagues")
    },
  });

  return (
    <Box mb={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{
          xs: 0,
          md: 0
        }}>
          <Grid item xs={12} md={12}>
            <Box mb={{
              xs: 2,
              sm: 4
            }}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                New Promotion
              </Typography>

              <Typography variant="h4" fontWeight={500}>
                Where should this promotion run?
              </Typography>

              <Typography variant="body2" color="text.secondary">
                You can run a single national promotion or present regionally specific options to
                make it more appealing to local fans.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={{
              xs: 1,
              sm: 1
            }}>
                <ClickablePaper selected={
                  _.filter(formik.values.regions, r => _.get(r, ["type"], "") == "country").length == 1
                } onClick={() => {
                  formik.setFieldValue("regions", [usa]);
                  updateNewPromotion({regions: [usa]})
                }} sx={{
                  pl: {
                    xs: 1,
                    sm: 2
                  }, pr: {
                    xs: 1,
                    sm: 2
                  }
                }}>
                  <Stack direction="row" spacing={{
                    xs: 2,
                    sm: 2
                  }}>
                    <Box>
                      <Typography variant={"body3"} fontWeight={500}>
                        Nationally
                      </Typography>
                      <Typography variant={"body3"} color="text.secondary">
                        Run offers across all regions
                      </Typography>
                    </Box>
                  </Stack>
                </ClickablePaper>

                <ClickablePaper selected={
                  _.filter(formik.values.regions, r => _.get(r, "type") == "city").length > 0
                } onClick={() => {
                  formik.setFieldValue("regions", []);
                  updateNewPromotion({regions: []})

                  history.push("./regions")
                }} sx={{
                  pl: {
                    xs: 1,
                    sm: 2
                  }, pr: {
                    xs: 1,
                    sm: 2
                  }
                }}>
                  <Stack direction="column" spacing={{
                    xs: 2,
                    sm: 2
                  }}>
                    <Box>
                      <Typography variant={"body3"} fontWeight={500}>
                        Regionally
                      </Typography>
                      <Typography variant={"body3"} color="text.secondary">
                        Run offers across specific cities
                      </Typography>
                    </Box>

                    {
                      _.filter(newPromotion.regions, r => r.type == "city").length > 0 &&
                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                          {
                            _.chain(newPromotion.regions)
                              .filter(r => r.type == "city")
                              .map(r => <Chip variant="contained" color="secondary" key={r.id} label={r.name} />)
                              .value()
                          }
                        </Stack>
                    }
                  </Stack>
                </ClickablePaper>
            </Stack>
          </Grid>
        </Grid>

        <Footer
          goBack={history.goBack}
          disabled={_.get(newPromotion, ["regions", "length"], 0) == 0}
          loading={formik.isSubmitting}
          progress={50} />
      </form>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "newPromotion", "regions");
export default connect(select, {
  ...newPromotionActions,
  ...regionActions
})(withRouter(NewPromotionGeo));
