import _ from 'lodash';
import qs from 'qs';
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';

import * as request from '../../../utils/adminRequest';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: "transparent",
  border: "none",
  boxShadow: "none",
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },

}));

const StyledInputBase = styled(Input)(({ theme }) => ({
  color: 'inherit',
    padding: 0,
    width: '100%',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      width: '40ch',
    },
    [theme.breakpoints.down('xs')]: {
      width: '30ch',
    },
}));

export default function AdminUsersSearch(props) {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  React.useEffect(() => {
    setInputValue(_.get(search, ["email"], ""))
  }, [location.search]);

  const fetch = React.useMemo(
    () =>
      _.throttle(async search => {
        const { data } = await request.get("users",  {
          params: { search }
        });

        const users = _.map(data, user => {
          return {
            id: user.id,
            email: user.email,
            phone: user.phone,
            ...user.attributes
          }
        });

        setOptions(users);

      }, 200),
    [],
  );

  return (
    <Search>
      <Autocomplete
        multiple={false}
        options={options}
        getOptionLabel={option => option.email}
        noOptionsText="No Results"
        ListboxProps={{
          sx: {
            p: 0,
          }
        }}
        onChange={(event, newValue) => {
          setOptions([]);
          setInputValue("");

          if (!!newValue) {
            navigate(`/admin/users/all?email=${newValue.email}`)
          } else {
            navigate(`/admin/users/all`)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);

          if (newInputValue.length >= 3) {
            fetch(newInputValue)
          } else {
            setOptions([])
          }
        }}
        renderOption={(props, option) => (
          <Box {...props} sx={{
            p: 2,
            pb: 0,
            borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
          }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                sx={{
                  width: 30,
                  height: 30
                }}
                src={option.avatar_url}
              />
              <Typography variant="body2">
                {option.email}
              </Typography>
            </Stack>
          </Box>
        )}
        renderInput={params => (
          <StyledInputBase
            {...(_.omit(params, ['InputLabelProps', "InputProps"]))}
            ref={params.InputProps.ref}
            placeholder="Search users"
            disableUnderline
            inputProps={{
              ...params.inputProps,
              value: inputValue,
              style: {
                fontSize: 16,
              }
            }}
          />
        )} />
    </Search>
  );
}