import _ from "lodash";

const createSymbols = (type, strings) =>
  _.fromPairs(
    _.map(strings, (str) => {
      return [str, `${_.toUpper(type)}_${_.toUpper(str)}`];
    })
  );

// TODO: tried CRUD and REST
// Should kill CRUD entirely and maybe keep REST
const defaults = ["CREATE", "DELETE", "INDEX", "NEW", "SHOW", "UPDATE", "NOOP"];

const actions = [
  ["alerts", []],
  ["billing", []],
  ["regions", []],
  ["integrations", []],
  ["merchants", []],
  ["merchantUsers", []],
  ["merchantShoppers", []],
  ["newPromotion", []],
  ["notifications", []],
  ["onboarding", []],
  ["payments", []],
  ["promotions", []],
  ["promotionEntries", []],
  ["promotionPropositions", []],
  ["sports", []],
  ["sportsEvents", []],
  ["leagues", []],
  ["players", []],
  ["teams", []],
  ["transactions", []],
  ["user", ["PASSWORD_UPDATE"]],
];

// Create action constants scoped to different modules
// (i.e. account.CREATE, banking.DELETE, etc).
// The string values contain the module prefix (upcase)
// (i.e. account.CREATE == "ACCOUNT_CREATE")
// TODO: better implementation would mean not having to get index below.
export default _.chain(actions)
  .map((action) => {
    let [module, customActions] = action;
    return [module, createSymbols(module, _.concat(defaults, customActions))];
  })
  .fromPairs()
  .value();
