import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import DataGrid from '../../../components/DataGrid';
import * as request from '../../../utils/adminRequest';
import * as dates from '../../../utils/dates';

export default function AdminPropositionDetails(props) {
  const [loading, setLoading] = React.useState(true);

  const [event, setEvent] = React.useState({})
  const [propositions, setPropositions] = React.useState([])

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    Promise.all([
      fetchEvent(params.eventId),
      fetchPropositions(params.eventId)
    ]).finally(() => setLoading(false))
  }, []);

  const fetchEvent = async id => {
    const { data } = await request.get(`events/${params.eventId}`)

    if (data.errors) {

    } else {
      setEvent({
        id: data.id,
        ...data.attributes
      })
    }
  }

  const fetchPropositions = async event_id => {
    const { data } = await request.get(`events/${params.eventId}/propositions`, {
      params: {
        event_id
      }
    })

    if (data.errors) {

    } else {
      setPropositions(
        _.map(data, p => {
          return {
            id: p.id,
            ...p.attributes
          }
        })
      )
    }

  }

  const propositionColumns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },
    {
      field: 'probability',
      headerName: 'Probability',
      width: 200,
      valueGetter: (value) => {
        return `${(value * 100).toFixed(2)}%`
      }
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 150,
      valueGetter: (value) => {
        return moment(value).format("MM/DD/YY")
      }
    },
  ]

  const breadcrumbs = [
    <Link
      variant="body3"
      color="secondary"
      fontWeight={600}
      component={RouterLink}
      to="../">
      Events
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      {event.title}
    </Typography>,
  ];

  if (loading) return <Loader />;
  if (_.isEmpty(event)) return <Loader />;


  const date = moment(event.end_at).format("YYYYMMDD");

  return (
    <Box pt={3}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <Box  mb={3}>
          <Stack direction="row" spacing={10}>
            <Box>
              <Typography variant="body3" fontWeight={500}>
                Starts
              </Typography>

              <Typography variant="body3" color="text.secondary">
                {
                  dates.tzAwareDateTime(event.start_at)
                }
              </Typography>
            </Box>

            <Box>
              <Typography variant="body3" fontWeight={500}>
                Ends
              </Typography>

              <Typography variant="body3" color="text.secondary">
                {
                  dates.tzAwareDateTime(event.end_at)
                }
              </Typography>
            </Box>
          </Stack>
        </Box>

        {
          !!event.oracle_url &&
            <Box  mb={3}>
              <Link color="secondary" href={event.oracle_url} target="_blank" variant="body3" fontWeight={500}>
                Polymarket URL
              </Link>
            </Box>
        }

        <Box  mb={3}>
          <img src={event.image_url} style={{
            height: 200
          }} />

        </Box>

        <Box mt={2} mb={3}>
          <Typography variant="body3" fontWeight={600}>
            Description
          </Typography>

          <Typography variant="body3">
            {
              event.description
            }
          </Typography>
        </Box>


        <Box mt={2} mb={3}>
          <Typography variant="body3" fontWeight={600}>
            Story
          </Typography>

          <Typography variant="body3">
            {
              event.story
            }
          </Typography>
        </Box>

        <Divider />

        <Box mt={3} mb={3}>
          <Stack direction="row" justifyContent="end">
            <Button color="secondary" startIcon={<AddIcon />} component={RouterLink} to="./propositions/new">
              Add Proposition
            </Button>
          </Stack>
          <Box sx={{ height: 400, mt: 2 }}>
            <DataGrid
              rows={propositions}
              columns={propositionColumns}
              pageSize={20}
              paginationMode="server"
              onRowSelectionModelChange={row => {
                navigate(`./propositions/${row[0]}`)
              }}
              components={{
                Cell: ({ value }) => <Typography variant="body3">{value}</Typography>,
                HeaderCell: ({ value }) => <Typography variant="body3" color="text.secondary">{value}</Typography>,
              }}
            />
          </Box>
        </Box>
      </Container>

      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
           <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to="./edit">
                Edit
              </Button>
              <Button
                variant="contained"
                color="error"
                component={RouterLink}
                to={`./delete`}>
                Delete
              </Button>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <Outlet context={{
        event,
        setEvent,
        propositions,
        setPropositions
      }} />
    </Box>
  )
}
