import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';

import ClickablePaper from '../../../components/ClickablePaper';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import * as request from '../../../utils/adminRequest';

function AdminPromotionDetails(props) {
  const { regions, leagues, teams } = props;

  const location = useLocation();

  const [loading, setLoading] = React.useState(true);

  const [promotion, setPromotion] = React.useState({})
  const [activeTab, setActiveTab] = React.useState(0)

  const tabs = ["propositions", "design"];

  const navigate = useNavigate();

  const params = useParams();

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  React.useEffect(() => {
    setActiveTab(
      _.includes(location.pathname, "propositions") ? 0 : 1
    )
  }, [location.pathname]);

  const fetchData = async id => {
    const { data } = await request.get(`promotions/${params.promotionId}`)

    setPromotion({
      id: data.id,
      ...data.attributes
    })

    setLoading(false)
  }

  if (loading) return <Loader />;

  const breadcrumbs = [
    <Link
      variant="body3"
      color="secondary"
      fontWeight={600}
      component={RouterLink}
      to="../">
      Promotions
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      {promotion.title}
    </Typography>,
  ];

  return (
    <Box pt={3} pb={10}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <Box mt={4} mb={3}>
          <Box mb={2}>
            <Box mb={1}>
              <Typography variant="body2" fontWeight={600}>
                Offered by <Link to={`/admin/merchants/${promotion.merchant.id}`} component={RouterLink} color="secondary">
                 {promotion.merchant.name}
                </Link>
              </Typography>
            </Box>

            <Typography variant="body3">
              Authored by {promotion.user.name}
            </Typography>

            <Typography variant="body3">
              Runs from <b>{
                moment(promotion.start_at).format("MM/DD/YY")
              }</b> to  <b>{
                moment(promotion.end_at).format("MM/DD/YY")
              }</b>
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box mt={4} mb={3}>
          <Typography variant="body3" color="text.secondary" sx={{
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}>
            {
              promotion.description
            }
          </Typography>
        </Box>

        <Divider />

        <Box mt={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs indicatorColor="secondary" textColor="secondary" value={activeTab} onChange={(e, tab) => {
            navigate(`./${tabs[tab]}`)
          }}>
            {
              _.map(tabs, t => {
                return (
                  <Tab key={t} label={_.toUpper(t)}  />
                )
              })
            }

          </Tabs>
        </Box>

        <Box mt={4} mb={3}>
          <Outlet context={{
            promotion,
            setPromotion,
          }} />
        </Box>

      </Container>

      <AppBar position="fixed" sx={{
        top: "auto",
        bottom: 0,
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Toolbar sx={{justifyContent: "flex-end"}}>
          <Box flexGrow={{
            xs: 0,
            sm: 0
          }}>
            {
                <Chip label={
                  _.toUpper(promotion.status)
                } />
            }
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

const select = $$state => _.pick($$state, "regions", "leagues", "teams");
export default connect(select, {
})(AdminPromotionDetails);
