import _ from 'lodash';
import moment from "moment";
import qs from 'qs';
import * as React from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function AdminOffersActions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const { offer, setOffer } = props;

  const {
    proposition,
    merchant_shopper,
    transactions,
    prize,
  } = offer;

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget)
  };

  const handleClose = e => {
    if (e) e.stopPropagation();
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>

        <List dense disablePadding>
          {
            offer.status == "claimed" &&
              <ListItem disablePadding divider>
                <ListItemButton onClick={() => {
                  navigate(`./approve`)
                }}>
                  <ListItemText>
                    <Typography variant="body3" fontWeight={600} textAlign="center">
                      Verify
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
          }

          {
            offer.status == "claimed" &&
              <ListItem disablePadding divider>
                <ListItemButton onClick={() => {
                  navigate(`./reject`)
                }}>
                  <ListItemText>
                    <Typography variant="body3" fontWeight={600} textAlign="center">
                      Reject
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
          }
        </List>
      </Popover>
    </Box>
  );
}
